import * as React from 'react';
import { useState, useEffect, useCallback, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import FilterListIcon from '@mui/icons-material/FilterList';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import Chip from '@mui/material/Chip';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Logout from '@mui/icons-material/Logout';
import ViewComfyIcon from '@mui/icons-material/ViewComfy';
import DetailsIcon from '@mui/icons-material/Details';
import UpdateIcon from '@mui/icons-material/Update';
import { visuallyHidden } from '@mui/utils';
import { tableCellClasses } from '@mui/material/TableCell';
import ProgressBar from './ProgressBar';
import ShowAllActiveProjectOfFollowingGroup from './ShowAllActiveProjectOfFollowingGroup';
import { useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';
import { getProjects } from '../../../Store/ProjectSlice';
import { useTranslation } from 'react-i18next';
import { t } from 'i18next';
// import { useDispatch } from 'react-redux';
// import { add } from '../../../Store/cartSlice';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14
	}
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	}
}));

const getDatePlease = (D) => {
	return new Date(D).toLocaleDateString();
};

function createData(name_en, calories, fat, carbs, protein) {
	return {
		name: name_en,
		calories,
		fat,
		carbs,
		protein
	};
}

const rows = [
	createData(
		'TID0123456789',
		'2020-2-10 ~ 2021-3-29',
		'ERA>ERA>DES',
		1,
		'In Progress'
	),
	createData('TID0123456788', '2020-2-10 ~ 2021-3-29', 'DES', 1, 'Completed'),
	createData(
		'TID0123456787',
		'2020-2-10 ~ 2021-3-29',
		'ERA>ERA>DES',
		1,
		'In Progress'
	),
	createData(
		'TID0123456786',
		'2020-2-10 ~ 2021-3-29',
		'ERA>ERA>DES',
		1,
		'In Progress'
	),
	createData('TID0123456785', '2020-2-10 ~ 2021-3-29', 'ERA>DES', 1, 'On Hold'),
	createData(
		'TID0123456784',
		'2020-2-10 ~ 2021-3-29',
		'ERA>ERA>DES',
		1,
		'Running'
	),
	createData(
		'TID0123456783',
		'2020-2-10 ~ 2021-3-29',
		'ERA>ERA>DES',
		1,
		'In Progress'
	),
	createData('TID0123456782', '2020-2-10 ~ 2021-3-29', 'DES', 1, 'Completed'),
	createData(
		'TID0123456781',
		'2020-2-10 ~ 2021-3-29',
		'ERA>ERA>DES',
		1,
		'In Progress'
	),
	createData(
		'TID0123456780',
		'2020-2-10 ~ 2021-3-29',
		'ERA>ERA>DES',
		1,
		'In Progress'
	),
	createData('TID0123456779', '2020-2-10 ~ 2021-3-29', 'ERA>DES', 1, 'On Hold'),
	createData(
		'TID0123456778',
		'2020-2-10 ~ 2021-3-29',
		'ERA>ERA>DES',
		1,
		'Running'
	),
	createData(2, 452, 25.0, 51, 4.9),
	createData(3, 262, 16.0, 24, 6.0),
	createData(4, 159, 6.0, 24, 4.0),
	createData(5, 356, 16.0, 49, 3.9),
	createData(6, 408, 3.2, 87, 6.5),
	createData(7, 237, 9.0, 37, 4.3),
	createData(8, 375, 0.0, 94, 0.0),
	createData(9, 518, 26.0, 65, 7.0),
	createData(10, 392, 0.2, 98, 0.0),
	createData(11, 318, 0, 81, 2.0),
	createData(12, 360, 19.0, 9, 37.0),
	createData(13, 437, 18.0, 63, 4.0),
	createData(14, 1, 1.0, 1, 1.0),
	createData(15, 2, 2.0, 2, 2.0),
	createData(16, 3, 3.0, 3, 3.0),
	createData(17, 4, 4.0, 4, 4.0),
	createData(18, 5, 5.0, 5, 5.0),
	createData(19, 6, 6.0, 6, 6.0),
	createData(20, 7, 7.0, 7, 7.0),
	createData(21, 8, 8.0, 8, 8.0),
	createData(22, 9, 9.0, 9, 9.0),
	createData(23, 10, 10.0, 10, 10.0),
	createData(24, 11, 11.0, 11, 11.0),
	createData(25, 12, 12.0, 12, 12.0),
	createData(26, 13, 13.0, 13, 13.0)
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

// const headCells = [
// 	{
// 		id: 'name_en',
// 		label: `${t('track')}`
// 	},
// 	{
// 		id: 'start_date',
// 		label: 'Duration'
// 	},
// 	{
// 		id: 'deadline',
// 		label: 'Deadline'
// 	},
// 	{
// 		id: 'track_summary',
// 		label: 'Progress'
// 	},
// 	{
// 		id: 'status',
// 		label: 'Status'
// 	},
// 	{
// 		id: 'action',
// 		label: ''
// 	}
// ];

function EnhancedTableHead(props) {
	const { t } = useTranslation();
	const {
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort
	} = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};
	const headCells = [
		{
			id: 'name_en',
			label: `${t('projectTable.name')}`
		},
		{
			id: 'start_date',
			label: `${t('projectTable.duration')}`
		},
		{
			id: 'deadline',
			label: `${t('projectTable.deadline')}`
		},
		{
			id: 'track_summary',
			label: `${t('projectTable.progress')}`
		},
		{
			id: 'status',
			label: `${t('projectTable.status')}`
		},
		{
			id: 'action',
			label: `${t('recordTable.action')}`
		}
	];

	return (
		<TableHead>
			<TableRow>
				{/* <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell> */}
				{headCells.map((headCell) => (
					<TableCell
						sx={{ backgroundColor: '#415A77', color: 'white' }}
						// sx={{ backgroundColor: "#", color: 'white' }}
						key={headCell.id}
						align="center"
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
							sx={{
								'&.MuiTableSortLabel-root': {
									color: 'white'
								},
								'&.MuiTableSortLabel-root:hover': {
									color: 'yellow'
								},
								'&.Mui-active': {
									color: 'yellow'
								},
								'& .MuiTableSortLabel-icon': {
									color: 'yellow !important'
								}
							}}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
};

function EnhancedTableToolbar(props) {
	const { numSelected } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						)
				})
			}}
		>
			{numSelected > 0 ? (
				<Typography
					sx={{ flex: '1 1 100%' }}
					color="inherit"
					variant="subtitle1"
					component="div"
				>
					{numSelected} selected
				</Typography>
			) : (
				<Typography
					sx={{ flex: '1 1 100%' }}
					variant="h6"
					id="tableTitle"
					component="div"
				>
					Nutrition
				</Typography>
			)}

			{numSelected > 0 ? (
				<Tooltip title="Delete">
					<IconButton>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title="Filter list">
					<IconButton>
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired
};

export default function ProjectTable(props) {
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('calories');
	const [selected, setSelected] = useState([]);
	const [page, setPage] = useState(0);
	const [dense, setDense] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const { clickGroupId } = props;
	const [projectData, setProjectData] = useState([]);
	const [initialDate, setInitialDate] = useState('1970-01-01T00:00:00.000Z');
	const [clickProjectId, setClickProjectId] = useState([]);
	const [loading, setLoading] = useState(false);
	const [refreshKey, setRefreshKey] = useState(0);
	const previousValue = useRef('');
	const dispatch = useDispatch();
	// const { data } = useSelector((state) => state.project);
	// const cartProject = useSelector((state) => state.cart);

	const [anchorEl, setAnchorEl] = React.useState(null);
	const navigate = useNavigate();
	const open = Boolean(anchorEl);
	const handleClickForOpen = (event, id) => {
		console.log('project valuoe for click', id);
		setClickProjectId(id);
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = rows.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChangeDense = (event) => {
		setDense(event.target.checked);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const visibleRows = React.useMemo(
		() =>
			stableSort(projectData, getComparator(order, orderBy)).slice(
				page * rowsPerPage,
				page * rowsPerPage + rowsPerPage
			),
		[projectData, order, orderBy, page, rowsPerPage]
	);

	// const FetchProjectData = useMemo(
	// 	() => async () => {
	// 		const allData = await ShowAllActiveProjectOfFollowingGroup(clickGroupId);
	// 		console.log(projectData);
	// 		if (allData.success === true) {
	// 			setProjectData(allData.body);
	// 			previousValue.current = projectData;
	// 			console.log('data aise ');
	// 		}
	// 	},
	// 	[clickGroupId, previousValue.current]
	// );

	useEffect(() => {
		const FetchProjectData = async () => {
			const allData = await ShowAllActiveProjectOfFollowingGroup(clickGroupId);
			console.log(allData.body);
			if (allData.success === true) {
				setProjectData(await allData.body);
				console.log('data aise ');
			}
		};
		FetchProjectData();
		// dispatch(getProjects());
	}, [clickGroupId, projectData]);

	return (
		<Box sx={{ width: '100%' }}>
			{loading === true ? (
				<h3 style={{ textAlign: 'center' }}>{t('loading')}</h3>
			) : projectData && projectData.length === 0 ? (
				<h3 style={{ textAlign: 'center' }}>{t('noRecordAvailable')}</h3>
			) : (
				<Paper sx={{ width: '100%', mb: 2 }}>
					{/* <EnhancedTableToolbar numSelected={selected.length} /> */}
					<TableContainer sx={{ marginTop: 0, overflowX: 'initial' }}>
						<Table
							sx={{ minWidth: 750, marginTop: 0 }}
							// aria-labelledby="tableTitle"
							stickyHeader
							aria-label="sticky table"
							size={dense ? 'small' : 'medium'}
						>
							<EnhancedTableHead
								numSelected={selected.length}
								order={order}
								orderBy={orderBy}
								onSelectAllClick={handleSelectAllClick}
								onRequestSort={handleRequestSort}
								rowCount={rows.length}
							/>
							<TableBody>
								{visibleRows.map((row, index) => {
									console.log('hahahahhahahahahah', row);
									const isItemSelected = isSelected(row.name);
									const labelId = `enhanced-table-checkbox-${index}`;

									return (
										<TableRow
											hover
											onClick={(event) => handleClick(event, row._id)}
											// role="checkbox"
											// aria-checked={isItemSelected}
											tabIndex={-1}
											key={row._id}
											// selected={isItemSelected}
											sx={{ cursor: 'pointer' }}
										>
											{/* <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell> */}
											<TableCell
												component="th"
												id={labelId}
												scope="row"
												padding="none"
												align="center"
											>
												{row.name_en}
											</TableCell>
											<TableCell align="center">
												{row.start_date === initialDate
													? 'N/A'
													: getDatePlease(row.start_date)}{' '}
												~{' '}
												{row.end_date === initialDate
													? 'N/A'
													: getDatePlease(row.end_date)}
											</TableCell>
											<TableCell align="center">
												{getDatePlease(row.deadline)}
											</TableCell>
											<TableCell align="center">
												{row &&
												row.track_summary &&
												row.track_summary.total === 0 ? (
													<Box
														sx={{
															fontSize: '10px',
															lineHeight: '12px',
															paddingY: '4px',
															marginX: '20px',
															borderRadius: '10px',
															backgroundColor: '#DEE4ED'
														}}
													>
														{t('projectTable.nothingRegister')}
													</Box>
												) : (
													<ProgressBar progressData={row.track_summary} />
												)}
											</TableCell>
											<TableCell align="center">
												<Chip
													label={row.status.toUpperCase()}
													sx={{
														fontSize: '10px',
														color: `${
															row.status === 'completed' ? 'white' : 'black'
														}`,
														backgroundColor: `${
															row.status === 'in progress'
																? '#D0FAE5'
																: row.status === 'ready'
																? '#DEE4ED'
																: '#415A77'
														}`
													}}
												/>
												{/* {row.status === "ready" ? row.status.toUpperCase() : null} */}
											</TableCell>
											<TableCell align="center">
												<MoreVertIcon
													onClick={(event) =>
														handleClickForOpen(event, row._id)
													}
												/>
												<Menu
													anchorEl={anchorEl}
													id="account-menu"
													open={open}
													onClose={handleClose}
													onClick={handleClose}
													PaperProps={{
														elevation: 0,
														sx: {
															overflow: 'visible',
															filter:
																'drop-shadow(0px 2px 4px rgba(0,0,0,0.10))',
															mt: 1.5,
															'& .MuiAvatar-root': {
																width: 32,
																height: 32,
																ml: -0.5,
																mr: 1
															},
															'&:before': {
																content: '""',
																display: 'block',
																position: 'absolute',
																top: 0,
																right: 14,
																width: 10,
																height: 10,
																bgcolor: 'background.paper',
																transform: 'translateY(-50%) rotate(45deg)',
																zIndex: 0
															}
														}
													}}
													transformOrigin={{
														horizontal: 'right',
														vertical: 'top'
													}}
													anchorOrigin={{
														horizontal: 'right',
														vertical: 'bottom'
													}}
												>
													<MenuItem
														onClick={() =>
															navigate('/trackBoard', {
																state: { trackId: clickProjectId }
															})
														}
													>
														<ListItemIcon>
															<ViewComfyIcon fontSize="small" />
														</ListItemIcon>
														{t('projectTable.viewTrackBoard')}
													</MenuItem>
													{/* <MenuItem onClick={handleClose}>
													<ListItemIcon>
														<DetailsIcon fontSize="small" />
													</ListItemIcon>
													View Details
												</MenuItem>
												<MenuItem onClick={handleClose}>
													<ListItemIcon>
														<UpdateIcon fontSize="small" />
													</ListItemIcon>
													Update Info
												</MenuItem> */}
												</Menu>
											</TableCell>
										</TableRow>
									);
								})}
								{emptyRows > 0 && (
									<TableRow
										style={{
											height: (dense ? 33 : 53) * emptyRows
										}}
									>
										<TableCell colSpan={6} />
									</TableRow>
								)}
							</TableBody>
						</Table>
					</TableContainer>
					<TablePagination
						labelRowsPerPage={t('projectTable.rowsPerPage')}
						rowsPerPageOptions={[5, 10, 25]}
						component="div"
						count={projectData.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onPageChange={handleChangePage}
						onRowsPerPageChange={handleChangeRowsPerPage}
					/>
				</Paper>
			)}
		</Box>
	);
}
