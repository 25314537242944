import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
// import styles from './AdminGroupTable.module.css';
import FetchAllGroupApiHandler from '../../../GroupComponents/CreateGroup/GroupApiHandler/FetchAllGroupApiHandler';
import { useTranslation } from 'react-i18next';
import jwt_decode from 'jwt-decode';
import { useNavigate } from 'react-router-dom';

const getDatePlease = (D) => {
	return new Date(D).toLocaleString();
};

const AdminGroupTable = () => {
	const { t } = useTranslation();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [groupData, setGroupData] = useState([]);

	// const navigate = useNavigate();
	// const now = new Date();
	// console.log('local storage', localStorage.getItem('access_token'));
	// const decodedToken = jwt_decode(localStorage.getItem('access_token'));
	// console.log('decoded token', decodedToken);
	// const { exp } = decodedToken;
	// console.log('this is exp', exp);

	// useEffect(() => {
	// 	if (!localStorage.getItem('access_token') || exp * 1000 < now.getTime()) {
	// 		navigate('/');
	// 		window.location.reload();
	// 	}
	// }, [navigate, exp, now]);

	console.log(groupData);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	useEffect(() => {
		const FetchData = async () => {
			const response = await FetchAllGroupApiHandler();
			console.log('organizatoin all data', response.body);
			setGroupData(response.body);
		};
		FetchData();
	}, [groupData]);

	return (
		<>
			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<TableContainer sx={{ maxHeight: 345 }}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
									align="center"
								>
									{t('group.groupName')}
								</TableCell>
								<TableCell
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
									align="center"
								>
									{t('group.groupStatus')}
								</TableCell>
								<TableCell
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
									align="center"
								>
									{t('group.createdAt')}
								</TableCell>
								<TableCell
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
									align="center"
								>
									{t('group.updatedAt')}
								</TableCell>
								{/* <TableCell
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
									align="center"
								>
									Action
								</TableCell> */}
							</TableRow>
						</TableHead>
						<TableBody>
							{groupData &&
								groupData.map((row) => (
									<TableRow
										key={row._id}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row" align="center">
											{row.name_en}
										</TableCell>
										<TableCell
											align="center"
											style={{
												color:
													(row.active === true && 'green') ||
													(row.active === false && 'red')
											}}
										>
											{row.active ? 'Active' : 'Inactive'}
										</TableCell>
										<TableCell align="center">
											{getDatePlease(row.createdAt)}
										</TableCell>
										<TableCell align="center">
											{getDatePlease(row.updatedAt)}
										</TableCell>
										{/* <TableCell align="center">
											<Stack
												justifyContent="center"
												spacing={2}
												direction="row"
											>
												<Button variant="contained">Update</Button>
												<Button variant="outlined" color="error">
													Block
												</Button>
											</Stack>
										</TableCell> */}
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					labelRowsPerPage={t('projectTable.rowsPerPage')}
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={groupData.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</>
	);
};

export default AdminGroupTable;
