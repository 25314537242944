import React from 'react';
import styles from './DeviceInfo.module.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
	plugins: {
		legend: {
			display: false
		}
	},
	responsive: true,
	maintainAspectRatio: false
};

export const data = {
	labels: ['Green', 'Purple', 'Orange'],
	datasets: [
		{
			label: '# of device',
			data: [5, 2, 3],
			backgroundColor: ['#DEE4ED', '#415A77', '#718399'],
			borderColor: ['#DEE4ED', '#415A77', '#718399'],
			borderWidth: 1
		}
	]
};

const ChartInformationForDevice = () => {
	return (
		<>
			<div className={styles['chart-information-align']}>
				<Doughnut data={data} options={options} />
			</div>
		</>
	);
};

export default ChartInformationForDevice;
