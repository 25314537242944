import React, { useState } from 'react';
import {
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Image,
	Font
} from '@react-pdf/renderer';

Font.register({
	family: 'Noto Sans JP',
	src: 'https://cdn.jsdelivr.net/npm/noto-sans-japanese@1.0.0/fonts/NotoSansJP-Light.otf'
});

// Create styles
const styles = StyleSheet.create({
	page: {
		fontFamily: 'Noto Sans JP'
	},
	gridContainer: {
		margin: 'auto',
		alignItems: 'center',
		width: '90%',
		height: '90%',
		border: '1px solid black'
	},
	gridItem1: {
		margin: '5px'
	},
	imageSize: {
		width: 'auto',
		height: '150px'
	},
	gridItem2: {
		alignItems: 'center',
		width: '100%',
		borderTop: '1px solid black',
		marginTop: 'auto'
	},
	id: {
		fontSize: '12',
		margin: '10px'
	}
});

const QrCodePdfDownload = (props) => {
	console.log('__QrCodePdfDownload starts');

	const [pdfData, setPdfData] = useState(props.pdfData);
	console.log('__QrCodePdfDownload data', pdfData);
	if (props.pdfData.length !== 0) {
		return (
			<Document>
				{pdfData?.map((row, index) => (
					<Page orientation="landscape" size="A7" style={styles.page}>
						<View key={index} style={styles.gridContainer}>
							<View style={styles.gridItem1}>
								<Image
									style={styles.imageSize}
									src={`${row.track_tag.qr_code}`}
									alt="qr code image"
								/>
							</View>
							<View style={styles.gridItem2}>
								<Text style={styles.id}>{`${row.track_tag.tag_number}`}</Text>
							</View>
						</View>
					</Page>
				))}
			</Document>
		);
	}
};

export default QrCodePdfDownload;
