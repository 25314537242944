import { Box, Card, CardContent, Typography, styled } from '@mui/material';
import DoughnutChart from './DoughnutChart';
import { useTranslation } from 'react-i18next';

const CustomCardContent = styled(CardContent)({
	'&:last-child': {
		paddingBottom: '16px'
	}
});

const StatCard = ({ statCardContent }) => {
	const { t } = useTranslation();
	return (
		<>
			<Card
				variant="outlined"
				sx={{
					width: '256px',
					height: '120px',
					border: '1px solid #dee4ed9b',
					borderRadius: '15px'
				}}
			>
				<CustomCardContent sx={{ color: '#415A77' }}>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: 1.5
						}}
					>
						<DoughnutChart statCardContent={statCardContent} />
						<Box
							sx={{
								flexGrow: 1
							}}
						>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									gap: 1,
									pb: 0.25
								}}
							>
								<Typography variant="h6" fontSize={13} fontWeight={500}>
									{/* Total {statCardContent.title} */}
									{t('statCard.total')} {statCardContent.title}
								</Typography>
								<Typography variant="h6" fontSize={13} fontWeight={700}>
									{statCardContent.total}
								</Typography>
							</Box>
							<Box>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										gap: 2
									}}
								>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											gap: 0.7
										}}
									>
										<Box
											sx={{
												width: 10,
												height: 10,
												borderRadius: '50%',
												backgroundColor: '#415A77',
												'&:hover': {
													backgroundColor: 'primary.main',
													opacity: [0.9, 0.8, 0.7]
												}
											}}
										/>
										<Typography variant="p" fontSize={12}>
											{t('statCard.completed')}
										</Typography>
									</Box>
									<Typography variant="p" fontSize={13} fontWeight={500}>
										{statCardContent.completed}
									</Typography>
								</Box>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										gap: 2
									}}
								>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											gap: 0.7
										}}
									>
										<Box
											sx={{
												width: 10,
												height: 10,
												borderRadius: '50%',
												backgroundColor: '#718399',
												'&:hover': {
													backgroundColor: 'primary.main',
													opacity: [0.9, 0.8, 0.7]
												}
											}}
										/>
										<Typography variant="p" fontSize={12}>
											{t('statCard.inProgress')}
										</Typography>
									</Box>
									<Typography variant="p" fontSize={13} fontWeight={500}>
										{statCardContent.inProgress}
									</Typography>
								</Box>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										alignItems: 'center',
										gap: 2
									}}
								>
									<Box
										sx={{
											display: 'flex',
											alignItems: 'center',
											gap: 0.7
										}}
									>
										<Box
											sx={{
												width: 10,
												height: 10,
												borderRadius: '50%',
												backgroundColor: '#DEE4ED',
												'&:hover': {
													backgroundColor: 'primary.main',
													opacity: [0.9, 0.8, 0.7]
												}
											}}
										/>
										<Typography variant="p" fontSize={12}>
											{t('statCard.remaining')}
										</Typography>
									</Box>
									<Typography variant="p" fontSize={13} fontWeight={500}>
										{statCardContent.remaining}
									</Typography>
								</Box>
							</Box>
						</Box>
					</Box>
				</CustomCardContent>
			</Card>
		</>
	);
};

export default StatCard;
