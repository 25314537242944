import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/material';
import AdminTab from '../AdminDashboard/AdminTab';
import jwt_decode from 'jwt-decode';

const ManageBoard = () => {
	const [status, setStatus] = useState(true);
	const navigate = useNavigate();
	const now = new Date();
	console.log('local storage', localStorage.getItem('access_token'));
	const decodedToken = jwt_decode(localStorage.getItem('access_token'));
	console.log('decoded token', decodedToken);
	const { exp } = decodedToken;
	console.log('this is exp', exp);

	useEffect(() => {
		if (!localStorage.getItem('access_token') || exp * 1000 < now.getTime()) {
			setStatus(false);
			navigate('/');
			window.location.reload();
		}
	}, [navigate, exp, now]);

	return (
		<>
			<Box display="flex" justifyContent="center" alignItems="center">
				{status === true ? <AdminTab /> : null}
			</Box>
			{/* <Box>
				<GroupsTable />
			</Box> */}
		</>
	);
};

export default ManageBoard;
