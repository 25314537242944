import { Box, Stack } from '@mui/material';
import LoginForm from './LoginForm';
import LoginFooter from './LoginFooter';

const Login = () => {
	return (
		<>
			<Stack direction="column" sx={{ minHeight: '100vh' }}>
				<Box
					flexGrow={1}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						alignItems: 'center',
						color: '#718399'
					}}
				>
					<LoginForm />
				</Box>
				<LoginFooter />
			</Stack>
		</>
	);
};

export default Login;
