import { Box, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';

const Footer = () => {
	const { t } = useTranslation();
	return (
		<Box
			sx={{
				backgroundColor: '#f6f8fa',
				paddingTop: '1.2rem',
				paddingBottom: '1rem'
			}}
		>
			<Stack
				direction="row"
				spacing={0.5}
				justifyContent="center"
				color="#718399"
			>
				<Typography variant="body2">
					&copy;
					{`${new Date().getFullYear()} ${t('footer.name')}`}
				</Typography>
				<Typography variant="body2">{t('footer.rights')}</Typography>
			</Stack>
		</Box>
	);
};

export default Footer;
