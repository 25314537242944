const ShowOrganizationCollaborationApi = async () => {
	const accessToken = localStorage.getItem('access_token');

	const res = await fetch(
		`${
			process.env.REACT_APP_BASE_URL +
			'organizations/showMyOrganizationCollaborations'
		}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${accessToken}`
			}

			// body: JSON.stringify({

			// })
		}
	);

	const data = await res.json();
	return data;
};

export default ShowOrganizationCollaborationApi;
