import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import { useState } from 'react';
import createTrack from '../../../../Icons/createTrack.svg';
import CloseIcon from '@mui/icons-material/Close';
import CreateTrackID from '../CreateTrackID';
import { useTranslation } from 'react-i18next';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 500,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 10,
	p: 4
};

const CreateTrackModal = ({ projectId, projectName }) => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const handleOpen = () => setOpen(true);

	const handleClose = () => {
		setOpen(false);
	};

	return (
		<>
			<Modal
				open={open}
				onClose={handleClose}
				aria-labelledby="modal-modal-title"
				aria-describedby="modal-modal-description"
			>
				<Box sx={style}>
					<Box sx={{ textAlign: 'right' }}>
						<Button onClick={handleClose}>
							<CloseIcon sx={{ color: '#415A77' }} />
						</Button>
					</Box>

					<CreateTrackID
						projectId={projectId}
						projectName={projectName}
						setOpen={setOpen}
					/>
				</Box>
			</Modal>
			<Button
				variant="outlined"
				// className={styles["create-track-button"]}
				sx={{ borderRadius: '5px', color: '#415A77' }}
				startIcon={
					<img src={createTrack} alt="CreateTrack" width="16" height="16" />
				}
				onClick={handleOpen}
			>
				{t('createTrack.name')}
			</Button>
		</>
	);
};

export default CreateTrackModal;
