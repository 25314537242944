import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import HandoverAcceptApi from '../../HandoverApi/HandoverAcceptApi';
import styles from './TrackDetails.module.css';
import HandoverRejectApi from '../../HandoverApi/HandoverRejectApi';
import HandoverReturnApi from '../../HandoverApi/HandoverReturnApi';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';

const TrackDetails = (props) => {
	const { t } = useTranslation();
	const [acceptHandover, setAcceptHandover] = useState(false);
	const [rejectHandover, setRejectHandover] = useState(false);
	const [returnHandover, setReturnHandover] = useState(false);
	const [isError, setIsError] = useState(false);
	const [open, setOpen] = useState(false);
	const { status } = props;

	const AcceptedHandover = async (id) => {
		const res = await HandoverAcceptApi(id);
		console.log('accepted handover response', res);
		if (res.success === true) {
			setAcceptHandover(true);
			setRejectHandover(false);
			setReturnHandover(false);
			setIsError(false);
			setOpen(true);
			setTimeout(() => window.location.reload(), 500);
		} else {
			setIsError(true);
			setAcceptHandover(false);
		}
	};

	const RejectedHandover = async (id) => {
		const res = await HandoverRejectApi(id);
		console.log('reject handover response', res);
		if (res.success === true) {
			setAcceptHandover(false);
			setReturnHandover(false);
			setRejectHandover(true);
			setIsError(false);
			setOpen(true);
			setTimeout(() => window.location.reload(), 500);
		} else {
			setIsError(true);
			setRejectHandover(false);
		}
	};

	const ReturnHandover = async (id) => {
		const res = await HandoverReturnApi(id);
		console.log('return handover response', res);
		if (res.success === true) {
			setAcceptHandover(false);
			setRejectHandover(false);
			setReturnHandover(true);
			setIsError(false);
			setOpen(true);
			setTimeout(() => window.location.reload(), 500);
		} else {
			setIsError(true);
			setReturnHandover(false);
		}
	};
	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	return (
		<>
			{/* <h1>{props.handover_id}</h1> */}
			{acceptHandover && (
				<Snackbar
					open={open}
					autoHideDuration={6000}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
				>
					<Alert
						onClose={handleClose}
						severity="success"
						sx={{ width: '100%' }}
					>
						{t('handoverRequest.acceptSuccess')}
					</Alert>
				</Snackbar>
			)}
			{rejectHandover && (
				<Snackbar
					open={open}
					autoHideDuration={6000}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
				>
					<Alert
						onClose={handleClose}
						severity="success"
						sx={{ width: '100%' }}
					>
						{t('handoverRequest.rejectSuccess')}
					</Alert>
				</Snackbar>
			)}
			{returnHandover && (
				<Snackbar
					open={open}
					autoHideDuration={6000}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
				>
					<Alert
						onClose={handleClose}
						severity="success"
						sx={{ width: '100%' }}
					>
						{t('handoverRequest.returnSuccess')}
					</Alert>
				</Snackbar>
			)}
			{isError && (
				<Snackbar
					open={open}
					autoHideDuration={6000}
					onClose={handleClose}
					anchorOrigin={{
						vertical: 'top',
						horizontal: 'center'
					}}
				>
					<Alert
						onClose={handleClose}
						severity="success"
						sx={{ width: '100%' }}
					>
						{t('handoverRequest.error')}
					</Alert>
				</Snackbar>
			)}
			<Stack
				spacing={2}
				direction="row"
				justifyContent="center"
				alignItems="center"
				marginTop={3}
			>
				{status !== 'Completed' ? (
					<div>
						<Stack
							spacing={2}
							direction="row"
							justifyContent="center"
							alignItems="center"
							marginTop={3}
						>
							<Button
								variant="contained"
								onClick={() => AcceptedHandover(props.handover_id)}
							>
								{t('handoverRequest.acceptButton')}
							</Button>
							<Button
								variant="contained"
								onClick={() => RejectedHandover(props.handover_id)}
							>
								{t('handoverRequest.rejectButton')}
							</Button>
						</Stack>
					</div>
				) : (
					<div>
						<Button
							variant="contained"
							onClick={() => ReturnHandover(props.handover_id)}
						>
							{t('handoverRequest.returnButton')}
						</Button>
					</div>
				)}
			</Stack>
		</>
	);
};

export default TrackDetails;
