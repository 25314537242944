import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ChartComponents from '../../ChartComponents';

const Home = () => {
	const navigate = useNavigate();
	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			navigate('/');
		}
	}, [navigate]);
	return (
		<>
			<ChartComponents />
		</>
	);
};

export default Home;
