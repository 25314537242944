import React from 'react';
import styles from './TrackInfo.module.css';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
	plugins: {
		legend: {
			display: false
		}
	},
	responsive: true,
	maintainAspectRatio: false
};

// export const data = {
//   labels: ["Completed", "In Progress", "Remaining"],
//   datasets: [
//     {
//       label: "# of device",
//       data: [0, 0, 0],
//       backgroundColor: ["#415A77", "#718399", "#DEE4ED"],
//       borderColor: ["#415A77", "#718399", "#DEE4ED"],
//       borderWidth: 1,
//     },
//   ],
// };

const ChartInformationForTrack = ({ trackSummary }) => {
	const { t } = useTranslation();
	const { total, inProgress, remaining, completed } = trackSummary;
	console.log(remaining);
	const data = {
		labels: [
			`${t('recordStatistics.completed')}`,
			`${t('recordStatistics.inProgress')}`,
			`${t('recordStatistics.remaining')}`
		],
		datasets: [
			{
				// label: completed
				//   ? `${completed} of device`
				//   : inProgress
				//   ? `${inProgress} of device`
				//   : `${remaining} of device`,
				label: `${t('recordStatistics.device')}`,
				data: [completed, inProgress, remaining],
				// data: [1, 1, 1],
				backgroundColor: ['#415A77', '#718399', '#DEE4ED'],
				borderColor: ['#415A77', '#718399', '#DEE4ED'],
				borderWidth: 1
			}
		]
	};

	return (
		<>
			<Doughnut data={data} options={options} />
		</>
	);
};

export default ChartInformationForTrack;
