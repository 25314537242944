const CreateReportApi = async (values, clickRecordId) => {
	const accessToken = localStorage.getItem('access_token');
	console.log(accessToken);
	console.log('kono ekta', clickRecordId);
	const res = await fetch(
		`${process.env.REACT_APP_BASE_URL + 'records/reportARecord'}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				record_id: clickRecordId,
				reported_reason: values.checked,
				report_details: values.reportDetails
			})
		}
	);

	const data = await res.json();
	console.log(data);
	return data;
};

export default CreateReportApi;
