import { Button, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import { Formik } from 'formik';
import { useState, useEffect } from 'react';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Alert from '@mui/material/Alert';
import styles from './CreateDeviceID.module.css';
import Autocomplete from '@mui/material/Autocomplete';
import ShowAllDeviceModelsOfMyOrganization from '../../DeviceApi/ShowAllDeviceModelsOfMyOrganization';
import FormHelperText from '@mui/material/FormHelperText';
import CreateDevice from '../../DeviceApi/CreateDevice';
import { useTranslation } from 'react-i18next';
// import TextareaAutosize from "@mui/material/TextareaAutosize";
// import FetchUnoccupiedTagsApi from "./CreateTrackIDApi/ShowUnoccupiedTagsofMyOrganization";
// import FetchAllRequestTypeApiHandler from "./CreateTrackIDApi/FetchAllRequestTypeApiHandler";
// import CreateTrackApiHandler from "./CreateTrackIDApi/CreateTrackApiHandler";

const CreateDeviceID = ({ trackId }) => {
	const { t } = useTranslation();
	const [isValid, setIsValid] = useState(false);
	const [isProjectCreate, setIsProjectCreate] = useState(false);
	const [isError, setIsError] = useState(false);
	const [status, setStatus] = useState(false);
	const [groupData, setGroupData] = useState([]);
	const [allGroupData, setAllGroupData] = useState([]);
	const [allProjectData, setAllProjectData] = useState([]);
	const [requestData, setRequestData] = useState([]);
	const [unoccupiedTags, setUnoccupiedTags] = useState([]);

	//   const TimeOut = () => {
	//     setOpen(false);
	//   };

	const initialValues = {
		requestType: '',
		deviceSerial: ''
	};

	const validate = (values) => {
		setIsValid(true);
		let errors = {};

		if (!values.requestType) {
			errors.requestType = `${t('createDevice.deviceModelRequired')}`;
			setIsValid(false);
		}
		if (!values.deviceSerial) {
			errors.deviceSerial = `${t('createDevice.deviceSerialRequired')}`;
			setIsValid(false);
		}

		return errors;
	};

	useEffect(() => {
		const FetchOrganizationData = async () => {
			const allData = await ShowAllDeviceModelsOfMyOrganization();
			console.log(allData.body);
			if (allData.success === true) {
				setRequestData(await allData.body);
				console.log('data aise ');
			}
		};
		FetchOrganizationData();
	}, []);

	//   useEffect(() => {
	//     const FetchUnoccupiedTagsData = async () => {
	//       const allData = await FetchUnoccupiedTagsApi();
	//       console.log(allData.body);
	//       if (allData.success === true) {
	//         setUnoccupiedTags(await allData.body._id);
	//       }
	//     };
	//     FetchUnoccupiedTagsData();
	//   }, []);

	const onSubmit = async (values, { resetForm }) => {
		console.log('device value', values);
		setStatus(true);
		const res = await CreateDevice(trackId, values);
		console.log('create project', res);
		if (res.success === true) {
			setIsProjectCreate(true);
			setIsError(false);
			resetForm();
			setTimeout(() => window.location.reload(), 500);
		} else {
			setIsError(true);
			setIsProjectCreate(false);
		}
	};

	return (
		<>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validate={validate}
			>
				{(formik) => {
					return (
						<>
							{isProjectCreate && (
								<Box>
									<Alert severity="success">
										{' '}
										{t('createDevice.deviceSuccess')}
									</Alert>
								</Box>
							)}
							{isError && (
								<Box>
									<Alert severity="error">
										{t('createDevice.deviceError')}
									</Alert>
								</Box>
							)}

							<Box sx={{ textAlign: 'center', color: '#415A77' }}>
								<h3>{t('createDevice.title')}</h3>
							</Box>
							<Typography color="text.secondary">
								{t('createDevice.trackId')}: <b>{trackId}</b>
							</Typography>
							<br />
							<FormControl required fullWidth>
								<InputLabel id="organization-initial-label">
									{t('createDevice.deviceModel')}
								</InputLabel>

								<Select
									labelId="demo-simple-select-label"
									id="requestType"
									name="requestType"
									value={formik.values.requestType}
									label="Request Type"
									size="small"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.requestType &&
										Boolean(formik.errors.requestType)
									}
									helperText=" please selelelel"
								>
									{requestData &&
										requestData.map((row) => (
											<MenuItem key={row._id} value={row._id}>
												{row.model}
											</MenuItem>
										))}
								</Select>
								<FormHelperText>
									{t('createDevice.deviceModelHelperText')}
								</FormHelperText>
								<span>
									{formik.touched.requestType && formik.errors.requestType ? (
										<Box sx={{ color: 'red' }}>{formik.errors.requestType}</Box>
									) : null}
								</span>

								<br />
							</FormControl>
							<FormControl className={styles['login-form']} fullWidth>
								<TextField
									id="deviceSerial"
									name="deviceSerial"
									label={t('createDevice.enterDeviceSerial')}
									variant="outlined"
									size="small"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.deviceSerial}
									error={
										formik.touched.deviceSerial &&
										Boolean(formik.errors.deviceSerial)
									}
									type="text"
								/>
								<span className={styles['error']}>
									{formik.touched.deviceSerial && formik.errors.deviceSerial ? (
										<div>{formik.errors.deviceSerial}</div>
									) : null}
								</span>
								<br />
							</FormControl>
							<Stack justifyContent="center">
								<Button
									type="Submit"
									variant="contained"
									onClick={formik.handleSubmit}
									sx={{
										backgroundColor: '#415A77',
										'&:hover': {
											backgroundColor: '#718399'
										}
									}}
								>
									{t('createDevice.submit')}
								</Button>
							</Stack>
						</>
					);
				}}
			</Formik>
		</>
	);
};

export default CreateDeviceID;
