import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styles from './DeviceInformation.module.css';
import FetchAllDeviceDataByTrackIdApiHandler from '../../../../TrackBoardComponents/TrackBoradTable/TrackBoardApi/FetchAllDeviceDataByTrackIdApiHandler';
import { useTranslation } from 'react-i18next';

const DeviceInformation = (props) => {
	const { t } = useTranslation();
	const [dashboardData, setDashboardData] = useState([]);
	const [deviceName, setDeviceName] = useState(null);
	console.log(dashboardData);

	useEffect(() => {
		const FetchData = async () => {
			const response = await FetchAllDeviceDataByTrackIdApiHandler(props.id);

			console.log('single device api', response.body);
			setDashboardData(response.body);
		};
		FetchData();
	}, [props.id]);

	return (
		<>
			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<h2 className={styles['recordHeaderCenter']}>
					{t('handoverRequest.title')}
				</h2>
				<TableContainer sx={{ maxHeight: 445 }}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell
									align="center"
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
								>
									{t('handoverRequest.deviceId')}
								</TableCell>
								<TableCell
									align="center"
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
								>
									{t('handoverRequest.deviceName')}
								</TableCell>
								<TableCell
									align="center"
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
								>
									{t('handoverRequest.vendor')}
								</TableCell>
								<TableCell
									align="center"
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
								>
									{t('handoverRequest.model')}
								</TableCell>
								<TableCell
									align="center"
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
								>
									{t('handoverRequest.serial')}
								</TableCell>
								<TableCell
									align="center"
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
								>
									{t('handoverRequest.specification')}
								</TableCell>
								<TableCell
									align="center"
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
								>
									{t('handoverRequest.requestType')}
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{dashboardData &&
								dashboardData.map((row) => (
									<TableRow
										key={row._id}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row" align="center">
											{row.device_tag}
										</TableCell>
										<TableCell align="center">
											{row.device_model ? row.device_model.device_type : null}
										</TableCell>
										<TableCell align="center">
											{row.device_model ? row.device_model.manufacturer : null}
										</TableCell>
										<TableCell align="center">
											{row.device_model ? row.device_model.model : null}
										</TableCell>
										<TableCell align="center">{row.serial}</TableCell>
										<TableCell align="center">
											{row.device_model
												? row.device_model.spacifications
												: null}
										</TableCell>
										<TableCell align="center">
											{row.request_type
												? row.request_type.processing_types.join(', ')
												: null}
										</TableCell>
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</>
	);
};

export default DeviceInformation;
