import React, { useState, useEffect } from 'react';
import styles from './Record.module.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import changeCatalog from '../../Icons/changeCatalog.svg';
import downloadSummary from '../../Icons/downloadSummary.svg';
import publishButton from '../../Icons/publishButton.svg';
import createTrack from '../../Icons/createTrack.svg';
import csvIcon from '../../Icons/csvIcon.svg';
import pdfIcon from '../../Icons/pdfIcon.svg';
import ProgressBar from './ProgressBar';
// import TrackInfo from './TrackInfo';
import DeviceInfo from './DeviceInfo';
import GlobalSearch from './GlobalSearch';
import progressClock from '../../Icons/progressClock.svg';
// import TrackTable from "./TrackTable";
// import ProjectInfo from "./ProjectInfo";
import { useNavigate, useLocation } from 'react-router-dom';
// import ShowFollowingProjectsDetailsByProjectId from "./TrackApi/ShowFollowingProjectsDetailsByProjectId";
// import ShowDeviceSummaryOfFollowingProject from "./TrackApi/ShowDeviceSummaryOfFollowingProject";
// import CreateTrackModal from "./TrackCreate/CreateTrackModal";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';
import ShowDeviceDetailsByDeviceID from './RecordApi/ShowDeviceDetailsByDeviceId';
import DeviceInformation from './DeviceInformation';
import DeviceDetails from './DeviceDetails';
import RecordInfo from './RecordInfo';
import RecordTable from './RecordTable';
import RecordModal from './CreateRecord/RecordModal';
import AdecPdfDonwload from './AdecPdfDownload';
import ShowDeviceDetailsByDeviceTag from './RecordApi/ShowDeviceDetailsByDeviceTag';
import { useTranslation } from 'react-i18next';
// import TrackInformation from './TrackInformation';
// import ShowTrackDetailsByTrackId from './DeviceApi/ShowTrackDetailsByTrackId';
// import ShowAllDeviceRecordSummaryOfFollowingTrack from './DeviceApi/ShowAllDeviceRecordSummaryOfFollowingTrack';
// import RecordInfo from './RecordInfo';
// import DeviceTable from './DeviceTable';
// import DeviceModal from './CreateDevice/DeviceModal';

const getDatePlease = (D) => {
	return new Date(D).toLocaleDateString();
};

const Record = () => {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const [projectData, setProjectData] = useState([]);
	const [trackInformation, setTrackInformation] = useState([]);
	const [trackSummary, setTrackSummary] = useState([]);
	const [deviceSummary, setDeviceSummary] = useState([]);
	const [deviceInfoDetails, setDeviceInfoDetails] = useState([]);
	const [recordSummary, setRecordSummary] = useState([]);
	const [addDeviceId, setAddDeviceId] = useState([]);
	console.log('addDeviceId', addDeviceId);
	const open = Boolean(anchorEl);

	const { state } = useLocation();
	// const { deviceId } = state ? state : localStorage.getItem('deviceId');
	const { deviceId, deviceTag } = state;
	console.log('device id', deviceId);
	console.log('device Tag', deviceTag);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	useEffect(() => {
		const FetchTrackData = async () => {
			const allData =
				deviceId !== undefined
					? await ShowDeviceDetailsByDeviceID(deviceId)
					: await ShowDeviceDetailsByDeviceTag(deviceTag);
			console.log('this is all project details', allData);

			if (allData.success === true) {
				setProjectData(allData.body);
				setAddDeviceId(allData.body._id);
				setDeviceInfoDetails(allData.body.device_model);
				setRecordSummary(allData.body.record_summary);
				console.log('data aise ');
			}
		};
		FetchTrackData();
	}, [deviceId, deviceTag]);

	// useEffect(() => {
	// 	const FetchRecordSummaryData = async () => {
	// 		const allData = await ShowAllDeviceRecordSummaryOfFollowingTrack(trackId);
	// 		console.log('this is all device summary details', allData);

	// 		if (allData.success === true) {
	// 			setRecordSummary(allData.body.record_summary);
	// 			console.log('data aise ');
	// 		}
	// 	};
	// 	FetchRecordSummaryData();
	// }, [trackId]);

	return (
		<>
			<div role="presentation" className={styles['layoutForBreadcrumbs']}>
				<Breadcrumbs
					aria-label="breadcrumb"
					separator={<NavigateNextIcon fontSize="small" />}
				>
					<Link underline="hover" color="inherit" href="/userDashboard">
						{t('breadCums.projectBoard')}
					</Link>
					{/* <Link underline="hover" color="inherit" href="/trackBoard">
						Track Board
					</Link>
					<Link underline="hover" color="inherit" href="/deviceBoard">
						Device Board
					</Link> */}
					<Typography color="text.primary">
						{t('breadCums.trackBoard')}
					</Typography>
					<Typography color="text.primary">
						{t('breadCums.deviceBoard')}
					</Typography>
					<Typography color="text.primary">
						{t('breadCums.recordBoard')}
					</Typography>
				</Breadcrumbs>
			</div>
			<Card sx={{ minWidth: 400 }}>
				<CardContent>
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Box>
							<Typography
								sx={{ fontSize: 18 }}
								color="text.secondary"
								gutterBottom
							>
								{t('deviceInformation.title')}
							</Typography>
						</Box>
						<Box sx={{ color: '#11887f' }}>
							<img
								src={progressClock}
								alt="progressClockIcon"
								width="16"
								height="16"
							/>{' '}
							Running
						</Box>
					</Box>
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Box>
							<Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>
								{projectData ? projectData.device_tag : null}
							</Typography>
						</Box>
						<Box>{/* <ProgressBar /> */}</Box>
					</Box>

					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Box sx={{ marginTop: '1rem', width: 290, height: 112 }}>
							{/* <TrackInformation projectData={projectData} /> */}
							<DeviceInformation projectData={projectData} />
						</Box>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								width: 700,
								height: 110
							}}
						>
							<Box sx={{ marginLeft: '50px' }}>
								{projectData && deviceInfoDetails ? (
									<DeviceDetails
										deviceInfoDetails={deviceInfoDetails}
										title={t('deviceDetail.title')}
										serial={projectData.serial}
									/>
								) : null}
							</Box>
							<Box>
								{recordSummary ? (
									<RecordInfo
										recordSummary={recordSummary}
										title={t('recordSummary.title')}
									/>
								) : null}
							</Box>
						</Box>
					</Box>
				</CardContent>
				<CardActions>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							marginTop: 0,
							padding: '10px',
							width: '100%'
						}}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								columnGap: '10px'
							}}
						>
							<Box>
								{/* <Button
									variant="outlined"
									startIcon={
										<img
											src={downloadSummary}
											alt="downloadSummary"
											width="16"
											height="16"
										/>
									}
									sx={{ borderRadius: '5px', color: '#415A77 ' }}
								>
									ADEC PDF
								</Button> */}
								{addDeviceId && deviceId === undefined ? (
									<AdecPdfDonwload deviceId={addDeviceId} />
								) : (
									<AdecPdfDonwload deviceId={deviceId} />
								)}
								{/* <AdecPdfDonwload deviceId={addDeviceId} /> */}
							</Box>
							<Box>
								{/* <Button
									variant="outlined"
									startIcon={
										<img
											src={downloadSummary}
											alt="downloadSummary"
											width="16"
											height="16"
										/>
									}
									sx={{ borderRadius: '5px', color: '#415A77 ' }}
								>
									CSV File
								</Button> */}
							</Box>
						</Box>
						{/* <Box>
							<Button
								variant="contained"
								
								sx={{
									color: 'white',
									backgroundColor: '#415A77',
									borderRadius: '5px'
								}}
								startIcon={
									<img
										src={publishButton}
										alt="publishButton"
										width="16"
										height="16"
									/>
								}
							>
								Publish
							</Button>
						</Box> */}
					</Box>
				</CardActions>
			</Card>

			<Paper
				component="form"
				sx={{
					marginTop: '10px',
					marginBottom: '40px',
					align: 'right',
					alignItems: 'center',
					width: '100%'
				}}
				elevation={2}
			>
				{/* <GlobalSearch
          searchItem={searchItem}
          setSearchItem={setSearchItem}
        />
         <CreateTrackModal /> */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						padding: '20px'
					}}
				>
					<Box>{/* <GlobalSearch /> */}</Box>
					<Box>
						{/* <Button
							variant="outlined"
							sx={{ borderRadius: '5px', color: '#415A77' }}
							startIcon={
								<img
									src={createTrack}
									alt="CreateTrack"
									width="16"
									height="16"
								/>
							}
						>
							Create Record
						</Button> */}
						{projectData.status !== 'completed' ? (
							<RecordModal deviceId={deviceId} />
						) : null}
					</Box>
				</Box>
				<Box>
					<Paper
						component="form"
						sx={{
							marginTop: '0px',
							marginLeft: '20px',
							marginRight: '20px',
							marginBottom: '40px',
							align: 'right',
							alignItems: 'center',
							flex: 1
						}}
						elevation={2}
					>
						{/* <DeviceTable trackId={trackId} /> */}
						{addDeviceId && deviceId === undefined ? (
							<RecordTable deviceId={addDeviceId} />
						) : (
							<RecordTable deviceId={deviceId} />
						)}
					</Paper>
				</Box>
			</Paper>
		</>
	);
};

export default Record;
