import { Box, Typography } from '@mui/material';
import UserCurrentWorkStatCards from './UserCurrentWorkStatCards';
import { useTranslation } from 'react-i18next';

const UserCurrentWorkStat = () => {
	const { t } = useTranslation();
	return (
		<>
			<Box
				display="flex"
				flexDirection="column"
				justifyContent="center"
				gap={1}
				border={1}
				p={2}
				borderColor="#dee4ed"
				borderRadius={2}
			>
				<Typography
					variant="h2"
					noWrap
					fontSize="1rem"
					color="#71839970"
					fontWeight="bold"
				>
					{t('userProfileWork.title')}
				</Typography>
				<UserCurrentWorkStatCards />
			</Box>
		</>
	);
};

export default UserCurrentWorkStat;
