import { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import DashboardCustomizeIcon from '@mui/icons-material/DashboardCustomize';
import {
	Dashboard,
	GroupsRounded,
	Person,
	QrCode2,
	SwapHorizontalCircle,
	// Settings,
	// Widgets,
	TuneOutlined
} from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const drawerWidth = 220;

const openedMixin = (theme) => ({
	width: drawerWidth,
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.enteringScreen
	}),
	overflowX: 'hidden'
});

const closedMixin = (theme) => ({
	transition: theme.transitions.create('width', {
		easing: theme.transitions.easing.sharp,
		duration: theme.transitions.duration.leavingScreen
	}),
	overflowX: 'hidden',
	width: `calc(${theme.spacing(7)} + 1px)`,
	[theme.breakpoints.up('sm')]: {
		width: `calc(${theme.spacing(8)} + 1px)`
	}
});

const DrawerHeader = styled('div')(({ theme }) => ({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	padding: theme.spacing(0, 1),
	// necessary for content to be below app bar
	...theme.mixins.toolbar
}));

const Drawer = styled(MuiDrawer, {
	shouldForwardProp: (prop) => prop !== 'open'
})(({ theme, open }) => ({
	width: drawerWidth,
	flexShrink: 0,
	whiteSpace: 'nowrap',
	boxSizing: 'border-box',
	zIndex: 1,
	...(open && {
		...openedMixin(theme),
		'& .MuiDrawer-paper': openedMixin(theme)
	}),
	...(!open && {
		...closedMixin(theme),
		'& .MuiDrawer-paper': closedMixin(theme)
	})
}));

const userSidebarItems = [
	{
		icon: <GroupsRounded />,
		link: '/userDashboard',
		translateText: 'sideBar.groups'
	},
	{
		icon: <DashboardCustomizeIcon />,
		link: '/acceptedHandover',
		translateText: 'sideBar.acceptedHandover'
	},
	{
		icon: <QrCode2 />,
		link: '/qrCodeReader',
		translateText: 'sideBar.findByQRCode'
	},
	{
		icon: <Person />,
		link: '/profile',
		translateText: 'sideBar.profile'
	}
];

const adminSidebarItems = [
	{
		icon: <Dashboard />,
		link: '/adminDashboard',
		translateText: 'sideBar.dashboard'
	},
	{
		icon: <TuneOutlined />,
		link: '/manage',
		translateText: 'sideBar.manage'
	},
	userSidebarItems[0],
	{
		icon: <SwapHorizontalCircle />,
		link: '/handover',
		translateText: 'sideBar.handover'
	},
	...userSidebarItems.slice(1)
];

const superAdminSidebarItems = [
	{
		icon: <Dashboard />,
		link: '/superAdminDashboard',
		translateText: 'sideBar.dashboard'
	}
];

const Sidebar = () => {
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [sidebarItems, setSidebarItems] = useState([]);
	const navigate = useNavigate();

	useEffect(() => {
		const permissions = localStorage.getItem('user_role');

		if (permissions === 'superAdmin') {
			setSidebarItems(superAdminSidebarItems);
		} else if (permissions === 'admin') {
			setSidebarItems(adminSidebarItems);
		} else {
			setSidebarItems(userSidebarItems);
		}
	}, []);

	const handleDrawer = () => {
		open ? setOpen(false) : setOpen(true);
	};

	return (
		<Box sx={{ display: 'flex' }}>
			<Drawer variant="permanent" open={open}>
				<DrawerHeader
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						ml: 1
					}}
				>
					<IconButton
						disableRipple
						onClick={handleDrawer}
						sx={{
							borderRadius: '10px',
							py: 0.5
						}}
					>
						{open ? (
							<Typography
								variant="h5"
								noWrap
								component="h1"
								fontWeight="bold"
								color="#415a77"
							>
								ETTMS
							</Typography>
						) : (
							<Typography
								variant="h5"
								noWrap
								component="h1"
								fontWeight="bold"
								color="#415a77"
							>
								E
							</Typography>
						)}
					</IconButton>
				</DrawerHeader>
				<List
					sx={{
						flexGrow: 1,
						display: 'flex',
						flexDirection: 'column',
						justifyContent: 'center',
						marginBottom: '10rem'
					}}
				>
					{sidebarItems.map((item, index) => (
						<ListItem key={index} disablePadding sx={{ display: 'block' }}>
							<ListItemButton
								sx={{
									minHeight: 48,
									justifyContent: open ? 'initial' : 'center',
									px: 2.5
								}}
								onClick={() => navigate(item.link)}
							>
								<ListItemIcon
									sx={{
										minWidth: 0,
										mr: open ? 2 : 'auto',
										justifyContent: 'center',
										color: '#415a77'
									}}
								>
									{item.icon}
								</ListItemIcon>
								<ListItemText
									primary={
										item.translateText ? t(item.translateText) : item.text
									}
									sx={{
										opacity: open ? 1 : 0,
										color: '#415a77'
									}}
								/>
							</ListItemButton>
						</ListItem>
					))}
				</List>
			</Drawer>
		</Box>
	);
};

export default Sidebar;
