import { Button, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Formik } from 'formik';
import { useState } from 'react';
import styles from './SendMailToOrganization.module.css';
import { useEffect } from 'react';
import sendCollaborationRequestEmailApi from '../../HandoverApi/SendCollaborationRequestMailApi';
import { useTranslation } from 'react-i18next';

const SendMailToOrganization = () => {
	const { t } = useTranslation();
	const [isValid, setIsValid] = useState(false);
	const [isEmailSended, setIsEmailSended] = useState(false);
	const [isError, setIsError] = useState(false);
	const [groupData, setGroupData] = useState([]);
	const [permissionData, setPermissionData] = useState([]);
	const [selectedGroupId, setSelectedGroupId] = useState('');
	const [selectedGroupName, setSelectedGroupName] = useState([]);

	const initialValues = {
		email: ''
	};

	const validate = (values) => {
		setIsValid(true);
		let errors = {};
		// if (!values.groupName) {
		//     errors.groupName = "Group name is required";
		//     setIsValid(false);
		// }
		// if (!values.permissionName) {
		//     errors.permissionName = "Permission name is required";
		//     setIsValid(false);
		// }

		if (!values.email) {
			errors.email = `${t('collaborationForm.emailRequired')}`;
			setIsValid(false);
		} else if (
			!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
		) {
			errors.email = `${t('collaborationForm.emailInvalid')}`;
			setIsValid(false);
		}

		return errors;
	};

	const onSubmit = async (values, { resetForm }) => {
		console.log('mail value is ', values);
		const response = await sendCollaborationRequestEmailApi(values);
		console.log('mail response', response);
		if (response.success === true) {
			setIsEmailSended(true);
			setIsError(false);
			resetForm();
		} else {
			setIsError(true);
			setIsEmailSended(false);
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validate={validate}
		>
			{(formik) => {
				return (
					<>
						{isEmailSended && (
							<div className={styles['password-reset-email-success']}>
								{t('collaborationForm.success')}
							</div>
						)}
						{isError && (
							<div className={styles['password-reset-email-error']}>
								{t('collaborationForm.error')}
							</div>
						)}

						<h3 className={styles['group-header-center']}>
							{t('collaborationForm.title')}
						</h3>

						<div className={styles['login-input-field']}>
							<FormControl className={styles['login-form']} fullWidth>
								<TextField
									id="email"
									name="email"
									label={t('collaborationForm.email')}
									variant="outlined"
									size="small"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.email}
									error={formik.touched.email && Boolean(formik.errors.email)}
									type="email"
								/>
								<span className={styles['error']}>
									{formik.touched.email && formik.errors.email ? (
										<div>{formik.errors.email}</div>
									) : null}
								</span>
								<br />
							</FormControl>
						</div>
						<Stack justifyContent="center">
							<Button
								type="Submit"
								variant="contained"
								onClick={formik.handleSubmit}
							>
								{t('collaborationForm.send')}
							</Button>
						</Stack>
					</>
				);
			}}
		</Formik>
	);
};

export default SendMailToOrganization;
