import { Button, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import { Formik, Field } from 'formik';
import { useState } from 'react';
import styles from './CreatePermission.module.css';
import { useEffect } from 'react';
import FetchPermissionListApi from './CreatePermissionApi/FetchPermissionListApi';
import CreatePermissionApiHandler from './CreatePermissionApi/CreatePermissionApiHandler';
import { useTranslation } from 'react-i18next';

const CreatePermission = () => {
	const { t } = useTranslation();
	const [isValid, setIsValid] = useState(false);
	const [isError, setIsError] = useState(false);
	const [selectPermissionData, setSelectPermissionData] = useState([]);
	const [isPermissionCreate, setIsPermissionCreate] = useState(false);

	const initialValues = {
		permissionTitle: '',
		checked: []
	};

	const validate = (values) => {
		setIsValid(true);
		let errors = {};
		if (!values.permissionTitle) {
			errors.permissionTitle = `${t('permissionForm.permissionNameRequired')}`;
			setIsValid(false);
		}
		if (values.checked.length === 0) {
			errors.checked = `${t('permissionForm.selectedPermissionRequired')}`;
			setIsValid(false);
		}

		return errors;
	};

	useEffect(() => {
		const FetchSelectPermissionData = async () => {
			const allData = await FetchPermissionListApi();
			console.log(allData.body);
			if (allData.success === true) {
				setSelectPermissionData(await allData.body);
				console.log('data aise ');
			}
		};
		FetchSelectPermissionData();
	}, []);
	const onSubmit = async (values, { resetForm }) => {
		console.log('permission value is ', values);

		const res = await CreatePermissionApiHandler(values);
		console.log('cfeate jpermisisisi', res);
		if (res.success === true) {
			setIsPermissionCreate(true);
			setIsError(false);
			resetForm();
		} else {
			setIsError(true);
			setIsPermissionCreate(false);
		}
	};

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validate={validate}
		>
			{(formik) => {
				return (
					<>
						{isPermissionCreate && (
							<div className={styles['password-reset-email-success']}>
								{t('permissionForm.success')}
							</div>
						)}
						{isError && (
							<div className={styles['password-reset-email-error']}>
								{t('permissionForm.error')}
							</div>
						)}

						<h3 className={styles['group-header-center']}>
							{' '}
							{t('permissionForm.createPermissionTitle')}
						</h3>

						<div className={styles['login-input-field']}>
							<FormControl className={styles['login-form']} fullWidth>
								<TextField
									id="permissionTitle"
									name="permissionTitle"
									label={t('permissionForm.permissionName')}
									variant="outlined"
									size="small"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.permissionTitle}
									error={
										formik.touched.permissionTitle &&
										Boolean(formik.errors.permissionTitle)
									}
									type="text"
								/>
								<span className={styles['error']}>
									{formik.touched.permissionTitle &&
									formik.errors.permissionTitle ? (
										<div>{formik.errors.permissionTitle}</div>
									) : null}
								</span>
								<br />
							</FormControl>

							<FormControl className={styles['login-form']} fullWidth>
								<FormGroup>
									<div id="checkbox-group">
										{t('permissionForm.selectedPermissionLabel')} :
									</div>
									<div
										className={styles['checkbox-align']}
										role="group"
										aria-labelledby="checkbox-group"
									>
										{selectPermissionData &&
											selectPermissionData.map((permission) => (
												<div>
													<label>
														<Field
															type="checkbox"
															name="checked"
															value={`${permission.id}`}
														/>
														{permission.task_title}
													</label>
												</div>
											))}
									</div>
									<span className={styles['error']}>
										{formik.touched.checked && formik.errors.checked ? (
											<div>{formik.errors.checked}</div>
										) : null}
									</span>
									<br />
								</FormGroup>
							</FormControl>
						</div>
						<Stack justifyContent="center">
							<Button
								type="Submit"
								variant="contained"
								onClick={formik.handleSubmit}
							>
								{t('permissionForm.submit')}
							</Button>
						</Stack>
					</>
				);
			}}
		</Formik>
	);
};

export default CreatePermission;
