import React from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';

const CollaborationAfterEmail = () => {
	const accessToken = localStorage.getItem('access_token');
	const params = useParams();
	console.log('The param is sdvwcdfe jhjbjbjbjjjbj', params);

	var decoded = jwt_decode(params.id);

	console.log('The decoded ', decoded);
	console.log('The organization id is ', decoded.organization_id);

	//For navigation to another page starts
	const navigate = useNavigate();

	const handoverRedireact = () => {
		navigate('/handover');
	};
	//For navigation to another page ends

	if (decoded.organization_id) {
		console.log('inside');
		approveCollaboration(decoded.organization_id);
	}

	async function approveCollaboration(decoded) {
		console.log('decode organization id', decoded);

		//last api: "https://tracktest.ultra-x.jp/handover/rehandover/approveHandoverByAdmin
		// console.log(decoded.organization)
		// console.log(h_id)

		const res = await fetch(
			`${process.env.REACT_APP_BASE_URL + 'organizations/createCollaboration'}`,
			{
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: `Bearer ${accessToken}`
				},
				body: JSON.stringify({
					//RequestedOrganization: "639aab5ecce1a1e1c394863a"
					RequestedOrganization: decoded
				})
			}
		);
		const value = await res.json();
		console.log(
			'Approve collaboration data is ' + JSON.stringify(value, null, 2)
		);

		return value;
	}

	return (
		<>
			<center>
				<h1>You have been collaborated</h1>

				<h3>
					Get back to <b> Handover </b> page{' '}
				</h3>
				{/* <button className='handover__button' onClick={handoverRedireact}> Handover Page</button> */}
				<Stack
					spacing={2}
					direction="row"
					justifyContent="center"
					alignItems="center"
				>
					<Button variant="contained" onClick={handoverRedireact}>
						{' '}
						Handover Page{' '}
					</Button>
				</Stack>
			</center>
		</>
	);
};

export default CollaborationAfterEmail;
