import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography } from '@mui/material';
import TopCardList from '../StatViewer/TopCardList';
import StatCardList from '../StatViewer/StatCardList';
// import TableWithFilter from '../TableViewer/TableWithFilter';
import UpcomingDeadlineTable from '../TableViewer/UpcomingDeadlineTable';
import { useTranslation } from 'react-i18next';
import jwt_decode from 'jwt-decode';
// import DemoTable from './DemoTable';
// import UpcomingDeadlineTable from './UpcomingDeadlineTable';
// import DemoTableWithFilter from './DemoTableWithFilter';

const AdminDashboard = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	const now = new Date();
	console.log('local storage', localStorage.getItem('access_token'));
	const decodedToken = jwt_decode(localStorage.getItem('access_token'));
	console.log('decoded token', decodedToken);
	const { exp } = decodedToken;
	console.log('this is exp', exp);

	useEffect(() => {
		if (!localStorage.getItem('access_token') || exp * 1000 < now.getTime()) {
			navigate('/');
			window.location.reload();
		}
	}, [navigate, exp, now]);

	return (
		<>
			<Typography
				variant="h1"
				sx={{ mb: 2 }}
				color="#dee4ed"
				fontWeight="bold"
				fontSize={32}
			>
				{t('dashboard.title')}
			</Typography>
			<Box sx={{ display: 'flex', flexDirection: 'column', gap: '1.2rem' }}>
				<TopCardList />
				<StatCardList />
				{/* <DemoTable /> */}
				{/* <TableWithFilter /> */}
				<UpcomingDeadlineTable />
			</Box>
		</>
	);
};

export default AdminDashboard;
