import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import publishButton from '../../../Icons/publishButton.svg';
import PublishFollowingProject from '../TrackApi/PublishFollowingProject';
import ShowFollowingProjectsDetailsByProjectId from '../TrackApi/ShowFollowingProjectsDetailsByProjectId';
import { useTranslation } from 'react-i18next';

const PublishProject = ({ trackId, publish }) => {
	const { t } = useTranslation();
	const [checkPublish, setCheckPublish] = useState(false);
	const PublishFuntion = async () => {
		const allData = await PublishFollowingProject(trackId);
		console.log('publish details', allData);

		if (allData.success === true) {
			setCheckPublish(true);
			console.log('data aise ');
		}
	};

	useEffect(() => {
		const FetchProjectData = async () => {
			const allData = await ShowFollowingProjectsDetailsByProjectId(trackId);
			console.log('this is all project details', allData);

			if (allData.success === true) {
				console.log('publish hoise ');
			}
		};
		FetchProjectData();
	}, [trackId, checkPublish]);

	return (
		<>
			<Button
				variant="contained"
				// className={styles["publish-button"]}
				sx={{
					color: 'white',
					backgroundColor: '#415A77',
					borderRadius: '5px'
				}}
				startIcon={
					<img src={publishButton} alt="publishButton" width="16" height="16" />
				}
				onClick={PublishFuntion}
				disabled={publish === true ? true : false}
			>
				{t('projectStatus.publish')}
			</Button>
		</>
	);
};

export default PublishProject;
