import React from 'react';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ChartInformationForRecord from './ChartInformationForRecord';
import { useTranslation } from 'react-i18next';

const RecordInfo = ({ recordSummary, title }) => {
	const { t } = useTranslation();
	const { completed, remaining, total } = recordSummary;
	return (
		<>
			<Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
				{title}
			</Typography>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					columnGap: '50px'
				}}
			>
				<Box>
					<Typography
						sx={{
							fontSize: 14,
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center'
						}}
						color="text.secondary"
					>
						{t('recordSummary.total')}
					</Typography>
					<Typography
						sx={{
							fontSize: 14,
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center'
						}}
						color="text.secondary"
					>
						{/* <span><FiberManualRecordIcon fontSize="small" /></span>  <span>Completed</span> */}
						<Stack direction="row" alignItems="center" gap={0.1}>
							<FiberManualRecordIcon
								fontSize="small"
								sx={{ color: '#415A77' }}
							/>
							<Typography
								sx={{
									fontSize: 14,
									display: 'flex',
									flexWrap: 'wrap',
									alignItems: 'center'
								}}
								color="text.secondary"
							>
								{t('recordSummary.completed')}
							</Typography>
						</Stack>
					</Typography>
					{/* <Typography
            sx={{
              fontSize: 14,
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
            color="text.secondary"
          >
            <Stack direction="row" alignItems="center" gap={0.1}>
              <FiberManualRecordIcon
                fontSize="small"
                sx={{ color: "#718399" }}
              />
              <Typography
                sx={{
                  fontSize: 14,
                  display: "flex",
                  flexWrap: "wrap",
                  alignItems: "center",
                }}
                color="text.secondary"
              >
                In Progress
              </Typography>
            </Stack>
          </Typography> */}
					<Typography
						sx={{
							fontSize: 14,
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center'
						}}
						color="text.secondary"
					>
						<Stack direction="row" alignItems="center" gap={0.1}>
							<FiberManualRecordIcon
								fontSize="small"
								sx={{ color: '#DEE4ED' }}
							/>
							<Typography
								sx={{
									fontSize: 14,
									display: 'flex',
									flexWrap: 'wrap',
									alignItems: 'center'
								}}
								color="text.secondary"
							>
								{t('recordSummary.remaining')}
							</Typography>
						</Stack>
					</Typography>
				</Box>

				<Box>
					<Typography
						sx={{
							fontSize: 14,
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center'
						}}
					>
						{total}
					</Typography>
					<Typography
						sx={{
							fontSize: 14,
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center'
						}}
						color="text.secondary"
					>
						{completed}
					</Typography>
					{/* <Typography
            sx={{
              fontSize: 14,
              display: "flex",
              flexWrap: "wrap",
              alignItems: "center",
            }}
            color="text.secondary"
          >
            {inProgress}
          </Typography> */}
					<Typography
						sx={{
							fontSize: 14,
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center'
						}}
						color="text.secondary"
					>
						{remaining}
					</Typography>
				</Box>

				<Box sx={{ height: 80, width: 80 }}>
					{/* <ChartInformationForTrack trackSummary={recordSummary} /> */}
					{/* <ChartInformationForDevice recordSummary={recordSummary} /> */}
					<ChartInformationForRecord recordSummary={recordSummary} />
				</Box>
			</Box>
		</>
	);
};

export default RecordInfo;
