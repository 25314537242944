import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import {
	ExpandMore,
	LogoutRounded,
	Mail,
	Notifications,
	Person,
	Search
} from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import {
	AppBar,
	Avatar,
	Badge,
	Box,
	Divider,
	IconButton,
	Menu,
	MenuItem,
	Stack,
	Toolbar,
	Typography
} from '@mui/material';
import Tooltip from '@mui/material/Tooltip';
import LanguageButton from '../LanguageChangeComponents/LanguageButton';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';

const Header = () => {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const navigate = useNavigate();

	const open = Boolean(anchorEl);
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const logout = () => {
		localStorage.removeItem('access_token');
		navigate('/');
	};

	// get user_name from local storage
	// const user_name = JSON.parse(localStorage.getItem('user_name'));
	// const organization_name_en = JSON.parse(
	// 	localStorage.getItem('organization_name_en')
	// );

	const user_name = JSON.parse(localStorage.getItem('user_name'));
	const jwt_token = localStorage.getItem('access_token');
	const email = jwt_decode(jwt_token).email;
	const organization_name_en = jwt_decode(jwt_token).organization_name_en;

	const handleClickChange = (e) => {
		i18next.changeLanguage(e.target.value);
	};

	return (
		<>
			<AppBar
				position="static"
				color="inherit"
				sx={{
					padding: '0 30px',
					color: '#415a77',
					borderBottom: '1px solid #dee4ed',
					boxShadow: 'none'
				}}
			>
				<Toolbar>
					<Box sx={{ flexGrow: 1 }} />
					<Stack direction="row" spacing={1} alignItems="center">
						{/* <IconButton
							size="large"
							aria-label="search box"
							sx={{
								color: '#718399'
							}}
						>
							<Search />
						</IconButton> */}
						<Tooltip title="Refresh">
							<IconButton
								size="large"
								aria-label="show 4 new mails"
								sx={{
									color: '#718399'
								}}
								onClick={() => window.location.reload()}
							>
								<Badge badgeContent={0} color="error">
									{/* <Mail /> */}
									<RefreshIcon />
								</Badge>
							</IconButton>
						</Tooltip>
						{/* <IconButton
							size="large"
							aria-label="show 17 new notifications"
							sx={{
								color: '#718399'
							}}
						>
							<Badge badgeContent={0} color="error">
								<Notifications />
							</Badge>
						</IconButton> */}
						<LanguageButton onChange={(e) => handleClickChange(e)} />

						<Divider orientation="vertical" variant="middle" flexItem />
						<IconButton
							size="large"
							edge="end"
							aria-label="account of current user"
							aria-haspopup="true"
							color="inherit"
							sx={{
								border: '1px solid #f6f8fa',
								borderRadius: 50,
								padding: 0.5
							}}
							onClick={handleClick}
						>
							<Stack direction="row" spacing={0.6} alignItems="center">
								<Avatar
									alt="Profile Avatar"
									src={`https://api.dicebear.com/6.x/avataaars/svg?seed=${email}&size=120&skinColor=ffdbb4&eyes=default&mouth=default&eyebrows=default&hairColor=2c1b18`}
									sx={{
										border: '2px solid #dee4ed'
									}}
								/>
								<Stack direction="column" alignItems="start">
									<Typography
										variant="body2"
										noWrap
										fontSize="0.9rem"
										fontWeight="bold"
									>
										{user_name}
									</Typography>
									<Typography variant="body2" noWrap fontSize="0.7rem">
										{organization_name_en}
									</Typography>
								</Stack>
								<ExpandMore
									sx={{
										color: '#718399'
									}}
								/>
							</Stack>
						</IconButton>
						<Menu
							anchorEl={anchorEl}
							open={open}
							onClose={handleClose}
							sx={{
								'& .MuiList-root': {
									padding: 0.5
								},
								'& .MuiPaper-root': {
									borderRadius: '10px',
									boxShadow: '0px 0px 10px 0px #dee4ed'
								}
							}}
						>
							<MenuItem
								onClick={() => navigate('/profile')}
								disableRipple
								sx={{
									width: '150px'
								}}
							>
								<Box display="flex" alignItems="center" gap={1}>
									<Person
										sx={{
											fontSize: '1.2rem'
										}}
									/>
									<Typography mt={0.2}>{t('header.profile')}</Typography>
								</Box>
							</MenuItem>
							<MenuItem
								onClick={() => logout()}
								disableRipple
								sx={{
									width: '150px'
								}}
							>
								<Box display="flex" alignItems="center" gap={1}>
									<LogoutRounded
										sx={{
											fontSize: '1.2rem'
										}}
									/>
									<Typography mt={0.2}>{t('header.logout')}</Typography>
								</Box>
							</MenuItem>
						</Menu>
					</Stack>
				</Toolbar>
			</AppBar>
		</>
	);
};

export default Header;
