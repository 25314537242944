import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './DeviceBoard.module.css';
import Device from './Device';

const DeviceBoard = () => {
	const navigate = useNavigate();
	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			navigate('/');
		}
	}, [navigate]);
	return (
		<>
			<div className={styles[`adminDashboard-wrapper`]}>
				<div></div>
				<div className={styles['adminDashboardTable-wraper']}>
					<Device />
				</div>
			</div>
		</>
	);
};

export default DeviceBoard;
