// import CloseIcon from "@mui/icons-material/Close";
import { Button, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
// import { useRouter } from "next/router";
import { useState, useEffect, useContext } from 'react';
import styles from './CreateDeviceModel.module.css';
import CloseIcon from '@mui/icons-material/Close';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { maxWidth } from '@mui/system';
import CreateDeviceModelApi from '../../DeviceApi/CreateDeviceModelApi';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';

// import ETTMSText from "/public/locals";
// import forgetPasswordApi from "./forgetPasswordApi";
const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4
};

const CreateDeviceModel = () => {
	// const {tokenValue} = useContext(TokenContext);
	// console.log("group", tokenValue)
	//   const { locale } = useRouter();
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const handleOpen = () => setOpen(true);
	const [isProjectCreate, setIsProjectCreate] = useState(false);
	const [isError, setIsError] = useState(false);
	const [status, setStatus] = useState(false);
	const [allGroupData, setAllGroupData] = useState([]);
	const [allDeviceData, setAllDeviceData] = useState([]);
	const handleClose = () => {
		setIsProjectCreate(false);
		setIsError(false);
		setOpen(false);
		//formik form reset
		// Formik.resetForm();
	};

	const initialValues = {
		model: '',
		nameEn: '',
		nameJp: '',
		deviceType: '',
		manufacturer: '',
		spacifications: ''
	};

	const validate = (values) => {
		setIsValid(true);
		let errors = {};
		if (!values.model) {
			errors.model = `${t('createDevice.modelNumberRequired')}`;
			setIsValid(false);
		}
		if (!values.nameEn) {
			errors.nameEn = `${t('createDevice.nameEnglishRequired')}`;
			setIsValid(false);
		}

		if (!values.nameJp) {
			errors.nameJp = `${t('createDevice.nameJapaneseRequired')}`;
			setIsValid(false);
		}
		if (!values.deviceType) {
			errors.deviceType = `${t('createDevice.deviceTypeRequired')}`;
			setIsValid(false);
		}
		if (!values.manufacturer) {
			errors.manufacturer = `${t('createDevice.manufacturerRequired')}`;
			setIsValid(false);
		}
		if (!values.spacifications) {
			errors.spacifications = `${t('createDevice.specificationRequired')}`;
			setIsValid(false);
		}

		return errors;
	};

	// const loginFormSubmit = (e) => {
	//   e.preventDefault()
	//   router.push('/home')
	// }
	//   useEffect(() => {
	//     const FetchDeviceTypeData = async () => {
	//       const allData = await FetchAllDeviceTypeApiHandler();
	//       console.log(allData.body);
	//       if (allData.success === true) {
	//         setAllDeviceData(await allData.body);
	//       }
	//     };
	//     FetchDeviceTypeData();
	//   }, []);

	const onSubmit = async (values, { resetForm }) => {
		console.log('device information value', values);
		setStatus(true);
		const res = await CreateDeviceModelApi(values);
		console.log('create model', res);
		if (res.success === true) {
			setIsProjectCreate(true);
			setIsError(false);
			resetForm();
		} else {
			setIsError(true);
			setIsProjectCreate(false);
		}

		// handleNext();
		// if (res.success) {
		//   console.log(res);
		//   console.log(" forget password success");
		//   setIsEmailSended(true);
		// } else {
		//   console.log(res);
		//   console.log(" forget password failed");
		//   setIsError(true);
		// }
		// console.log("forgetPassword res", res);
		// e.preventDefault()
		// router.push('/home')
	};

	return (
		<>
			{/* <div>
        <ToastContainer />
      </div> */}
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validate={validate}
			>
				{(formik) => {
					return (
						<>
							{/* <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            > */}
							{/* <Box sx={style}>
                <div className={styles.closeButton}>
                  <Button onClick={handleClose}>
                    <CloseIcon />
                  </Button>
                </div> */}
							{isProjectCreate && (
								<Alert severity="success">
									{t('createDevice.modelSuccess')}
								</Alert>
							)}
							{isError && (
								<Alert severity="error">{t('createDevice.modelError')}</Alert>
							)}

							{/*
                <p id="modal-modal-title" variant="h6" component="h2">
                 { ETTMSText[locale].welcomePage.forgetPassword.forgetPasswordHeader}
                </p> */}

							<h3 className={styles['group-header-center']}>
								{t('createDevice.createDeviceModel')}
							</h3>
							<div className={styles['login-input-field']}>
								<br />
								<FormControl className={styles['login-form']} fullWidth>
									<TextField
										id="model"
										name="model"
										label={t('createDevice.modelNumber')}
										variant="outlined"
										size="small"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.model}
										error={formik.touched.model && Boolean(formik.errors.model)}
										type="text"
									/>
									<span className={styles['error']}>
										{formik.touched.model && formik.errors.model ? (
											<div>{formik.errors.model}</div>
										) : null}
									</span>
									<br />
								</FormControl>

								<FormControl className={styles['login-form']} fullWidth>
									<TextField
										id="nameEn"
										name="nameEn"
										label={t('createDevice.nameEnglish')}
										variant="outlined"
										size="small"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.nameEn}
										error={
											formik.touched.nameEn && Boolean(formik.errors.nameEn)
										}
										type="text"
									/>
									<span className={styles['error']}>
										{formik.touched.nameEn && formik.errors.nameEn ? (
											<div>{formik.errors.nameEn}</div>
										) : null}
									</span>
									<br />
								</FormControl>

								<FormControl className={styles['login-form']} fullWidth>
									<TextField
										id="nameJp"
										name="nameJp"
										label={t('createDevice.nameJapanese')}
										variant="outlined"
										size="small"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.nameJp}
										error={
											formik.touched.nameJp && Boolean(formik.errors.nameJp)
										}
										type="text"
									/>
									<span className={styles['error']}>
										{formik.touched.nameJp && formik.errors.nameJp ? (
											<div>{formik.errors.nameJp}</div>
										) : null}
									</span>
									<br />
								</FormControl>

								<div className={styles['topMarginForMaker']}>
									<FormControl
										required
										className={styles['login-form']}
										fullWidth
									>
										<InputLabel id="organization-initial-label">
											{t('createDevice.deviceType')}
										</InputLabel>
										{/* {formik.values.organizationInitial ? setOrganizationInitialName(formik.values.organizationInitial): null} */}
										<Select
											labelId="demo-simple-select-label"
											id="deviceType"
											name="deviceType"
											value={formik.values.deviceType}
											label="Device Type"
											size="small"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											error={
												formik.touched.deviceType &&
												Boolean(formik.errors.deviceType)
											}
										>
											{/* {allDeviceData &&
												allDeviceData.map((deviceData) => (
													<MenuItem key={deviceData._id} value={deviceData._id}>
														{deviceData.name_en}
													</MenuItem>
												))} */}
											<MenuItem key={1} value={'hdd'}>
												HDD
											</MenuItem>
											<MenuItem key={2} value={'ssd'}>
												SSD
											</MenuItem>
											<MenuItem key={3} value={'laptop'}>
												Laptop
											</MenuItem>
											<MenuItem key={4} value={'mobile'}>
												Mobile
											</MenuItem>
											<MenuItem key={5} value={'tablet'}>
												Tablet
											</MenuItem>
										</Select>
										<span className={styles['error']}>
											{formik.touched.deviceType && formik.errors.deviceType ? (
												<div>{formik.errors.deviceType}</div>
											) : null}
										</span>

										<br />
									</FormControl>
								</div>

								<FormControl className={styles['login-form']} fullWidth>
									<TextField
										id="manufacturer"
										name="manufacturer"
										label={t('createDevice.manufacturer')}
										variant="outlined"
										size="small"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.manufacturer}
										error={
											formik.touched.manufacturer &&
											Boolean(formik.errors.manufacturer)
										}
										type="text"
									/>
									<span className={styles['error']}>
										{formik.touched.manufacturer &&
										formik.errors.manufacturer ? (
											<div>{formik.errors.manufacturer}</div>
										) : null}
									</span>
									<br />
								</FormControl>

								<FormControl className={styles['login-form']} fullWidth>
									<TextField
										id="spacifications"
										name="spacifications"
										label={t('createDevice.specification')}
										variant="outlined"
										size="small"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.spacifications}
										error={
											formik.touched.spacifications &&
											Boolean(formik.errors.spacifications)
										}
										type="text"
									/>
									<span className={styles['error']}>
										{formik.touched.spacifications &&
										formik.errors.spacifications ? (
											<div>{formik.errors.spacifications}</div>
										) : null}
									</span>
									<br />
									<br />
								</FormControl>
							</div>
							<Stack justifyContent="center">
								<Button
									type="Submit"
									variant="contained"
									// className={styles["login-in-btn"]}
									onClick={formik.handleSubmit}
								>
									{t('createDevice.submit')}
								</Button>
							</Stack>
							{/* </Box>
            </Modal> */}
							{/* <div className={styles["organization-button-control"]}>
                <Button variant="contained"  onClick={handleOpen} >Create Organization</Button>
            </div> */}
							{/* <Button
              onClick={handleOpen}
              className={styles["login-forget-password"]}
            >
              ki khobor
            </Button> */}
						</>
					);
				}}
			</Formik>
		</>
	);
};

export default CreateDeviceModel;
