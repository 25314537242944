import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import ProfileCard from './ProfileCard';
import UserCurrentWorkStat from './UserCurrentWorkStat';

const UserProfile = () => {
	const navigate = useNavigate();
	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			navigate('/');
		}
	}, [navigate]);
	return (
		<>
			<Box display="flex" justifyContent="center" gap={3}>
				<ProfileCard />
				<UserCurrentWorkStat />
			</Box>
		</>
	);
};

export default UserProfile;
