import { Button, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Formik } from 'formik';
import { useState } from 'react';
import styles from './SendMail.module.css';
import { useEffect } from 'react';
import OrganizationApiFetchHandle from './OrganizationApi/OrganizationApiFetchHandle';
import OrganizationSendMailApiHandler from './OrganizationApi/OrganizationSendMailApiHandler';
import FetchAllPermissionApi from './OrganizationApi/FetchAllPermissionApi';
import { useTranslation } from 'react-i18next';

const SendMail = ({ setNextMailButton }) => {
	const { t } = useTranslation();
	const [isValid, setIsValid] = useState(false);
	const [isEmailSended, setIsEmailSended] = useState(false);
	const [isError, setIsError] = useState(false);
	const [organization, setOrganization] = useState([]);
	const [organizationInitialName, setOrganizationInitialName] = useState('');
	const [organizationData, setOrganizationData] = useState([]);
	const [assignPermissionValue, setAssignPermissionValue] = useState([]);
	console.log('checkfr data', organizationData);
	console.log(organization);

	const initialValues = {
		organizationInitial: '',
		assignPermission: '',
		email: ''
	};

	const validate = (values) => {
		setIsValid(true);
		let errors = {};
		if (!values.organizationInitial) {
			errors.organizationInitial = `${t(
				'createOrganization.organizationInitialRequired'
			)}`;
			setIsValid(false);
		}

		if (!values.assignPermission) {
			errors.assignPermission = `${t('sendMail.assignPermissionRequired')}`;
			setIsValid(false);
		}

		if (!values.email) {
			errors.email = `${t('sendMail.enterEmailRequired')}`;
			setIsValid(false);
		} else if (
			!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
		) {
			errors.email = `${t('sendMail.invalidEmail')}`;
			setIsValid(false);
		}

		return errors;
	};

	const onSubmit = async (values) => {
		console.log('mail value is ', values);
		console.log('organiztion value is', organization);
		const res = await OrganizationSendMailApiHandler(values, organization);
		console.log(res);
		if (res.success === true) {
			setIsEmailSended(true);
			setIsError(false);
			setNextMailButton(true);
		} else {
			setIsError(true);
			setIsEmailSended(false);
		}
	};

	useEffect(() => {
		const FetchOrganizationData = async () => {
			const allData = await OrganizationApiFetchHandle();
			console.log(allData.body);
			if (allData.success === true) {
				setOrganizationData(await allData.body);
				console.log('data aise ');
			}
		};
		FetchOrganizationData();
	}, []);

	useEffect(() => {
		const FetchPermissionData = async () => {
			const allData = await FetchAllPermissionApi();
			console.log(allData.body);
			if (allData.success === true) {
				setAssignPermissionValue(await allData.body);
				console.log('data aise ');
			}
		};
		FetchPermissionData();
	}, []);

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validate={validate}
		>
			{(formik) => {
				return (
					<>
						{isEmailSended && (
							<div className={styles['password-reset-email-success']}>
								{t('sendMail.success')}
							</div>
						)}
						{isError && (
							<div className={styles['password-reset-email-error']}>
								{t('sendMail.error')}
							</div>
						)}

						<h3>{t('sendMail.title')}</h3>

						<div className={styles['login-input-field']}>
							<FormControl required className={styles['login-form']} fullWidth>
								<InputLabel id="organization-initial-label">
									{t('createOrganization.organizationInitial')}
								</InputLabel>

								{formik.values.organizationInitial
									? setOrganizationInitialName(
											formik.values.organizationInitial
									  )
									: null}
								<Select
									labelId="demo-simple-select-label"
									id="organizationInitial"
									name="organizationInitial"
									value={formik.values.organizationInitial}
									label="Organization Initial"
									size="small"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.organizationInitial &&
										Boolean(formik.errors.organizationInitial)
									}
								>
									{organizationData.map((organization) => (
										<MenuItem
											key={organization._id}
											value={organization.initial}
										>
											{organization.initial}
										</MenuItem>
									))}
								</Select>
								<span className={styles['error']}>
									{formik.touched.organizationInitial &&
									formik.errors.organizationInitial ? (
										<div>{formik.errors.organizationInitial}</div>
									) : null}
								</span>

								<br />
							</FormControl>
							<FormControl className={styles['login-form']} fullWidth>
								{organizationData.map((i) =>
									organizationInitialName === i.initial
										? setOrganization(i)
										: null
								)}
								<TextField
									id="organizationNameEn"
									name="organizationNameEn"
									label={t('createOrganization.organizationNameEn')}
									variant="outlined"
									size="small"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={organization.name_en}
									defaultValue=" "
									InputProps={{
										readOnly: true
									}}
									error={
										formik.touched.organizationNameEn &&
										Boolean(formik.errors.organizationNameEn)
									}
									type="text"
								/>
								<span className={styles['error']}>
									{formik.touched.organizationNameEn &&
									formik.errors.organizationNameEn ? (
										<div>{formik.errors.organizationNameEn}</div>
									) : null}
								</span>
								<br />
							</FormControl>
							<FormControl className={styles['login-form']} fullWidth>
								<TextField
									id="organizationNameJp"
									name="organizationNameJp"
									label={t('createOrganization.organizationNameJp')}
									variant="outlined"
									size="small"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={organization.name_jp}
									defaultValue=" "
									InputProps={{
										readOnly: true
									}}
									error={
										formik.touched.organizationNameJp &&
										Boolean(formik.errors.organizationNameJp)
									}
									type="text"
								/>
								<span className={styles['error']}>
									{formik.touched.organizationNameJp &&
									formik.errors.organizationNameJp ? (
										<div>{formik.errors.organizationNameJp}</div>
									) : null}
								</span>
								<br />
							</FormControl>

							<FormControl required className={styles['login-form']} fullWidth>
								<InputLabel id="organization-initial-label">
									{t('sendMail.assignPermission')}
								</InputLabel>

								<Select
									labelId="demo-simple-select-label"
									id="assignPermission"
									name="assignPermission"
									value={formik.values.assignPermission}
									label="Assign Permission"
									size="small"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.assignPermission &&
										Boolean(formik.errors.assignPermission)
									}
								>
									{assignPermissionValue.map((row) => (
										<MenuItem key={row._id} value={row._id}>
											{row.title}
										</MenuItem>
									))}
								</Select>
								<span className={styles['error']}>
									{formik.touched.assignPermission &&
									formik.errors.assignPermission ? (
										<div>{formik.errors.assignPermission}</div>
									) : null}
								</span>

								<br />
							</FormControl>

							<FormControl className={styles['login-form']} fullWidth>
								<TextField
									id="email"
									name="email"
									label={t('sendMail.enterEmail')}
									variant="outlined"
									size="small"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									value={formik.values.email}
									error={formik.touched.email && Boolean(formik.errors.email)}
									type="email"
								/>
								<span className={styles['error']}>
									{formik.touched.email && formik.errors.email ? (
										<div>{formik.errors.email}</div>
									) : null}
								</span>
								<br />
							</FormControl>
						</div>
						<Stack justifyContent="center">
							<Button
								type="Submit"
								variant="contained"
								onClick={formik.handleSubmit}
							>
								{t('sendMail.submit')}
							</Button>
						</Stack>
					</>
				);
			}}
		</Formik>
	);
};

export default SendMail;
