import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import styles from './DeviceInfoShowTable.module.css';
import FetchSingleDeviceInfoByQRCodeApi from '../../../TrackBoardApi/FetchSingleDeviceInfoByQRCodeApi';

const DeviceInfoShowTable = (props) => {
	const [dashboardData, setDashboardData] = useState([]);
	const [deviceName, setDeviceName] = useState(null);
	console.log(dashboardData);

	useEffect(() => {
		const FetchData = async () => {
			const response = await FetchSingleDeviceInfoByQRCodeApi(props.id);

			console.log('single device api', response.body);
			setDashboardData(response.body.details);
			setDeviceName(response.body.details.device_type.name_en);
		};
		FetchData();
	}, [props.id]);

	return (
		<>
			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<h2 className={styles['recordHeaderCenter']}>
					Device Information Details
				</h2>
				<TableContainer sx={{ maxHeight: 445 }}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell
									align="center"
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
								>
									Device ID
								</TableCell>
								<TableCell
									align="center"
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
								>
									Device Name
								</TableCell>
								<TableCell
									align="center"
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
								>
									Vendor
								</TableCell>
								<TableCell
									align="center"
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
								>
									Model
								</TableCell>
								<TableCell
									align="center"
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
								>
									Serial
								</TableCell>
								<TableCell
									align="center"
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
								>
									Specification
								</TableCell>
								<TableCell
									align="center"
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
								>
									State
								</TableCell>
								<TableCell
									align="center"
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
								>
									Comment
								</TableCell>
							</TableRow>
						</TableHead>
						<TableBody>
							{/* {dashboardData && dashboardData.map((row) => ( */}
							<TableRow
								key={dashboardData._id}
								sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
							>
								<TableCell component="th" scope="row" align="center">
									{dashboardData.device_tag}
								</TableCell>
								<TableCell align="center">
									{deviceName === null ? null : deviceName}
								</TableCell>
								<TableCell align="center">{dashboardData.vendor}</TableCell>
								<TableCell align="center">{dashboardData.model}</TableCell>
								<TableCell align="center">{dashboardData.serial}</TableCell>
								<TableCell align="center">
									{dashboardData.spacifications}
								</TableCell>
								<TableCell align="center">{dashboardData.state}</TableCell>
								<TableCell align="center">{dashboardData.comment}</TableCell>
							</TableRow>
							{/* ))} */}
						</TableBody>
					</Table>
				</TableContainer>
			</Paper>
		</>
	);
};

export default DeviceInfoShowTable;
