import React, { useState, useEffect } from 'react';
import Button from '@mui/material/Button';
import downloadSummary from '../../../Icons/downloadSummary.svg';
import ShowDeviceDetailsByDeviceID from '../RecordApi/ShowDeviceDetailsByDeviceId';
import ShowAllRecordOfFollowingDevice from '../RecordApi/ShowAllRecordOfFollowingDevice';
import {
	PDFDownloadLink,
	Page,
	Text,
	View,
	Document,
	StyleSheet,
	Image,
	Link,
	Font
} from '@react-pdf/renderer';
import AdecPdfDocument from './AdecPdfDocument';

const AdecPdfDonwload = ({ deviceId }) => {
	const [deviceDetails, setDeviceDetails] = useState([]);
	const [recordDetails, setRecordDetails] = useState([]);

	console.log('device data', deviceDetails);
	console.log('record data', recordDetails);

	useEffect(() => {
		const FetchDeviceData = async () => {
			const allData = await ShowDeviceDetailsByDeviceID(deviceId);
			console.log('this is all project details', allData);

			if (allData.success === true) {
				setDeviceDetails(allData.body);
				console.log('data aise ');
			}
		};
		FetchDeviceData();
	}, [deviceId]);

	useEffect(() => {
		const FetchRecordData = async () => {
			const allData = await ShowAllRecordOfFollowingDevice(deviceId);
			console.log('this is all project details', allData);

			if (allData.success === true) {
				setRecordDetails(allData.body);
				console.log('data aise ');
			}
		};
		FetchRecordData();
	}, [deviceId]);

	return (
		<>
			{/* <Button
				variant="outlined"
				startIcon={
					<img
						src={downloadSummary}
						alt="downloadSummary"
						width="16"
						height="16"
					/>
				}
				sx={{ borderRadius: '5px', color: '#415A77 ' }}
				onClick={() => alert('dkdjdj')}
			>
				ADEC PDF
			</Button> */}

			<PDFDownloadLink
				document={
					<AdecPdfDocument
						deviceDetails={deviceDetails}
						recordDetails={recordDetails}
					/>
				}
				fileName={`${deviceId}_${Date.now()}.pdf`}
			>
				{({ blob, url, loading, error }) =>
					loading ? (
						''
					) : (
						<Button
							variant="outlined"
							startIcon={
								<img
									src={downloadSummary}
									alt="downloadSummary"
									width="16"
									height="16"
								/>
							}
							sx={{ borderRadius: '5px', color: '#415A77 ' }}
						>
							ADEC PDF
						</Button>
					)
				}
			</PDFDownloadLink>
		</>
	);
};

export default AdecPdfDonwload;
