const CreateOrganizationApi = async (values) => {
	const accessToken = localStorage.getItem('access_token');
	const res = await fetch(
		`${process.env.REACT_APP_BASE_URL + 'organizations/createOrganization'}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				initial: values.organizationInitial,
				name_en: values.organizationNameEn,
				name_jp: values.organizationNameJp
			})
		}
	);

	const data = await res.json();
	console.log(data);
	return data;
};

export default CreateOrganizationApi;
