import React from 'react';
import Typography from '@mui/material/Typography';
import styles from './DeviceDetails.module.css';
import { useTranslation } from 'react-i18next';

const DeviceDetails = ({ deviceInfoDetails, title, serial }) => {
	const { t } = useTranslation();
	const { model, device_type, manufacturer, spacifications } =
		deviceInfoDetails;
	console.log('inside details', deviceInfoDetails);
	return (
		<>
			<Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
				{title}
			</Typography>
			<div className={styles['main-information']}>
				<div className={styles['main-information-align']}>
					<div className={styles['information-row1']}>
						<Typography
							className={styles['information-item']}
							color="text.secondary"
						>
							{t('deviceDetail.deviceType')}
						</Typography>
						<Typography
							className={styles['information-item']}
							color="text.secondary"
						>
							{t('deviceDetail.serial')}
						</Typography>
						<Typography
							className={styles['information-item']}
							color="text.secondary"
						>
							{t('deviceDetail.model')}
						</Typography>
						<Typography
							className={styles['information-item']}
							color="text.secondary"
						>
							{t('deviceDetail.manufacturer')}
						</Typography>
						<Typography
							className={styles['information-item']}
							color="text.secondary"
						>
							{t('deviceDetail.specification')}
						</Typography>
					</div>

					<div className={styles['information-row2']}>
						<Typography className={styles['information-item']}>
							{device_type}
						</Typography>
						<Typography className={styles['information-item']}>
							{serial}
						</Typography>
						<Typography className={styles['information-item']}>
							{model}
						</Typography>
						<Typography className={styles['information-item']}>
							{manufacturer}
						</Typography>
						<Typography className={styles['information-item']}>
							{spacifications}
						</Typography>
					</div>
				</div>
			</div>
		</>
	);
};

export default DeviceDetails;
