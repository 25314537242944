const RegistrationApiHandlerForUser = async (
	values,
	email,
	organization_id,
	permission_id,
	group_id
) => {
	// alert(JSON.stringify(values, null, 2));
	// e.preventDefault()
	console.log('inside group', group_id);
	// `${process.env.REACT_APP_BASE_URL+"users/createUser"
	const res = await fetch(
		`${process.env.REACT_APP_BASE_URL + 'users/createUser'}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json'
			},
			body: JSON.stringify({
				organization: organization_id,
				email: email,
				first_name: values.firstName,
				last_name: values.lastName,
				password: values.password,
				permission: permission_id,
				group: group_id
			})
		}
	);
	const data = await res.json();
	return data;
};

export default RegistrationApiHandlerForUser;
