import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const getDatePlease = (D) => {
	return new Date(D).toLocaleDateString();
};

const TrackInformation = ({ projectData }) => {
	const { t } = useTranslation();
	const {
		status,
		project,
		request_type,
		createdAt,
		deadline,
		start_date,
		end_date
	} = projectData;
	const [initialDate, setInitialDate] = useState('1970-01-01T00:00:00.000Z');
	return (
		<>
			<Box sx={{ display: 'flex', flexDirection: 'row' }}>
				<Box sx={{ width: '70%', textAlign: 'left' }}>
					<Typography sx={{ fontSize: 14 }} color="text.secondary">
						{t('trackInformation.status')}
					</Typography>
					<Typography sx={{ fontSize: 14 }} color="text.secondary">
						{t('trackInformation.project')}
					</Typography>
					<Typography sx={{ fontSize: 14 }} color="text.secondary">
						{t('trackInformation.request')}
					</Typography>
					<Typography sx={{ fontSize: 14 }} color="text.secondary">
						{t('trackInformation.createdDate')}
					</Typography>
					<Typography sx={{ fontSize: 14 }} color="text.secondary">
						{t('trackInformation.duration')}
					</Typography>
					<Typography sx={{ fontSize: 14 }} color="text.secondary">
						{t('trackInformation.deadline')}
					</Typography>
				</Box>

				<Box sx={{ width: '100%', textAlign: 'left' }}>
					<Typography sx={{ fontSize: 14 }} color="#D9AD8D">
						{status ? status.toUpperCase() : null}
					</Typography>
					<Typography sx={{ fontSize: 14 }}>
						{' '}
						{project ? project.name_en : null}
					</Typography>
					<Typography sx={{ fontSize: 14 }}>
						{request_type ? request_type.title_en : null}
					</Typography>
					<Typography sx={{ fontSize: 14 }}>
						{getDatePlease(createdAt)}
					</Typography>
					<Typography sx={{ fontSize: 14 }}>
						{' '}
						{start_date === initialDate
							? 'N/A'
							: getDatePlease(start_date)} ~{' '}
						{end_date === initialDate ? 'N/A' : getDatePlease(end_date)}
					</Typography>
					<Typography sx={{ fontSize: 14 }} color="red">
						{getDatePlease(deadline)}
					</Typography>
				</Box>
			</Box>
		</>
	);
};

export default TrackInformation;
