const FindCurrentToolApi = async (deviceId) => {
	const accessToken = localStorage.getItem('access_token');
	console.log(accessToken);
	console.log('kono ekta', deviceId);
	const res = await fetch(
		`${
			process.env.REACT_APP_BASE_URL +
			'tools/showAllActiveToolsOfFollowingOperationOfMyOrganization'
		}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				processing_type: deviceId
			})
		}
	);

	const data = await res.json();
	console.log(data);
	return data;
};

export default FindCurrentToolApi;
