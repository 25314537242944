import { Button, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Formik, Field } from 'formik';
import { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import styles from './CreateRequest.module.css';
import AddIcon from '@mui/icons-material/Add';
import RemoveIcon from '@mui/icons-material/Remove';
// import CreateToolApiHandler from './CreateToolApi/CreateToolApiHandler';
import { useTranslation } from 'react-i18next';
import CreateRequestTypeApi from './CreateRequestTypeApi';

const CreateRequest = () => {
	const { t } = useTranslation();
	const [isValid, setIsValid] = useState(false);
	const [isGroupCreate, setIsGroupCreate] = useState(false);
	const [isError, setIsError] = useState(false);
	const [status, setStatus] = useState(false);
	const [array, setArray] = useState([]);

	const incrementValueForErase = () => {
		const newValue = 'erase';
		setArray([...array, newValue]);
	};

	const incrementValueForPurge = () => {
		const newValue = 'purge';
		setArray([...array, newValue]);
	};
	const decrementValueForErase = () => {
		if (array.length > 0) {
			let newArray = [...array];
			for (let i = newArray.length - 1; i >= 0; i--) {
				if (newArray[i] === 'erase') {
					newArray.splice(i, 1);
					break;
				}
			}
			setArray(newArray);
		}
	};
	const decrementValueForPurge = () => {
		if (array.length > 0) {
			let newArray = [...array];
			for (let i = newArray.length - 1; i >= 0; i--) {
				if (newArray[i] === 'purge') {
					newArray.splice(i, 1);
					break;
				}
			}
			setArray(newArray);
		}
	};

	const initialValues = {
		groupNameEn: array
	};

	const validate = (values) => {
		setIsValid(true);
		let errors = {};

		// if (!values.groupNameEn) {
		// 	errors.groupNameEn = 'Request type is required';
		// 	setIsValid(false);
		// }
		// if (array.length === 0) {
		// 	errors.groupNameEn = 'Request type is required';
		// 	setIsValid(false);
		// }

		return errors;
	};

	const onSubmit = async (values, { resetForm }) => {
		console.log('request type value', array);
		setStatus(true);
		const res = await CreateRequestTypeApi(array);
		console.log(res);
		if (res.success === true) {
			setIsGroupCreate(true);
			setIsError(false);
			resetForm();
		} else {
			setIsError(true);
			setIsGroupCreate(false);
		}
	};

	return (
		<>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validate={validate}
			>
				{(formik) => {
					return (
						<>
							{isGroupCreate && (
								<div className={styles['password-reset-email-success']}>
									{t('requestType.success')}
								</div>
							)}
							{isError && (
								<div className={styles['password-reset-email-error']}>
									{t('requestType.error')}
								</div>
							)}

							<h3 className={styles['group-header-center']}>
								{t('requestType.createRequestType')}
							</h3>
							<div className={styles['login-input-field']}>
								<FormControl className={styles['login-form']} fullWidth>
									<TextField
										id="groupNameEn"
										name="groupNameEn"
										label={t('requestType.title')}
										variant="outlined"
										size="small"
										helperText={
											array.length === 0
												? `${t('requestType.requestTypeHelperText')}`
												: `${t('requestType.requestTypeHelperText1')}`
										}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={array.join(', ')}
										error={
											formik.touched.groupNameEn &&
											Boolean(formik.errors.groupNameEn)
										}
										type="text"
										readOnly
									/>
									<span className={styles['error']}>
										{formik.touched.groupNameEn && formik.errors.groupNameEn ? (
											<div>{formik.errors.groupNameEn}</div>
										) : null}
									</span>
									<br />
								</FormControl>
								{/* <input type="text" value={array.join(', ')} readOnly /> */}
								<Stack
									direction="column"
									spacing={1}
									sx={{
										marginBottom: '15px',
										alignItems: 'center',
										justifyContent: 'center'
									}}
								>
									<Stack direction="row" spacing={2}>
										<Button
											variant="outlined"
											startIcon={<AddIcon />}
											onClick={incrementValueForErase}
										>
											{t('requestType.erase')}
										</Button>
										<Button
											variant="outlined"
											startIcon={<AddIcon />}
											onClick={incrementValueForPurge}
										>
											{t('requestType.purge')}
										</Button>
									</Stack>
									<Stack direction="row" spacing={2}>
										<Button
											variant="outlined"
											startIcon={<RemoveIcon />}
											onClick={decrementValueForErase}
										>
											{t('requestType.erase')}
										</Button>
										<Button
											variant="outlined"
											startIcon={<RemoveIcon />}
											onClick={decrementValueForPurge}
										>
											{t('requestType.purge')}
										</Button>
									</Stack>
								</Stack>
							</div>
							<Stack justifyContent="center">
								<Button
									type="Submit"
									variant="contained"
									onClick={formik.handleSubmit}
									disabled={array.length === 0 ? true : false}
								>
									{t('requestType.create')}
								</Button>
							</Stack>
						</>
					);
				}}
			</Formik>
		</>
	);
};

export default CreateRequest;
