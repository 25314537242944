import { Button, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { Formik } from 'formik';
import { useState } from 'react';
import styles from './SelectOrganization.module.css';
import { useEffect } from 'react';
import ShowOrganizationCollaborationApi from '../../HandoverApi/ShowOrganizationCollaborationApi';
import SendOrganizationHandoverByAmin from '../../HandoverApi/SendOrganizationHandoverByAmin';
import { useTranslation } from 'react-i18next';

const SelectOrganization = (props) => {
	const { t } = useTranslation();
	const [isValid, setIsValid] = useState(false);
	const [isEmailSended, setIsEmailSended] = useState(false);
	const [isError, setIsError] = useState(false);
	const [groupData, setGroupData] = useState([]);
	const [permissionData, setPermissionData] = useState([]);
	const [selectedGroupId, setSelectedGroupId] = useState('');
	const [selectedGroupName, setSelectedGroupName] = useState([]);

	const initialValues = {
		groupName: ''
	};

	const validate = (values) => {
		setIsValid(true);
		let errors = {};
		if (!values.groupName) {
			errors.groupName = `${t('sendHandover.organizationNameRequired')}`;
			setIsValid(false);
		}

		return errors;
	};

	const onSubmit = async (values, { resetForm }) => {
		console.log('value is ', values);
		console.log('props is ', props);
		const response = await SendOrganizationHandoverByAmin(values, props);
		console.log('handover send', response);

		if (response.success === true) {
			setIsEmailSended(true);
			setIsError(false);
			resetForm();
			setTimeout(() => window.location.reload(), 500);
		} else {
			setIsError(true);
			setIsEmailSended(false);
		}
	};

	useEffect(() => {
		const FetchGroupData = async () => {
			const allData = await ShowOrganizationCollaborationApi();
			console.log(allData.body);
			if (allData.success === true) {
				setGroupData(await allData.body);
				console.log('data aise ');
			}
		};
		FetchGroupData();
	}, []);

	//   useEffect( () =>{
	//     const FetchPermissionData = async() =>{
	//       const allData = await FetchAllPermissionApi();
	//       console.log(allData.body);
	//        if(allData.success === true)
	//        {
	//         setPermissionData(await allData.body);
	//         console.log("data aise ")

	//        }

	//     }
	//     FetchPermissionData();
	//   },[])

	return (
		<Formik
			initialValues={initialValues}
			onSubmit={onSubmit}
			validate={validate}
		>
			{(formik) => {
				return (
					<>
						{isEmailSended && (
							<div className={styles['password-reset-email-success']}>
								{t('sendHandover.success')}
							</div>
						)}
						{isError && (
							<div className={styles['password-reset-email-error']}>
								{t('sendHandover.error')}
							</div>
						)}

						<h3 className={styles['group-header-center']}>
							{' '}
							{t('sendHandover.title')}
						</h3>

						<div className={styles['login-input-field']}>
							<FormControl required className={styles['login-form']} fullWidth>
								<InputLabel id="organization-initial-label">
									{t('sendHandover.organizationName')}
								</InputLabel>
								{formik.values.groupName
									? setSelectedGroupId(formik.values.groupName)
									: null}
								{groupData.map((i) =>
									selectedGroupId === i._id
										? setSelectedGroupName(i.name_en)
										: null
								)}
								<Select
									labelId="demo-simple-select-label"
									id="groupName"
									name="groupName"
									value={formik.values.groupName}
									label="Organization Name"
									size="small"
									onChange={formik.handleChange}
									onBlur={formik.handleBlur}
									error={
										formik.touched.groupName && Boolean(formik.errors.groupName)
									}
								>
									{groupData &&
										groupData.map((group) => (
											<MenuItem key={group._id} value={group._id}>
												{group.name_en}
											</MenuItem>
										))}
								</Select>
								<span className={styles['error']}>
									{formik.touched.groupName && formik.errors.groupName ? (
										<div>{formik.errors.groupName}</div>
									) : null}
								</span>

								<br />
							</FormControl>
						</div>
						<Stack justifyContent="center">
							<Button
								type="Submit"
								variant="contained"
								onClick={formik.handleSubmit}
							>
								{t('sendHandover.send')}
							</Button>
						</Stack>
					</>
				);
			}}
		</Formik>
	);
};

export default SelectOrganization;
