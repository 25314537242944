const CreateTrackApiHandler = async (values, projectId, unoccupiedTags) => {
	const accessToken = localStorage.getItem('access_token');
	console.log(accessToken);
	console.log(projectId);
	console.log(unoccupiedTags);
	//alert(JSON.stringify(values, null, 2));
	// e.preventDefault()
	const res = await fetch(
		`${process.env.REACT_APP_BASE_URL + 'tracks/createTrack'}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				project_id: projectId,
				track_tag: unoccupiedTags,
				request_type: values.requestType
			})
		}
	);

	const data = await res.json();
	console.log(data);
	return data;
};

export default CreateTrackApiHandler;
