import React from 'react';
import Button from '@mui/material/Button';
import downloadSummary from '../../../Icons/downloadSummary.svg';
import Papa from 'papaparse';
import { useTranslation } from 'react-i18next';

const CsvFileDonwload = ({ trackId }) => {
	const { t } = useTranslation();
	const handleCSV = async () => {
		// Make a GET request to the API
		const accessToken = localStorage.getItem('access_token');
		const response = await fetch(
			`${
				process.env.REACT_APP_BASE_URL +
				'devices/showAllActiveDevicesOfFollowingTrack'
			}`,
			{
				method: 'POST',
				body: JSON.stringify({
					track_id: trackId
				}),
				headers: {
					'Content-Type': 'application/json',
					Accept: 'application/json',
					Authorization: `Bearer ${accessToken}`
				}
			}
		);
		if (!response) {
			return;
		}
		const data = await response.json();
		if (trackId !== null || trackId !== '' || typeof trackId !== 'undefined') {
			// Convert the data into an array of objects
			const rows = data?.body?.map((item) => {
				return {
					//   TrackID: item.device_tag.substring(0, item.device_tag.indexOf(".")),
					TrackID: item.track._id,
					DeviceType: item.device_model ? item.device_model.device_type : null,
					manufacturer: item.device_model
						? item.device_model.manufacturer
						: null,
					DeviceModel: item.device_model ? item.device_model.model : null,
					DeviceSerial: item.serial,
					spacifications: item.device_model
						? item.device_model.spacifications
						: null
				};
			});
			// Convert the array of objects into a CSV string
			const csv = Papa.unparse(rows);
			// Create a URL that points to the CSV string
			const url = window.URL.createObjectURL(new Blob([csv]));
			// Create a link that allows the user to download the CSV file
			const link = document.createElement('a');
			link.href = url;
			link.download = `${trackId}_TRACKID_LIST_${Date.now()}.csv`;
			link.click();
		} else {
			return;
		}
	};
	return (
		<>
			<Button
				variant="outlined"
				startIcon={
					<img
						src={downloadSummary}
						alt="downloadSummary"
						width="16"
						height="16"
					/>
				}
				sx={{ borderRadius: '5px', color: '#415A77 ' }}
				onClick={handleCSV}
			>
				{t('button.csvFile')}
			</Button>
		</>
	);
};

export default CsvFileDonwload;
