import { useState, useEffect } from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { Box, Chip, styled } from '@mui/material';
import LinearProgress, {
	linearProgressClasses
} from '@mui/material/LinearProgress';
import TableWithFilter from './TableWithFilter';
import { useTranslation } from 'react-i18next';
// import dummy_data from './dummy_data2.json';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 10,
	borderRadius: 5,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor:
			theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: theme.palette.mode === 'light' ? '#718399' : '#415A77'
	}
}));

const UpcomingDeadlineTable = () => {
	const { t } = useTranslation();
	const [data, setData] = useState([]);

	useEffect(() => {
		const fetchData = async () => {
			const response = await fetch(
				`${
					process.env.REACT_APP_BASE_URL +
					'projects/showUpcomingDeadlineProjectsDetailsOfMyOrganization'
				}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${localStorage.getItem('access_token')}`
					}
				}
			);
			const result = await response.json();
			setData(result.body);
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const columnHelper = createColumnHelper();

	const columns = [
		columnHelper.accessor('deadline', {
			header: `${t('upcomingDeadlines.deadline')}`,
			cell: (info) => {
				const deadline = new Date(info.row.original.deadline);
				const day = deadline.getDate();
				const month = deadline.toLocaleString('default', { month: 'short' });
				const year = deadline.getFullYear();
				return `${day} ${month} ${year}`;
			},
			footer: (info) => info.column.id
		}),
		columnHelper.accessor('remaining', {
			header: `${t('upcomingDeadlines.remainingTime')}`,
			cell: (info) => {
				const deadline = new Date(info.row.original.deadline);
				const remaining = deadline.getTime() - Date.now();
				const days = Math.floor(remaining / (1000 * 60 * 60 * 24));
				const hours = Math.floor(
					(remaining % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
				);
				return `${days} days ${hours} hrs`;
			}
		}),
		columnHelper.accessor('name_en', {
			header: `${t('upcomingDeadlines.project')}`,
			footer: (info) => info.column.id
		}),
		columnHelper.accessor('group.name_en', {
			header: `${t('upcomingDeadlines.group')}`,
			footer: (info) => info.column.id
		}),
		columnHelper.accessor('progress', {
			header: `${t('upcomingDeadlines.progress')}`,
			cell: (info) => {
				const { total, completed } = info.row.original.track_summary;
				const progress = (completed / total) * 100 || 0;
				return (
					<Box sx={{ width: '100%' }}>
						<BorderLinearProgress variant="determinate" value={progress} />
					</Box>
				);
			}
		}),
		columnHelper.accessor('status', {
			header: `${t('upcomingDeadlines.status')}`,
			cell: (info) => {
				const status = info.row.original.status;
				return (
					<Chip
						label={status}
						size="small"
						sx={{
							height: '18px',
							width: '72px',
							backgroundColor:
								status === 'ready'
									? '#eceff2'
									: status === 'in progress'
									? '#d0fae5 '
									: status === 'completed'
									? '#718399'
									: '#fdedf3 ',
							fontSize: '10px',
							color:
								status === 'completed'
									? '#ffffff'
									: status === 'failed'
									? '#e74b83'
									: '#415a77'
						}}
					/>
				);
			},
			footer: (info) => info.column.id
		})
	];

	return (
		<>
			{data.length > 0 && (
				<TableWithFilter columns={columns} table_data={data} />
			)}
		</>
	);
};

export default UpcomingDeadlineTable;
