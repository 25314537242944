import * as React from 'react';
import { styled } from '@mui/material/styles';
import styles from './ProgressBar.module.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress, {
	linearProgressClasses
} from '@mui/material/LinearProgress';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 20,
	borderRadius: 10,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor:
			theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: theme.palette.mode === 'light' ? '#415A77' : '#308fe8'
	}
}));

const ProgressBar = () => {
	return (
		<>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Box
					sx={{
						height: 1,
						width: 300,
						marginTop: 0,
						marginBottom: 0,
						padding: 0
					}}
				>
					<BorderLinearProgress variant="determinate" value={80} />
					<p className={styles['progress-bar-text']}>
						Records Completed{' '}
						<span style={{ fontWeight: 'bold' }}>289/300</span>
					</p>
				</Box>
				<Box sx={{ minWidth: 35 }}>
					<Typography
						variant="body2"
						color="text.secondary"
						sx={{ textAlign: 'right' }}
					>
						80%
					</Typography>
				</Box>
			</Box>
		</>
	);
};

export default ProgressBar;
