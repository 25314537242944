// import CloseIcon from "@mui/icons-material/Close";
import { Button, Stack } from '@mui/material';
import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import Modal from '@mui/material/Modal';
import TextField from '@mui/material/TextField';
import FormGroup from '@mui/material/FormGroup';
import { Formik, Field } from 'formik';
// import { useRouter } from "next/router";
import { useState, useEffect, useContext } from 'react';
import styles from './ReportForm.module.css';
import CloseIcon from '@mui/icons-material/Close';
// import { ToastContainer, toast } from 'react-toastify';
// import { TokenContext } from "../../CommonComponents/Contexts/TokenContext";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import { maxWidth } from '@mui/system';
import CreateReportApi from '../../RecordApi/CreateReportApi';
import { useTranslation } from 'react-i18next';

const style = {
	position: 'absolute',
	top: '50%',
	left: '50%',
	transform: 'translate(-50%, -50%)',
	width: 400,
	bgcolor: 'background.paper',
	border: '2px solid #000',
	boxShadow: 24,
	p: 4
};

const ReportForm = ({ clickRecordId, deviceTag }) => {
	// const {tokenValue} = useContext(TokenContext);
	// console.log("group", tokenValue)
	//   const { locale } = useRouter();
	const { t } = useTranslation();
	const [open, setOpen] = useState(false);
	const [isValid, setIsValid] = useState(false);
	const handleOpen = () => setOpen(true);
	const [isProjectCreate, setIsProjectCreate] = useState(false);
	const [isError, setIsError] = useState(false);
	const [status, setStatus] = useState(false);
	const [groupData, setGroupData] = useState([]);
	const [allGroupData, setAllGroupData] = useState([]);
	const [allProjectData, setAllProjectData] = useState([]);
	const [requestData, setRequestData] = useState([]);
	const [unoccupiedTags, setUnoccupiedTags] = useState([]);
	const [trackTags, setTrackTags] = useState(unoccupiedTags);
	const handleClose = () => {
		setIsProjectCreate(false);
		setIsError(false);
		setOpen(false);
		//formik form reset
		// Formik.resetForm();
	};
	console.log('aita e data', unoccupiedTags);
	//   unoccupiedTags.map((row)=>
	//          console.log(row._id)
	//   )

	const initialValues = {
		reportId: '',
		checked: [],
		reportDetails: ''
	};

	const validate = (values) => {
		setIsValid(true);
		let errors = {};
		if (values.checked.length === 0) {
			errors.checked = `${t('createReport.reportReasonRequired')}`;
			setIsValid(false);
		}
		if (!values.reportDetails) {
			errors.reportDetails = `${t('createReport.reportDetailsRequired')}`;
			setIsValid(false);
		}

		return errors;
	};

	// const loginFormSubmit = (e) => {
	//   e.preventDefault()
	//   router.push('/home')
	// }

	//   useEffect( () =>{
	//     const FetchOrganizationData = async() =>{
	//       const allData = await FetchAllGroupApiDataHandler();
	//       console.log(allData.body);
	//        if(allData.success === true)
	//        {
	//         setAllGroupData(await allData.body);
	//         console.log("data aise ")

	//        }

	//     }
	//     FetchOrganizationData();
	//   },[])

	const onSubmit = async (values, { resetForm }) => {
		console.log('report value for', values);
		console.log('record id value', clickRecordId);
		setStatus(true);
		const res = await CreateReportApi(values, clickRecordId);
		console.log('create record', res);
		if (res.success === true) {
			setIsProjectCreate(true);
			setIsError(false);
			resetForm();
		} else {
			setIsError(true);
			setIsProjectCreate(false);
		}
	};

	return (
		<>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validate={validate}
			>
				{(formik) => {
					return (
						<>
							{/* <Modal
              open={open}
              onClose={handleClose}
              aria-labelledby="modal-modal-title"
              aria-describedby="modal-modal-description"
            > */}
							{/* <Box sx={style}>
                <div className={styles.closeButton}>
                  <Button onClick={handleClose}>
                    <CloseIcon />
                  </Button>
                </div> */}
							{isProjectCreate && (
								<div className={styles['password-reset-email-success']}>
									{t('createReport.success')}
								</div>
							)}
							{isError && (
								<div className={styles['password-reset-email-error']}>
									{t('createReport.error')}
								</div>
							)}

							{/*
                <p id="modal-modal-title" variant="h6" component="h2">
                 { ETTMSText[locale].welcomePage.forgetPassword.forgetPasswordHeader}
                </p> */}

							<h3 className={styles['group-header-center']}>
								{t('createReport.title')}
							</h3>
							<div className={styles['login-input-field']}>
								<FormControl className={styles['login-form']} fullWidth>
									<TextField
										id="reportId"
										name="reportId"
										label={t('createReport.reportId')}
										variant="outlined"
										size="small"
										helperText={t('createReport.reportIdHelperText')}
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={deviceTag}
										defaultValue="0"
										InputProps={{
											readOnly: true
										}}
										error={
											formik.touched.reportId && Boolean(formik.errors.reportId)
										}
									/>
									<span className={styles['error']}>
										{formik.touched.reportId && formik.errors.reportId ? (
											<div>{formik.errors.reportId}</div>
										) : null}
									</span>
									<br />
								</FormControl>

								<FormControl className={styles['login-form']} fullWidth>
									<FormGroup>
										<div id="checkbox-group">
											{t('createReport.reportReason')}:
										</div>
										<div
											className={styles['checkbox-align']}
											role="group"
											aria-labelledby="checkbox-group"
										>
											<div>
												<label>
													<Field
														type="checkbox"
														name="checked"
														value={'device'}
													/>
													Device
												</label>
											</div>
											<div>
												<label>
													<Field
														type="checkbox"
														name="checked"
														value={'software'}
													/>
													Software
												</label>
											</div>
										</div>
										<span className={styles['error']}>
											{formik.touched.checked && formik.errors.checked ? (
												<div>{formik.errors.checked}</div>
											) : null}
										</span>

										<br />
									</FormGroup>
								</FormControl>

								<FormControl className={styles['login-form']} fullWidth>
									<div className="control">
										<TextareaAutosize
											id="reportDetails"
											name="reportDetails"
											className="input"
											onChange={formik.handleChange}
											onBlur={formik.handleBlur}
											value={formik.values.reportDetails}
											placeholder={t('createReport.reportDetails')}
											style={{
												width: '99%',
												maxWidth: '100%',
												height: 50,
												maxHeight: 100,
												fontSize: '15px',
												fontWeight: 'none',
												border: '1px solid gray',
												textIndent: '10px'
											}}
											error={
												formik.touched.reportDetails &&
												Boolean(formik.errors.reportDetails)
											}
										/>
										<span className={styles['error']}>
											{formik.touched.reportDetails &&
											formik.errors.reportDetails ? (
												<div>{formik.errors.reportDetails}</div>
											) : null}
										</span>
									</div>

									<br />
								</FormControl>

								{/* <Stack justifyContent="center">
                  <Button
                    type="Submit"
                    variant="contained"
                    className={styles["login-in-btn"]}
                    onClick={formik.handleSubmit}
                  >
                   Create
                  </Button>
                </Stack> */}
							</div>
							<Stack justifyContent="center">
								<Button
									type="Submit"
									variant="contained"
									// className={styles["login-in-btn"]}
									onClick={formik.handleSubmit}
								>
									{t('createReport.submit')}
								</Button>
							</Stack>
							{/* </Box>
            </Modal> */}
							{/* <div className={styles["organization-button-control"]}>
                <Button variant="contained"  onClick={handleOpen} >Create Organization</Button>
            </div> */}
							{/* <Button
              onClick={handleOpen}
              className={styles["login-forget-password"]}
            >
              ki khobor
            </Button> */}
						</>
					);
				}}
			</Formik>
		</>
	);
};

export default ReportForm;
