import React, { useState, useEffect } from 'react';

import styles from './AcceptedHandoverComponents.module.css';
import { useNavigate } from 'react-router-dom';
import HandoverInformation from './HandoverInformation';

const AcceptedHandover = () => {
	const navigate = useNavigate();
	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			navigate('/');
		}
	}, [navigate]);
	return (
		<>
			<div className={styles[`adminDashboard-wrapper`]}>
				<div>{/* <TrackBoardTable/> */}</div>
				<div className={styles['adminDashboardTable-wraper']}>
					<HandoverInformation />
				</div>
			</div>
		</>
	);
};

export default AcceptedHandover;
