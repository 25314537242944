const OrganizationSendMailApiHandler = async (values, organization) => {
	const accessToken = localStorage.getItem('access_token');
	console.log('values', values);
	console.log('organization', organization);
	console.log('organization id', organization._id);

	const res = await fetch(
		`${process.env.REACT_APP_BASE_URL + 'users/sendAdminRegistrationEmail'}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				organization_id: organization._id,
				organization_name_en: organization.name_en,
				organization_name_jp: organization.name_jp,
				email: values.email,
				permission_id: values.assignPermission
			})
		}
	);

	const data = await res.json();
	console.log(data);

	return data;
};

export default OrganizationSendMailApiHandler;
