import { createSlice } from '@reduxjs/toolkit';
import ShowAllActiveProjectOfFollowingGroup from '../Components/ProjectComponents/ProjectTable/ShowAllActiveProjectOfFollowingGroup';

const initialState = {
	data: []
};
const projectSlice = createSlice({
	name: 'projects',
	initialState,
	reducers: {
		fetchProjects(state, action) {
			state.data = action.payload;
		}
	}
});

export const { fetchProjects } = projectSlice.actions;
export default projectSlice.reducer;

export function getProjects() {
	return async function getProjectThunk(dispatch, getState) {
		const data = await ShowAllActiveProjectOfFollowingGroup(
			'6492c3d1b99a90a9db60a4a7'
		);
		dispatch(fetchProjects(data.body));
	};
}
