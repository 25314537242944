import { Button, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Formik, Field } from 'formik';
import { useState } from 'react';
import FormGroup from '@mui/material/FormGroup';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import styles from './CreateTool.module.css';
import CreateToolApiHandler from './CreateToolApi/CreateToolApiHandler';
import { useTranslation } from 'react-i18next';

const CreateTool = () => {
	const { t } = useTranslation();
	const [isValid, setIsValid] = useState(false);
	const [isGroupCreate, setIsGroupCreate] = useState(false);
	const [isError, setIsError] = useState(false);
	const [status, setStatus] = useState(false);

	const initialValues = {
		groupNameEn: '',
		groupNameJp: '',
		checked: []
	};

	const validate = (values) => {
		setIsValid(true);
		let errors = {};

		if (!values.groupNameEn) {
			errors.groupNameEn = `${t('toolForm.nameEnglishRequired')}`;
			setIsValid(false);
		}
		if (!values.groupNameJp) {
			errors.groupNameJp = `${t('toolForm.nameJapaneseRequired')}`;
			setIsValid(false);
		}
		if (values.checked.length === 0) {
			errors.checked = `${t('toolForm.processingTypeRequired')}`;
			setIsValid(false);
		}

		return errors;
	};

	const onSubmit = async (values, { resetForm }) => {
		console.log(values);
		setStatus(true);
		const res = await CreateToolApiHandler(values);
		console.log(res);
		if (res.success === true) {
			setIsGroupCreate(true);
			setIsError(false);
			resetForm();
		} else {
			setIsError(true);
			setIsGroupCreate(false);
		}
	};

	return (
		<>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validate={validate}
			>
				{(formik) => {
					return (
						<>
							{isGroupCreate && (
								<div className={styles['password-reset-email-success']}>
									{t('toolForm.success')}
								</div>
							)}
							{isError && (
								<div className={styles['password-reset-email-error']}>
									{t('toolForm.error')}
								</div>
							)}

							<h3 className={styles['group-header-center']}>
								{t('toolForm.title')}
							</h3>
							<div className={styles['login-input-field']}>
								<FormControl className={styles['login-form']} fullWidth>
									<TextField
										id="groupNameEn"
										name="groupNameEn"
										label={t('toolForm.nameEnglish')}
										variant="outlined"
										size="small"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.groupNameEn}
										error={
											formik.touched.groupNameEn &&
											Boolean(formik.errors.groupNameEn)
										}
										type="text"
									/>
									<span className={styles['error']}>
										{formik.touched.groupNameEn && formik.errors.groupNameEn ? (
											<div>{formik.errors.groupNameEn}</div>
										) : null}
									</span>
									<br />
								</FormControl>
								<FormControl className={styles['login-form']} fullWidth>
									<TextField
										id="groupNameJp"
										name="groupNameJp"
										label={t('toolForm.nameJapanese')}
										variant="outlined"
										size="small"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.groupNameJp}
										error={
											formik.touched.groupNameJp &&
											Boolean(formik.errors.groupNameJp)
										}
										type="text"
									/>
									<span className={styles['error']}>
										{formik.touched.groupNameJp && formik.errors.groupNameJp ? (
											<div>{formik.errors.groupNameJp}</div>
										) : null}
									</span>
									<br />
								</FormControl>

								<FormControl className={styles['login-form']} fullWidth>
									<FormGroup>
										<div id="checkbox-group">
											{t('toolForm.processingType')}:
										</div>
										<div
											className={styles['checkbox-align']}
											role="group"
											aria-labelledby="checkbox-group"
										>
											<div>
												<label>
													<Field type="checkbox" name="checked" value="erase" />
													Erase
												</label>
											</div>
											<div>
												<label>
													<Field type="checkbox" name="checked" value="purge" />
													Purge
												</label>
											</div>
										</div>
										<span className={styles['error']}>
											{formik.touched.checked && formik.errors.checked ? (
												<div>{formik.errors.checked}</div>
											) : null}
										</span>

										<br />
									</FormGroup>
								</FormControl>
							</div>
							<Stack justifyContent="center">
								<Button
									type="Submit"
									variant="contained"
									onClick={formik.handleSubmit}
								>
									{t('toolForm.create')}
								</Button>
							</Stack>
						</>
					);
				}}
			</Formik>
		</>
	);
};

export default CreateTool;
