import { useState, useEffect } from 'react';
import {
	Paper,
	Table as MuiTable,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TableContainer,
	Box,
	FormControl,
	Select,
	MenuItem,
	Typography,
	OutlinedInput,
	IconButton
} from '@mui/material';
import {
	ArrowBackIosNewRounded,
	ArrowDownward,
	ArrowForwardIosRounded,
	ArrowUpwardRounded,
	FirstPageRounded,
	LastPageRounded,
	Search
} from '@mui/icons-material';
import {
	useReactTable,
	getCoreRowModel,
	getFilteredRowModel,
	getFacetedRowModel,
	getFacetedUniqueValues,
	getFacetedMinMaxValues,
	getPaginationRowModel,
	getSortedRowModel,
	flexRender
} from '@tanstack/react-table';
import { rankItem } from '@tanstack/match-sorter-utils';
import { useTranslation } from 'react-i18next';

const fuzzyFilter = (row, columnId, value, addMeta) => {
	const itemRank = rankItem(row.getValue(columnId), value);
	addMeta({
		itemRank
	});

	return itemRank.passed;
};

// A debounced input react component
function DebouncedInput({
	value: initialValue,
	onChange,
	debounce = 500,
	...props
}) {
	const [value, setValue] = useState(initialValue);

	useEffect(() => {
		setValue(initialValue);
	}, [initialValue]);

	useEffect(() => {
		const timeout = setTimeout(() => {
			onChange(value);
		}, debounce);

		return () => clearTimeout(timeout);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [value]);

	return (
		<OutlinedInput
			id="search"
			placeholder="Search"
			{...props}
			value={value}
			onChange={(e) => setValue(e.target.value)}
			endAdornment={
				<IconButton aria-label="search" edge="end" onClick={() => {}}>
					<Search fontSize="small" sx={{ color: '#718399' }} />
				</IconButton>
			}
			sx={{
				height: '30px',
				fontSize: '12px',
				'& .MuiOutlinedInput-notchedOutline': {
					borderColor: '#dee4ed9b',
					borderRadius: '10px'
				},
				'&:hover .MuiOutlinedInput-notchedOutline': {
					borderColor: '#718399'
				},
				'&.Mui-focused .MuiOutlinedInput-notchedOutline': {
					borderColor: '#718399'
				}
			}}
		/>
	);
}

const TableWithFilter = ({ columns, table_data }) => {
	const { t } = useTranslation();
	const [columnFilters, setColumnFilters] = useState([]);
	const [globalFilter, setGlobalFilter] = useState('');

	const [data] = useState(table_data);

	const table = useReactTable({
		data,
		columns,
		filterFns: {
			fuzzy: fuzzyFilter
		},
		state: {
			columnFilters,
			globalFilter
		},
		onColumnFiltersChange: setColumnFilters,
		onGlobalFilterChange: setGlobalFilter,
		globalFilterFn: fuzzyFilter,
		getCoreRowModel: getCoreRowModel(),
		getFilteredRowModel: getFilteredRowModel(),
		getSortedRowModel: getSortedRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		getFacetedRowModel: getFacetedRowModel(),
		getFacetedUniqueValues: getFacetedUniqueValues(),
		getFacetedMinMaxValues: getFacetedMinMaxValues(),
		debugTable: true,
		debugHeaders: true,
		debugColumns: false
	});

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				padding: '1rem',
				borderRadius: '12px',
				border: '1px solid #dee4ed9b'
			}}
		>
			<Box
				sx={{
					display: 'flex',
					flexDirection: 'row',
					justifyContent: 'space-between',
					alignItems: 'center'
				}}
				mb={1}
			>
				<Typography
					variant="h3"
					p={0.5}
					color="#415a77"
					fontWeight="semibold"
					fontSize={16}
				>
					{t('upcomingDeadlines.title')}
				</Typography>
				{/* search input */}
				<FormControl sx={{ width: 400 }}>
					{/* <OutlinedInput
						id="search"
						placeholder="Search"
						endAdornment={
							<IconButton
								aria-label="search"
								edge="end"
								onClick={() => {}}
							>
								<Search />
							</IconButton>
						}
						sx={{
							height: '30px',
							fontSize: '12px'
						}}
					/> */}
					<DebouncedInput
						value={globalFilter ?? ''}
						onChange={(value) => setGlobalFilter(String(value))}
						placeholder={t('dashboard.search')}
					/>
				</FormControl>
			</Box>
			<TableContainer
				component={Paper}
				sx={{
					borderRadius: '12px',
					border: '1px solid #dee4ed9b',
					boxShadow: 'none'
				}}
			>
				<MuiTable>
					<TableHead
						sx={{
							backgroundColor: '#f6f8fa'
						}}
					>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<TableCell
											key={header.id}
											colSpan={header.colSpan}
											sx={{
												padding: '8px 12px',
												fontWeight: 'semibold',
												fontSize: '12px',
												color: '#415A77'
											}}
										>
											{header.isPlaceholder ? null : (
												<>
													<Box
														{...{
															className: header.column.getCanSort()
																? 'cursor-pointer select-none'
																: '',
															onClick: header.column.getToggleSortingHandler()
														}}
														display="flex"
														alignItems="center"
														gap={0.5}
													>
														{flexRender(
															header.column.columnDef.header,
															header.getContext()
														)}
														{{
															asc: (
																<ArrowUpwardRounded
																	sx={{
																		fontSize: '1rem',
																		color: '#8a4fff'
																	}}
																/>
															),
															desc: (
																<ArrowDownward
																	sx={{
																		fontSize: '1rem',
																		color: '#8a4fff'
																	}}
																/>
															)
														}[header.column.getIsSorted()] ?? null}
													</Box>
												</>
											)}
										</TableCell>
									);
								})}
							</TableRow>
						))}
					</TableHead>
					<TableBody>
						{table.getRowModel().rows.map((row) => {
							return (
								<TableRow key={row.id}>
									{row.getVisibleCells().map((cell) => {
										return (
											<TableCell
												key={cell.id}
												sx={{
													padding: '8px 12px',
													fontSize: '12px',
													color: '#415A77'
												}}
											>
												{flexRender(
													cell.column.columnDef.cell,
													cell.getContext()
												)}
											</TableCell>
										);
									})}
								</TableRow>
							);
						})}
					</TableBody>
				</MuiTable>
			</TableContainer>
			<Box display="flex" flexDirection="row" justifyContent="flex-end">
				<FormControl sx={{ m: 1, width: 600 }}>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'flex-end',
							gap: '1rem'
						}}
					>
						<Box display="flex" gap={0.5} alignItems="center">
							<Typography fontSize={12}>Rows per page:</Typography>
							<FormControl>
								<Select
									value={table.getState().pagination.pageSize}
									onChange={(e) => {
										table.setPageSize(Number(e.target.value));
									}}
									input={
										<OutlinedInput
											sx={{
												width: '64px',
												height: '30px',
												fontSize: '12px'
											}}
										/>
									}
								>
									{[10, 20, 30, 40, 50].map((pageSize) => (
										<MenuItem
											key={pageSize}
											value={pageSize}
											sx={{
												fontSize: '10px'
											}}
										>
											{pageSize}
										</MenuItem>
									))}
								</Select>
							</FormControl>
						</Box>
						<Box display="flex" gap={0.5} alignItems="center">
							<Typography fontSize={12}>Page</Typography>
							<Typography variant="subtitle2" fontSize={12} fontWeight="bold">
								{table.getState().pagination.pageIndex + 1} of{' '}
								{table.getPageCount()}
							</Typography>
						</Box>
						<Box display="flex" gap={0.5} alignItems="center">
							<Typography fontSize={12}>Go to page:</Typography>
							<OutlinedInput
								sx={{
									width: '64px',
									height: '30px',
									fontSize: '12px'
								}}
								type="number"
								defaultValue={table.getState().pagination.pageIndex + 1}
								onChange={(e) => {
									const page = e.target.value ? Number(e.target.value) - 1 : 0;
									table.setPageIndex(page);
								}}
							/>
						</Box>
						<Box display="flex">
							<IconButton
								aria-label="first page"
								onClick={() => table.setPageIndex(0)}
								disabled={!table.getCanPreviousPage()}
							>
								<FirstPageRounded
									sx={{
										fontSize: '20px'
									}}
								/>
							</IconButton>
							<IconButton
								aria-label="back"
								onClick={() => table.previousPage()}
								disabled={!table.getCanPreviousPage()}
							>
								<ArrowBackIosNewRounded
									sx={{
										fontSize: '14px'
									}}
								/>
							</IconButton>
							<IconButton
								aria-label="back"
								onClick={() => table.nextPage()}
								disabled={!table.getCanNextPage()}
							>
								<ArrowForwardIosRounded
									sx={{
										fontSize: '14px'
									}}
								/>
							</IconButton>
							<IconButton
								aria-label="last page"
								onClick={() => table.setPageIndex(table.getPageCount() - 1)}
								disabled={!table.getCanNextPage()}
							>
								<LastPageRounded
									sx={{
										fontSize: '20px'
									}}
								/>
							</IconButton>
						</Box>
					</Box>
				</FormControl>
			</Box>
		</Box>
	);
};

export default TableWithFilter;
