import './App.css';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './Components/WelcomeComponents/HomePanel';
import AdminDashboard from './Components/DashboardComponents/AdminDashboard';
import SuperAdminDashboard from './Components/DashboardComponents/SuperAdminDashboard';
import Registration from './Components/WelcomeComponents/RegistrationPanel';
import TrackBoard from './Components/TrackBoardComponents';
import RecordTableView from './Components/TrackBoardComponents/TrackBoradTable/DeviceRecordComponents/RecordTableView';
import Handover from './Components/HandoverComponents';
import UserDashboardBasedOnGroup from './Components/DashboardComponents/UserDashboardBasedOnGroup';
import QRCodeReader from './Components/QRCodeComponents/QRCodeReader';
import SingleDeviceInfoShow from './Components/TrackBoardComponents/TrackBoradTable/DeviceComponents/SingleDeviceInfoShow';
import UserProfile from './Components/ProfileComponents/UserProfile';
import CollaborationAfterEmail from './Components/HandoverComponents/Collaboration/CollaborationAfterEmail';
import DeviceBoard from './Components/DeviceBoardComponents';
import RecordBoard from './Components/RecordBoardComponents';
import Layout from './Components/Layout';
import Login from './Components/WelcomeComponents/Login';
import ManageBoard from './Components/DashboardComponents/ManageBoard';
import AcceptedHandover from './Components/AcceptedHandoverComponents';

function App() {
	return (
		<>
			<Router>
				<Routes>
					<Route path="/" element={<Login />} />
					<Route path="/registration/:token" element={<Registration />} />
					<Route element={<Layout />}>
						<Route path="/home" element={<Home />} />
						{/* <Route path="/dashboard" element={<Dashboard />} /> */}
						<Route path="/manage" element={<ManageBoard />} />
						<Route path="/adminDashboard" element={<AdminDashboard />} />
						<Route
							path="/superAdminDashboard"
							element={<SuperAdminDashboard />}
						/>
						<Route
							path="/userDashboard"
							element={<UserDashboardBasedOnGroup />}
						/>
						<Route path="/trackBoard" element={<TrackBoard />} />
						<Route path="/deviceBoard" element={<DeviceBoard />} />
						<Route path="/recordBoard" element={<RecordBoard />} />
						<Route path="/viewTrackRecord" element={<RecordTableView />} />
						<Route
							path="/singleDeviceInfoShow"
							element={<SingleDeviceInfoShow />}
						/>
						<Route path="/handover" element={<Handover />} />
						<Route path="/acceptedHandover" element={<AcceptedHandover />} />
						<Route path="/qrCodeReader" element={<QRCodeReader />} />
						<Route path="/profile" element={<UserProfile />} />
						<Route
							path="/organization_collaboration/:id"
							element={<CollaborationAfterEmail />}
						/>
						{/* <Route path="/TrackDetails" element = { <TrackDetailsBeforeHandover/> } /> */}
					</Route>
				</Routes>
			</Router>
		</>
	);
}

export default App;
