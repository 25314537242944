import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
// import { Project } from '../Model/Project.model'
export const ProjectApi = createApi({
	reducerPath: 'ProjectsApi',
	baseQuery: fetchBaseQuery({ baseUrl: 'http://192.168.87.45:5001/' }),
	tagTypes: ['Project'],
	endpoints: (build) => ({
		getProjects: build.query({
			query: () => 'projects/showAllActiveProjectsOfFollowingGroup',
			providesTags: ['Project']
		}),
		addProject: build.mutation({
			query: (project) => ({
				url: '/projects',
				method: 'POST',
				body: project
			})
		})
	})
});
export const { useGetProjectQuery } = ProjectApi;
