import { useState } from 'react';
import QrReader from 'react-qr-reader';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const QRCodeScanner = () => {
	const { t } = useTranslation();
	const [selected, setSelected] = useState('user');
	const [startScan, setStartScan] = useState(false);
	const [loadingScan, setLoadingScan] = useState(false);
	const [data, setData] = useState('');

	const navigate = useNavigate();

	const handleScan = async (scanData) => {
		setLoadingScan(true);
		console.log(`loaded data data`, scanData);
		if (scanData && scanData !== '') {
			console.log(`loaded >>>`, scanData);
			setData(scanData);
			setStartScan(false);
			setLoadingScan(false);
			navigate('/recordBoard', { state: { deviceTag: scanData } });
		}
	};
	const handleError = (err) => {
		console.error(err);
	};
	return (
		<div className="App">
			<h2>{t('scan.title')}</h2>
			<Button
				variant="contained"
				onClick={() => {
					setStartScan(!startScan);
				}}
			>
				{startScan ? `${t('scan.stopScan')}` : `${t('scan.startScan')}`}
			</Button>
			{startScan && (
				<>
					<QrReader
						facingMode={selected}
						delay={100}
						onError={handleError}
						onScan={handleScan}
						style={{ width: '350px', marginLeft: '35%', marginTop: '3%' }}
					/>
				</>
			)}
			{loadingScan && <p>{t('looding')}</p>}
			{data !== '' && <p>Scan Data: {data}</p>}
		</div>
	);
};

export default QRCodeScanner;
