import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import styles from './AdminCollaborationTable.module.css';
import ShowAllCollaborationOrganization from '../../../HandoverComponents/HandoverApi/ShowAllCollaborationOrganizationApi';
import { useTranslation } from 'react-i18next';

const getDatePlease = (D) => {
	return new Date(D).toLocaleString();
};

const AdminCollaborationTable = () => {
	const { t } = useTranslation();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [groupData, setGroupData] = useState([]);

	console.log(groupData);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	useEffect(() => {
		const FetchData = async () => {
			const response = await ShowAllCollaborationOrganization();
			console.log('organizatoin all data', response.body);
			setGroupData(response.body);
		};
		FetchData();
	}, [groupData]);

	return (
		<>
			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<TableContainer sx={{ maxHeight: 345 }}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
									align="center"
								>
									{t('collaboration.organizationInitial')}
								</TableCell>
								<TableCell
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
									align="center"
								>
									{t('collaboration.organizationName')}
								</TableCell>
								{/* <TableCell style={{ backgroundColor: ' rgb(203, 200, 200)', fontWeight: 'bold' }} align="center">Organization Name </TableCell> */}
								{/* <TableCell
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
									align="center"
								>
									{t('collaboration.action')}
								</TableCell> */}
							</TableRow>
						</TableHead>
						<TableBody>
							{groupData &&
								groupData.map((row) => (
									<TableRow
										key={row._id}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row" align="center">
											{row.initial}
										</TableCell>
										<TableCell align="center">{row.name_en}</TableCell>
										{/* <TableCell align="center">{row.name_jp}</TableCell> */}
										{/* <TableCell align="center">
											<Stack
												justifyContent="center"
												spacing={2}
												direction="row"
											>
												<Button variant="contained">Update</Button>
												<Button variant="outlined" color="error">
													Block
												</Button>
											</Stack>
										</TableCell> */}
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					labelRowsPerPage={t('projectTable.rowsPerPage')}
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={groupData.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</>
	);
};

export default AdminCollaborationTable;
