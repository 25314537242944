import { Box, Stack } from '@mui/material';
import Footer from './Footer';
import Header from './Header';
import Sidebar from './Sidebar';
import { Outlet } from 'react-router-dom';

const Layout = () => {
	return (
		<>
			<Sidebar />
			<Stack direction="column" sx={{ minHeight: '100vh' }}>
				<Header />
				<Box
					flexGrow={1}
					sx={{
						display: 'flex',
						justifyContent: 'center',
						// padding: '0 150px',
						color: '#718399',
						mx: 6
					}}
				>
					<Box
						sx={{
							width: '1050px',
							m: 2,
							border: '1px solid #f9f9f9',
							borderRadius: '5px',
							p: 2
						}}
					>
						{/* <Dashboard /> */}
						{/* {this.props.children} */}
						<Outlet />
					</Box>
				</Box>
				<Footer />
			</Stack>
		</>
	);
};

export default Layout;
