import { React } from 'react';
import Paper from '@mui/material/Paper';
import InputBase from '@mui/material/InputBase';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import SearchIcon from '@mui/icons-material/Search';
import Tooltip from '@mui/material/Tooltip';
import styles from './GlobalSearch.module.css';
import { useTranslation } from 'react-i18next';

export default function GlobalSearch({ searchItem, setSearchItem }) {
	const { t } = useTranslation();
	return (
		<>
			{/* <div className={styles['search-layout']}>
                <Tooltip title="Search">
                    <IconButton type="button" color="primary" sx={{ p: '10px' }} aria-label="search">
                        <SearchIcon />
                    </IconButton>
                </Tooltip>
                <InputBase
                    type='text'
                    sx={{
                        width: 320,
                        height: 18,
                        sx: 1,
                       
                    }}
                    placeholder="Search in a table"
                    inputProps={{ 'aria-label': 'search' }}
                    value={props.SearchItem}


                    onChange={(e) => { props.setSearchItem(e.target.value) }}
                />
            </div> */}

			<Paper
				component="form"
				sx={{
					p: '2px 2px',
					border: '1px solid #415A77',
					display: 'flex',
					alignItems: 'center',
					width: 400
				}}
			>
				<InputBase
					sx={{ ml: 1, flex: 1 }}
					placeholder={t('trackTable.search')}
					inputProps={{ 'aria-label': 'search' }}
					value={searchItem}
					onChange={(e) => {
						setSearchItem(e.target.value);
					}}
				/>
				<IconButton type="button" sx={{ p: '5px' }} aria-label="search">
					<SearchIcon />
				</IconButton>
			</Paper>
		</>
	);
}
