import { Box, Stack, Typography } from '@mui/material';

const LoginFooter = () => {
	return (
		<Box
			sx={{
				// backgroundColor: '#f6f8fa',
				paddingTop: '1.2rem',
				paddingBottom: '1rem'
			}}
		>
			<Stack
				direction="row"
				spacing={0.5}
				justifyContent="center"
				color="#718399"
			>
				<Typography variant="body2">
					&copy;
					{`${new Date().getFullYear()} Ultra-X Asia Pacific Inc.`}
				</Typography>
				<Typography variant="body2">All rights reserved.</Typography>
			</Stack>
		</Box>
	);
};

export default LoginFooter;
