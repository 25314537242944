import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';

const getDatePlease = (D) => {
	return new Date(D).toLocaleDateString();
};

const ProjectInfo = ({ projectData }) => {
	const { t } = useTranslation();
	const { status, group, createdAt, deadline, start_date, end_date } =
		projectData;
	const [initialDate, setInitialDate] = useState('1970-01-01T00:00:00.000Z');
	return (
		<>
			<Box sx={{ display: 'flex', flexDirection: 'row' }}>
				<Box sx={{ width: '70%', textAlign: 'left' }}>
					<Typography sx={{ fontSize: 14 }} color="text.secondary">
						{t('projectInformation.status')}
					</Typography>
					<Typography sx={{ fontSize: 14 }} color="text.secondary">
						{t('projectInformation.group')}
					</Typography>
					<Typography sx={{ fontSize: 14 }} color="text.secondary">
						{t('projectInformation.createdDate')}
					</Typography>
					<Typography sx={{ fontSize: 14 }} color="text.secondary">
						{t('projectInformation.duration')}
					</Typography>
					<Typography sx={{ fontSize: 14 }} color="text.secondary">
						{t('projectInformation.deadline')}
					</Typography>
				</Box>

				<Box sx={{ width: '100%', textAlign: 'left' }}>
					<Typography sx={{ fontSize: 14 }} color="#D9AD8D">
						{status ? status.toUpperCase() : null}
					</Typography>
					<Typography sx={{ fontSize: 14 }}>
						{' '}
						{group ? group.name_en : null}
					</Typography>
					<Typography sx={{ fontSize: 14 }}>
						{getDatePlease(createdAt)}
					</Typography>
					<Typography sx={{ fontSize: 14 }}>
						{' '}
						{start_date === initialDate
							? 'N/A'
							: getDatePlease(start_date)} ~{' '}
						{end_date === initialDate ? 'N/A' : getDatePlease(end_date)}
					</Typography>
					<Typography sx={{ fontSize: 14 }} color="red">
						{getDatePlease(deadline)}
					</Typography>
				</Box>
			</Box>
		</>
	);
};

export default ProjectInfo;
