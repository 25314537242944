import React, { useState, useEffect } from 'react';
import styles from './Track.module.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import changeCatalog from '../../Icons/changeCatalog.svg';
import downloadSummary from '../../Icons/downloadSummary.svg';
import publishButton from '../../Icons/publishButton.svg';
import createTrack from '../../Icons/createTrack.svg';
import csvIcon from '../../Icons/csvIcon.svg';
import pdfIcon from '../../Icons/pdfIcon.svg';
import ProgressBar from './ProgressBar';
import TrackInfo from './TrackInfo';
import DeviceInfo from './DeviceInfo';
import GlobalSearch from './GlobalSearch';
import TrackTable from './TrackTable';
import ProjectInfo from './ProjectInfo';
import { useNavigate, useLocation } from 'react-router-dom';
import ShowFollowingProjectsDetailsByProjectId from './TrackApi/ShowFollowingProjectsDetailsByProjectId';
import ShowDeviceSummaryOfFollowingProject from './TrackApi/ShowDeviceSummaryOfFollowingProject';
import CreateTrackModal from './TrackCreate/CreateTrackModal';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';
import PublishProject from './PublishProject';
import { useTranslation } from 'react-i18next';

const getDatePlease = (D) => {
	return new Date(D).toLocaleDateString();
};

const Track = () => {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const [projectData, setProjectData] = useState([]);
	const [trackSummary, setTrackSummary] = useState([]);
	const [deviceSummary, setDeviceSummary] = useState([]);
	const [searchItem, setSearchItem] = useState('');
	const [loading, setLoading] = useState(false);
	const open = Boolean(anchorEl);

	const { state } = useLocation();
	const { trackId } = state;
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	useEffect(() => {
		const FetchProjectData = async () => {
			setLoading(true);
			const allData = await ShowFollowingProjectsDetailsByProjectId(trackId);
			console.log('this is all project details', allData);

			if (allData.success === true) {
				setProjectData(allData.body);
				setTrackSummary(allData.body.track_summary);
				setLoading(false);
				console.log('data aise ');
			}
		};
		FetchProjectData();
	}, [trackId]);

	useEffect(() => {
		const FetchDeviceSummaryData = async () => {
			const allData = await ShowDeviceSummaryOfFollowingProject(trackId);
			console.log('this is all device summary details', allData);

			if (allData.success === true) {
				setDeviceSummary(allData.body.device_summary);
				console.log('data aise ');
			}
		};
		FetchDeviceSummaryData();
	}, [trackId]);

	return (
		<>
			{loading === true ? (
				<Box sx={{ textAlign: 'center', fontSize: '18px', fontWeight: 'bold' }}>
					{t('looding')}
				</Box>
			) : (
				<div>
					<div role="presentation" className={styles['layoutForBreadcrumbs']}>
						<Breadcrumbs
							aria-label="breadcrumb"
							separator={<NavigateNextIcon fontSize="small" />}
						>
							<Link underline="hover" color="inherit" href="/userDashboard">
								{t('breadCums.projectBoard')}
							</Link>
							<Typography color="text.primary">
								{t('breadCums.trackBoard')}
							</Typography>
						</Breadcrumbs>
					</div>
					<Card sx={{ minWidth: 400 }}>
						<CardContent>
							<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
								<Box>
									<Typography
										sx={{ fontSize: 18 }}
										color="text.secondary"
										gutterBottom
									>
										{t('projectInformation.title')}
									</Typography>
								</Box>
								<Box sx={{ color: '#D9AD8D' }}>
									<img
										src={changeCatalog}
										alt="changeCatalogIcons"
										width="16"
										height="16"
										style={{ color: 'red' }}
									/>{' '}
									{projectData.published === true
										? `${t('projectStatus.published')}`
										: `${t('projectStatus.unpublished')}`}
								</Box>
							</Box>
							<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
								<Box>
									<Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>
										{projectData ? projectData.name_en : null}
									</Typography>
								</Box>
								<Box>
									<ProgressBar trackId={trackId} />
								</Box>
							</Box>

							<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
								<Box sx={{ marginTop: '1rem', width: 290, height: 112 }}>
									<ProjectInfo projectData={projectData} />
								</Box>
								<Box
									sx={{
										display: 'flex',
										justifyContent: 'space-between',
										width: 700,
										height: 110
									}}
								>
									<Box>
										{trackSummary ? (
											<TrackInfo
												trackSummary={trackSummary}
												title={t('trackSummary.trackTitle')}
											/>
										) : null}
									</Box>
									<Box>
										{deviceSummary ? (
											<TrackInfo
												trackSummary={deviceSummary}
												title={t('trackSummary.deviceTitle')}
											/>
										) : null}
									</Box>
								</Box>
							</Box>
						</CardContent>
						<CardActions>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'space-between',
									marginTop: 0,
									padding: '10px',
									width: '100%'
								}}
							>
								<Box>
									{/* <Button
								variant="outlined"
								// className={styles["download-summary-button"]}
								sx={{ borderRadius: '5px', color: '#415A77' }}
								startIcon={
									<img
										src={downloadSummary}
										alt="downloadSummary"
										width="16"
										height="16"
									/>
								}
								aria-controls={open ? 'basic-menu' : undefined}
								aria-haspopup="true"
								aria-expanded={open ? 'true' : undefined}
								onClick={handleClick}
							>
								Project Summary
							</Button>
							<Menu
								id="basic-menu"
								anchorEl={anchorEl}
								open={open}
								onClose={handleClose}
								MenuListProps={{
									'aria-labelledby': 'basic-button'
								}}
							>
								<Button
									startIcon={
										<img src={pdfIcon} alt="pdfIcon" width="16" height="16" />
									}
									sx={{ color: '#415A77', width: '100%' }}
									onClick={handleClose}
								>
									PDF
								</Button>
								<Button
									startIcon={
										<img src={csvIcon} alt="csvIcon" width="16" height="16" />
									}
									sx={{ color: '#415A77', width: '100%' }}
								>
									CSV
								</Button>
							</Menu> */}
								</Box>
								<Box>
									{/* <Button
								variant="contained"
								sx={{
									color: 'white',
									backgroundColor: '#415A77',
									borderRadius: '5px'
								}}
								startIcon={
									<img
										src={publishButton}
										alt="publishButton"
										width="16"
										height="16"
									/>
								}
							>
								Publish
							</Button> */}
									<PublishProject
										trackId={trackId}
										publish={projectData.published}
									/>
								</Box>
							</Box>
						</CardActions>
					</Card>

					<Paper
						component="form"
						sx={{
							marginTop: '10px',
							marginBottom: '40px',
							align: 'right',
							alignItems: 'center',
							width: '100%'
						}}
						elevation={2}
					>
						{/* <GlobalSearch
          searchItem={searchItem}
          setSearchItem={setSearchItem}
        />
         <CreateTrackModal /> */}
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								padding: '20px'
							}}
						>
							<Box>
								<GlobalSearch
									searchItem={searchItem}
									setSearchItem={setSearchItem}
								/>
							</Box>
							<Box>
								{/* <Button
              variant="outlined"
              sx={{ borderRadius: "5px", color: "#415A77" }}
              startIcon={
                <img
                  src={createTrack}
                  alt="CreateTrack"
                  width="16"
                  height="16"
                />
              }
            >
              Create Track
            </Button> */}
								{/* {projectData.status === 'ready' ? ( */}
								<CreateTrackModal
									projectId={projectData._id}
									projectName={projectData.name_en}
								/>
								{/* ) : null} */}
							</Box>
						</Box>
						<Box>
							<Paper
								component="form"
								sx={{
									marginTop: '0px',
									marginLeft: '20px',
									marginRight: '20px',
									marginBottom: '40px',
									align: 'right',
									alignItems: 'center',
									flex: 1
								}}
								elevation={2}
							>
								<TrackTable
									projectId={projectData._id}
									searchItem={searchItem}
								/>
							</Paper>
						</Box>
					</Paper>
				</div>
			)}
		</>
	);
};

export default Track;
