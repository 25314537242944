import { Box, Grid } from '@mui/material';
import LoginFooter from '../Login/LoginFooter';
import WelcomeAnimation from '../WelcomeAnimation';
import RegistrationForm from './RegistrationForm';
import styles from './RegistrationPanel.module.css';

const Registration = () => {
	return (
		<>
			<div className={styles['welcome-panel-wrapper']}>
				{/* <LoginHeader /> */}
				<Grid container justifyContent="center" spacing={3} mt={1} ml={10}>
					<Grid
						item
						sm={0}
						md={4}
						mt={4}
						className={styles['registration-lottie-wrapper']}
					>
						{/* <WelcomeSlider/> */}
						<WelcomeAnimation />
					</Grid>
					<Grid item md={6} xs={12} justifyContent="center">
						<Box
							sx={{
								marginLeft: '5px'
							}}
						>
							<Box
								sx={{ width: '100%' }}
								className={styles['welcome-login-reg-side-wrapper']}
							>
								<RegistrationForm />
							</Box>
						</Box>
					</Grid>
				</Grid>
				<LoginFooter />
			</div>
		</>
	);
};

export default Registration;
