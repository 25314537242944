import jwt_decode from 'jwt-decode';
import { BusinessRounded, MailOutlined, Place } from '@mui/icons-material';
import { Avatar, Box, Stack, Typography } from '@mui/material';

const ProfileCard = () => {
	// const user_name = JSON.parse(localStorage.getItem('user_name'));
	const jwt_token = localStorage.getItem('access_token');
	const email = jwt_decode(jwt_token).email;
	const full_name =
		jwt_decode(jwt_token).first_name + ' ' + jwt_decode(jwt_token).last_name;
	const organization_name_en = jwt_decode(jwt_token).organization_name_en;

	return (
		<>
			<Box
				display="flex"
				justifyContent="center"
				border={1}
				px={3}
				py={2}
				borderColor="#dee4ed"
				borderRadius={2}
			>
				<Stack direction="row" spacing={2} alignItems="center">
					<Avatar
						alt="Profile Avatar"
						src={`https://api.dicebear.com/6.x/avataaars/svg?seed=${email}&size=120&skinColor=ffdbb4&eyes=default&mouth=default&eyebrows=default&hairColor=2c1b18`}
						sx={{
							width: '120px',
							height: '120px',
							backgroundColor: '#dee4ed50',
							border: '2px solid #dee4ed'
						}}
					/>
					<Stack direction="column" alignItems="start" gap={0.5}>
						<Typography variant="h2" noWrap fontSize="1.5rem" fontWeight="bold">
							{full_name}
						</Typography>
						<Box display="flex" flexDirection="column" ml={0.2}>
							<Box display="flex" alignItems="center" gap={1}>
								<MailOutlined
									sx={{
										fontSize: '1rem'
									}}
								/>
								<Typography mt={0.2}>{email}</Typography>
							</Box>
							<Box display="flex" alignItems="center" gap={1}>
								<BusinessRounded
									sx={{
										fontSize: '1rem'
									}}
								/>
								<Typography mt={0.2}>{organization_name_en}</Typography>
							</Box>
							<Box display="flex" alignItems="center" gap={1}>
								<Place
									sx={{
										fontSize: '1rem'
									}}
								/>
								<Typography mt={0.2}>Dhaka, Bangladesh</Typography>
							</Box>
						</Box>
					</Stack>
				</Stack>
			</Box>
		</>
	);
};

export default ProfileCard;
