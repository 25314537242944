const CreateDevice = async (trackId, values) => {
	const accessToken = localStorage.getItem('access_token');
	console.log(accessToken);
	//   console.log("kono ekta", projectId);
	const res = await fetch(
		`${process.env.REACT_APP_BASE_URL + 'devices/createDevice'}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				track_id: trackId,
				device_model: values.requestType,
				serial: values.deviceSerial
			})
		}
	);

	const data = await res.json();
	console.log(data);
	return data;
};

export default CreateDevice;
