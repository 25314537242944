import { configureStore } from '@reduxjs/toolkit';
import { ProjectApi } from '../Services/Project';
// import cartSlice from './cartSlice';
// import ProjectSlice from './ProjectSlice';

const store = configureStore({
	reducer: {
		[ProjectApi.reducerPath]: ProjectApi.reducer
		// cart: cartSlice,
		// project: ProjectSlice
	},
	middleware: (getDefaultMiddleware) =>
		getDefaultMiddleware().concat(ProjectApi.middleware)
});

export default store;
