import React, { useEffect } from 'react';

import styles from './HandoverComponents.module.css';
import { useNavigate } from 'react-router-dom';
import HandoverTabs from './HandoverTabs';

const Handover = () => {
	const navigate = useNavigate();
	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			navigate('/');
		}
	}, [navigate]);
	return (
		<>
			<div className={styles[`adminDashboard-wrapper`]}>
				<div>{/* <TrackBoardTable/> */}</div>
				<div className={styles['adminDashboardTable-wraper']}>
					<HandoverTabs />
				</div>
			</div>
		</>
	);
};

export default Handover;
