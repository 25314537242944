const CreateProjectApiHandler = async (values, clickGroupId) => {
	const accessToken = localStorage.getItem('access_token');
	console.log(accessToken);
	console.log(clickGroupId);
	console.log(values);

	const res = await fetch(
		`${process.env.REACT_APP_BASE_URL + 'projects/createProject'}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				group_id: clickGroupId,
				name_en: values.projectNameEn,
				name_jp: values.projectNameJp,
				deadline: values.date
			})
		}
	);

	const data = await res.json();
	console.log(data);
	return data;
};

export default CreateProjectApiHandler;
