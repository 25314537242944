import * as React from 'react';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
// import Track from '../handover/Track';
// import Track_req from '../handover/Track_req';
// import HandoverRequest from '../handover/HandoverRequest';
// import OrgHandoverAccepted from '../handover/OrgHandoverAccepted';
// import SecondLayerHandoverReq from '../handover/SecondLayerHandoverReq';
// import HandoverSentStatus from '../handover/HandoverSentStatus';
// import HandoverReqFromOrg from '../handover/HandoverReqFromOrg';
import HandoverSend from '../HandoverSend';
import HandoverReceive from '../HandoverReceive';
import HandoverStatus from '../HandoverStatus';
import { useTranslation } from 'react-i18next';

function TabPanel(props) {
	const { children, value, index, ...other } = props;

	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && (
				<Box sx={{ p: 3 }}>
					<Typography>{children}</Typography>
				</Box>
			)}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.number.isRequired,
	value: PropTypes.number.isRequired
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		'aria-controls': `simple-tabpanel-${index}`
	};
}

export default function HandoverTabs() {
	const { t } = useTranslation();
	const [value, setValue] = React.useState(0);

	const handleChange = (event, newValue) => {
		setValue(newValue);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
				<Tabs
					value={value}
					onChange={handleChange}
					aria-label="basic tabs example"
				>
					{/* <Tab label="Track Information" {...a11yProps(0)} /> */}
					<Tab label={t('sendHandover.name')} {...a11yProps(0)} />
					<Tab label={t('showStatus.name')} {...a11yProps(1)} />
					<Tab label={t('handoverRequest.name')} {...a11yProps(2)} />
					{/* <Tab label="Transfer" {...a11yProps(4)} />
          <Tab label="Show Status" {...a11yProps(5)} /> */}
				</Tabs>
			</Box>
			{/* <TabPanel value={value} index={0}>
        <Track/>
      </TabPanel> */}
			<TabPanel value={value} index={0}>
				{/* <Track_req /> */}
				<HandoverSend />
			</TabPanel>
			<TabPanel value={value} index={1}>
				{/* <HandoverReqFromOrg /> */}
				<HandoverStatus />
			</TabPanel>
			<TabPanel value={value} index={2}>
				{/* <HandoverRequest /> */}
				<HandoverReceive />
			</TabPanel>
			{/* <TabPanel value={value} index={4}>
        <SecondLayerHandoverReq/>
      </TabPanel>
      <TabPanel value={value} index={5}>
      <HandoverSentStatus/>
      </TabPanel> */}
		</Box>
	);
}
