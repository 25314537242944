import { useEffect, useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import FormControlLabel from '@mui/material/FormControlLabel';
import Switch from '@mui/material/Switch';
import DeleteIcon from '@mui/icons-material/Delete';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import FilterListIcon from '@mui/icons-material/FilterList';
import ReportIcon from '@mui/icons-material/Report';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { visuallyHidden } from '@mui/utils';
import { tableCellClasses } from '@mui/material/TableCell';
import ShowAllRecordOfFollowingDevice from '../RecordApi/ShowAllRecordOfFollowingDevice';
import ReportModal from '../RecordReport/ReportModal';
import { useTranslation } from 'react-i18next';

const getDatePlease = (D) => {
	return new Date(D).toLocaleString();
};

const StyledTableCell = styled(TableCell)(({ theme }) => ({
	[`&.${tableCellClasses.head}`]: {
		backgroundColor: theme.palette.common.black,
		color: theme.palette.common.white
	},
	[`&.${tableCellClasses.body}`]: {
		fontSize: 14
	}
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
	'&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover
	},
	// hide last border
	'&:last-child td, &:last-child th': {
		border: 0
	}
}));

function createData(name, calories, fat, carbs, protein) {
	return {
		name,
		calories,
		fat,
		carbs,
		protein
	};
}

const rows = [
	createData(
		'TID0123456789',
		'2020-2-10 ~ 2021-3-29',
		'ERA>ERA>DES',
		1,
		'In Progress'
	),
	createData('TID0123456788', '2020-2-10 ~ 2021-3-29', 'DES', 1, 'Completed'),
	createData(
		'TID0123456787',
		'2020-2-10 ~ 2021-3-29',
		'ERA>ERA>DES',
		1,
		'In Progress'
	),
	createData(
		'TID0123456786',
		'2020-2-10 ~ 2021-3-29',
		'ERA>ERA>DES',
		1,
		'In Progress'
	),
	createData('TID0123456785', '2020-2-10 ~ 2021-3-29', 'ERA>DES', 1, 'On Hold'),
	createData(
		'TID0123456784',
		'2020-2-10 ~ 2021-3-29',
		'ERA>ERA>DES',
		1,
		'Running'
	),
	createData(
		'TID0123456783',
		'2020-2-10 ~ 2021-3-29',
		'ERA>ERA>DES',
		1,
		'In Progress'
	),
	createData('TID0123456782', '2020-2-10 ~ 2021-3-29', 'DES', 1, 'Completed'),
	createData(
		'TID0123456781',
		'2020-2-10 ~ 2021-3-29',
		'ERA>ERA>DES',
		1,
		'In Progress'
	),
	createData(
		'TID0123456780',
		'2020-2-10 ~ 2021-3-29',
		'ERA>ERA>DES',
		1,
		'In Progress'
	),
	createData('TID0123456779', '2020-2-10 ~ 2021-3-29', 'ERA>DES', 1, 'On Hold'),
	createData(
		'TID0123456778',
		'2020-2-10 ~ 2021-3-29',
		'ERA>ERA>DES',
		1,
		'Running'
	),
	createData(2, 452, 25.0, 51, 4.9),
	createData(3, 262, 16.0, 24, 6.0),
	createData(4, 159, 6.0, 24, 4.0),
	createData(5, 356, 16.0, 49, 3.9),
	createData(6, 408, 3.2, 87, 6.5),
	createData(7, 237, 9.0, 37, 4.3),
	createData(8, 375, 0.0, 94, 0.0),
	createData(9, 518, 26.0, 65, 7.0),
	createData(10, 392, 0.2, 98, 0.0),
	createData(11, 318, 0, 81, 2.0),
	createData(12, 360, 19.0, 9, 37.0),
	createData(13, 437, 18.0, 63, 4.0),
	createData(14, 1, 1.0, 1, 1.0),
	createData(15, 2, 2.0, 2, 2.0),
	createData(16, 3, 3.0, 3, 3.0),
	createData(17, 4, 4.0, 4, 4.0),
	createData(18, 5, 5.0, 5, 5.0),
	createData(19, 6, 6.0, 6, 6.0),
	createData(20, 7, 7.0, 7, 7.0),
	createData(21, 8, 8.0, 8, 8.0),
	createData(22, 9, 9.0, 9, 9.0),
	createData(23, 10, 10.0, 10, 10.0),
	createData(24, 11, 11.0, 11, 11.0),
	createData(25, 12, 12.0, 12, 12.0),
	createData(26, 13, 13.0, 13, 13.0)
];

function descendingComparator(a, b, orderBy) {
	if (b[orderBy] < a[orderBy]) {
		return -1;
	}
	if (b[orderBy] > a[orderBy]) {
		return 1;
	}
	return 0;
}

function getComparator(order, orderBy) {
	return order === 'desc'
		? (a, b) => descendingComparator(a, b, orderBy)
		: (a, b) => -descendingComparator(a, b, orderBy);
}

// Since 2020 all major browsers ensure sort stability with Array.prototype.sort().
// stableSort() brings sort stability to non-modern browsers (notably IE11). If you
// only support modern browsers you can replace stableSort(exampleArray, exampleComparator)
// with exampleArray.slice().sort(exampleComparator)
function stableSort(array, comparator) {
	const stabilizedThis = array.map((el, index) => [el, index]);
	stabilizedThis.sort((a, b) => {
		const order = comparator(a[0], b[0]);
		if (order !== 0) {
			return order;
		}
		return a[1] - b[1];
	});
	return stabilizedThis.map((el) => el[0]);
}

function EnhancedTableHead(props) {
	const { t } = useTranslation();
	const {
		onSelectAllClick,
		order,
		orderBy,
		numSelected,
		rowCount,
		onRequestSort
	} = props;
	const createSortHandler = (property) => (event) => {
		onRequestSort(event, property);
	};
	const headCells = [
		{
			id: 'name',
			label: `${t('recordTable.processingType')}`
		},
		{
			id: 'createdAt',
			label: `${t('recordTable.createdAt')}`
		},
		{
			id: 'calories',
			label: `${t('recordTable.location')}`
		},
		{
			id: 'fat',
			label: `${t('recordTable.toolUsed')}`
		},
		{
			id: 'carbs',
			label: `${t('recordTable.recordedBy')}`
		},
		{
			id: 'protein',
			label: `${t('recordTable.image')}`
		},
		{
			id: 'report',
			label: `${t('recordTable.action')}`
		}
	];

	return (
		<TableHead>
			<TableRow>
				{/* <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all desserts',
                        }}
                    />
                </TableCell> */}
				{headCells.map((headCell) => (
					<TableCell
						sx={{ backgroundColor: '#415A77', color: 'white' }}
						key={headCell.id}
						align="center"
						padding={headCell.disablePadding ? 'none' : 'normal'}
						sortDirection={orderBy === headCell.id ? order : false}
					>
						<TableSortLabel
							active={orderBy === headCell.id}
							direction={orderBy === headCell.id ? order : 'asc'}
							onClick={createSortHandler(headCell.id)}
							sx={{
								'&.MuiTableSortLabel-root': {
									color: 'white'
								},
								'&.MuiTableSortLabel-root:hover': {
									color: 'yellow'
								},
								'&.Mui-active': {
									color: 'yellow'
								},
								'& .MuiTableSortLabel-icon': {
									color: 'yellow !important'
								}
							}}
						>
							{headCell.label}
							{orderBy === headCell.id ? (
								<Box component="span" sx={visuallyHidden}>
									{order === 'desc' ? 'sorted descending' : 'sorted ascending'}
								</Box>
							) : null}
						</TableSortLabel>
					</TableCell>
				))}
			</TableRow>
		</TableHead>
	);
}

EnhancedTableHead.propTypes = {
	numSelected: PropTypes.number.isRequired,
	onRequestSort: PropTypes.func.isRequired,
	onSelectAllClick: PropTypes.func.isRequired,
	order: PropTypes.oneOf(['asc', 'desc']).isRequired,
	orderBy: PropTypes.string.isRequired,
	rowCount: PropTypes.number.isRequired
};

function EnhancedTableToolbar(props) {
	const { numSelected } = props;

	return (
		<Toolbar
			sx={{
				pl: { sm: 2 },
				pr: { xs: 1, sm: 1 },
				...(numSelected > 0 && {
					bgcolor: (theme) =>
						alpha(
							theme.palette.primary.main,
							theme.palette.action.activatedOpacity
						)
				})
			}}
		>
			{numSelected > 0 ? (
				<Typography
					sx={{ flex: '1 1 100%' }}
					color="inherit"
					variant="subtitle1"
					component="div"
				>
					{numSelected} selected
				</Typography>
			) : (
				<Typography
					sx={{ flex: '1 1 100%' }}
					variant="h6"
					id="tableTitle"
					component="div"
				>
					Nutrition
				</Typography>
			)}

			{numSelected > 0 ? (
				<Tooltip title="Delete">
					<IconButton>
						<DeleteIcon />
					</IconButton>
				</Tooltip>
			) : (
				<Tooltip title="Filter list">
					<IconButton>
						<FilterListIcon />
					</IconButton>
				</Tooltip>
			)}
		</Toolbar>
	);
}

EnhancedTableToolbar.propTypes = {
	numSelected: PropTypes.number.isRequired
};

export default function RecordTable({ deviceId }) {
	const { t } = useTranslation();
	const [order, setOrder] = useState('asc');
	const [orderBy, setOrderBy] = useState('calories');
	const [selected, setSelected] = useState([]);
	const [page, setPage] = useState(0);
	const [dense, setDense] = useState(false);
	const [rowsPerPage, setRowsPerPage] = useState(5);
	const [recordData, setRecordData] = useState([]);
	const [clickRecordId, setClickRecordId] = useState([]);
	const [deviceTag, setDeviceTag] = useState([]);

	const [anchorEl, setAnchorEl] = useState(null);
	const open = Boolean(anchorEl);
	const handleClickForOpen = (event, id) => {
		console.log('see device tag', id);
		setClickRecordId(id);
		// setDeviceTag(device);
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};

	const handleRequestSort = (event, property) => {
		const isAsc = orderBy === property && order === 'asc';
		setOrder(isAsc ? 'desc' : 'asc');
		setOrderBy(property);
	};

	const handleSelectAllClick = (event) => {
		if (event.target.checked) {
			const newSelected = rows.map((n) => n.name);
			setSelected(newSelected);
			return;
		}
		setSelected([]);
	};

	const handleClick = (event, name) => {
		const selectedIndex = selected.indexOf(name);
		let newSelected = [];

		if (selectedIndex === -1) {
			newSelected = newSelected.concat(selected, name);
		} else if (selectedIndex === 0) {
			newSelected = newSelected.concat(selected.slice(1));
		} else if (selectedIndex === selected.length - 1) {
			newSelected = newSelected.concat(selected.slice(0, -1));
		} else if (selectedIndex > 0) {
			newSelected = newSelected.concat(
				selected.slice(0, selectedIndex),
				selected.slice(selectedIndex + 1)
			);
		}

		setSelected(newSelected);
	};

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(parseInt(event.target.value, 10));
		setPage(0);
	};

	const handleChangeDense = (event) => {
		setDense(event.target.checked);
	};

	const isSelected = (name) => selected.indexOf(name) !== -1;

	// Avoid a layout jump when reaching the last page with empty rows.
	const emptyRows =
		page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

	const visibleRows = useMemo(
		() =>
			stableSort(recordData, getComparator(order, orderBy)).slice(
				page * rowsPerPage,
				page * rowsPerPage + rowsPerPage
			),
		[recordData, order, orderBy, page, rowsPerPage]
	);

	useEffect(() => {
		const FetchTrackData = async () => {
			const allData = await ShowAllRecordOfFollowingDevice(deviceId);
			console.log('table data show', allData);

			if (allData.success === true) {
				setRecordData(await allData.body);
				console.log('data aise ');
			}
		};
		FetchTrackData();
	}, [deviceId]);

	return (
		<Box sx={{ width: '100%' }}>
			<Paper sx={{ width: '100%', mb: 2 }}>
				{/* <EnhancedTableToolbar numSelected={selected.length} /> */}
				<TableContainer sx={{ marginTop: 0, overflowX: 'initial' }}>
					<Table
						sx={{ minWidth: 750, marginTop: 0 }}
						// aria-labelledby="tableTitle"
						stickyHeader
						aria-label="sticky table"
						size={dense ? 'small' : 'medium'}
					>
						<EnhancedTableHead
							numSelected={selected.length}
							order={order}
							orderBy={orderBy}
							onSelectAllClick={handleSelectAllClick}
							onRequestSort={handleRequestSort}
							rowCount={rows.length}
						/>
						<TableBody>
							{visibleRows.map((row, index) => {
								const isItemSelected = isSelected(row.name);
								const labelId = `enhanced-table-checkbox-${index}`;

								return (
									<TableRow
										hover
										onClick={(event) => handleClick(event, row.name)}
										// role="checkbox"
										// aria-checked={isItemSelected}
										tabIndex={-1}
										key={row.name}
										// selected={isItemSelected}
										sx={{ cursor: 'pointer' }}
									>
										{/* <TableCell padding="checkbox">
                                            <Checkbox
                                                color="primary"
                                                checked={isItemSelected}
                                                inputProps={{
                                                    'aria-labelledby': labelId,
                                                }}
                                            />
                                        </TableCell> */}
										<TableCell
											component="th"
											id={labelId}
											scope="row"
											padding="none"
											align="center"
										>
											{row.processing_type}
										</TableCell>
										<TableCell align="center">
											{getDatePlease(row.createdAt)}
										</TableCell>
										<TableCell align="center">{row.location}</TableCell>
										<TableCell align="center">
											{row && row.tool ? row.tool.name_en : null}
										</TableCell>
										<TableCell align="center">
											{row && row.recorded_by
												? row.recorded_by.first_name +
												  ' ' +
												  row.recorded_by.last_name
												: null}
										</TableCell>
										<TableCell align="center">
											<img
												src={process.env.REACT_APP_BASE_URL + row.image_link}
												width="200"
												height="200"
												alt="devicepic"
											/>
										</TableCell>
										<TableCell align="center">
											<MoreVertIcon
												onClick={(event) => handleClickForOpen(event, row._id)}
											/>
											<Menu
												anchorEl={anchorEl}
												id="account-menu"
												open={open}
												onClose={handleClose}
												// onClick={handleClose}
												PaperProps={{
													elevation: 0,
													sx: {
														overflow: 'visible',
														filter: 'drop-shadow(0px 2px 4px rgba(0,0,0,0.10))',
														mt: 1.5,
														'& .MuiAvatar-root': {
															width: 32,
															height: 32,
															ml: -0.5,
															mr: 1
														},
														'&:before': {
															content: '""',
															display: 'block',
															position: 'absolute',
															top: 0,
															right: 14,
															width: 10,
															height: 10,
															bgcolor: 'background.paper',
															transform: 'translateY(-50%) rotate(45deg)',
															zIndex: 0
														}
													}
												}}
												transformOrigin={{
													horizontal: 'right',
													vertical: 'top'
												}}
												anchorOrigin={{
													horizontal: 'right',
													vertical: 'bottom'
												}}
											>
												{/* <MenuItem onClick={() => {}}>
													<ListItemIcon>
														<ReportIcon fontSize="small" />
													</ListItemIcon>
													Report
												</MenuItem> */}
												{row.reported ? (
													<MenuItem>
														<ListItemIcon>
															<ReportIcon fontSize="small" />
														</ListItemIcon>
														{t('createReport.alreadyReported')}
													</MenuItem>
												) : (
													<ReportModal
														clickRecordId={clickRecordId}
														deviceTag={row.device ? row.device.device_tag : 0}
													/>
												)}
											</Menu>
										</TableCell>
									</TableRow>
								);
							})}
							{emptyRows > 0 && (
								<TableRow
									style={{
										height: (dense ? 33 : 53) * emptyRows
									}}
								>
									<TableCell colSpan={6} />
								</TableRow>
							)}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					rowsPerPageOptions={[5, 10, 25]}
					component="div"
					count={recordData.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
			{/* <FormControlLabel
                control={<Switch checked={dense} onChange={handleChangeDense} />}
                label="Dense padding"
            /> */}
		</Box>
	);
}
