const ShowTrackStatusByTrackId = async (trackId) => {
	const accessToken = localStorage.getItem('access_token');
	console.log(accessToken);
	//   console.log("kono ekta", projectId);
	const res = await fetch(
		`${
			process.env.REACT_APP_BASE_URL + 'devices/showTracksRecordStatusByTrackId'
		}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				track_id: trackId
			})
		}
	);

	const data = await res.json();
	console.log(data);
	return data;
};

export default ShowTrackStatusByTrackId;
