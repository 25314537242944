import React from 'react';
import Button from '@mui/material/Button';
import downloadSummary from '../../../Icons/downloadSummary.svg';
import jsPDF from 'jspdf';
import QRCode from 'qrcode';
import { useEffect, useState } from 'react';
import ShowAllActiveDeviceOfFollowingTrack from '../DeviceApi/ShowAllActiveDeviceOfFollowingTrack';
import { useTranslation } from 'react-i18next';

const QRCodeGenerator = ({ trackId }) => {
	const { t } = useTranslation();
	const [deviceData, setDeviceData] = useState([]);

	useEffect(() => {
		const FetchTrackData = async () => {
			const allData = await ShowAllActiveDeviceOfFollowingTrack(trackId);
			console.log(allData.body);
			if (allData.success === true) {
				setDeviceData(await allData.body);
				console.log('data aise ');
			}
		};
		FetchTrackData();
	}, [trackId]);

	const downloadPDF = async () => {
		try {
			const qrCodesPerPage = 18; // Number of QR codes per page
			const qrCodeWidth = 20; // Width of each QR code
			const qrCodeHeight = 20; // Height of each QR code
			const boxHeight = 25; // Height of the info box
			const boxWidth = 23; // Width of the info box
			const startX = 20; // X-coordinate to start adding QR codes
			const startY = 20; // Y-coordinate to start adding QR codes
			const padding = 0.5; // Padding between QR codes and boxes
			const columnPadding = 25; // Padding between columns
			const headerHeight = 10; // Height of the header

			let currentPage = 1;
			let qrCodeCounter = 0;
			let x = startX;
			let y = startY;

			const doc = new jsPDF();
			if (deviceData) {
				for (let i = 0; i < deviceData.length; i++) {
					const data = deviceData[i];

					// Add a new page if the current page is full
					if (qrCodeCounter === qrCodesPerPage) {
						doc.addPage();
						currentPage++;
						qrCodeCounter = 0;
						x = startX;
						y = startY;
					}

					// Draw QR code
					const canvas = document.createElement('canvas');
					await QRCode.toCanvas(canvas, deviceData[i].device_tag, {
						width: qrCodeWidth,
						height: qrCodeHeight
					});

					const imgData = canvas.toDataURL('image/png');
					doc.addImage(imgData, 'PNG', x, y, qrCodeWidth, qrCodeHeight);

					// Draw info box
					doc.setFillColor('#FFFFFF');
					doc.rect(x + qrCodeWidth + padding, y, boxWidth, boxHeight, 'F');

					// Draw info text
					// const infoX = x + qrCodeWidth + padding + 1; // X-coordinate for info text
					// const infoY = y + 7; // Starting Y-coordinate for info text
					// doc.setFontSize(8);
					// doc.setTextColor("#000000");
					// doc.text(`Group Name: ${data.groupName}`, infoX, infoY);
					// doc.text(`Project Name: ${data.projectName}`, infoX, infoY + 5);
					// doc.text(`Track ID: ${data.trackId}`, infoX, infoY + 10);
					// doc.text(`Deadline: ${data.deadline}`, infoX, infoY + 15);

					// Draw QR code name
					const nameX = x + qrCodeWidth / 2;
					const nameY = y + qrCodeHeight + 5;
					doc.setFontSize(9);
					doc.setTextColor('#000000');
					doc.text(` ${deviceData[i].device_tag}`, nameX, nameY, {
						align: 'center'
					});

					qrCodeCounter++;
					x += qrCodeWidth + boxWidth + padding * 2 + columnPadding;
					if (qrCodeCounter % 3 === 0) {
						x = startX;
						y += qrCodeHeight + boxHeight + padding;
					}
				}
			}
			//Header of the page
			doc.setPage(1);
			doc.setFontSize(12);
			doc.setTextColor('#000000');
			doc.text(
				'All QR Code for Device Tag',
				doc.internal.pageSize.getWidth() / 2.1,
				headerHeight,
				{
					align: 'center'
				}
			);

			// Add page numbers as headers
			for (let i = 1; i <= doc.getNumberOfPages(); i++) {
				doc.setPage(i);
				doc.setFontSize(6);
				doc.setTextColor('#000000');
				doc.text(
					`Page ${i}`,
					doc.internal.pageSize.getWidth() - 14,
					headerHeight
				);
			}

			doc.save(`${trackId}`);
		} catch (error) {
			console.error(error);
		}
	};

	return (
		<>
			<Button
				variant="outlined"
				startIcon={
					<img
						src={downloadSummary}
						alt="downloadSummary"
						width="16"
						height="16"
					/>
				}
				sx={{ borderRadius: '5px', color: '#415A77 ' }}
				onClick={downloadPDF}
			>
				{t('button.qrCode')}
			</Button>
		</>
	);
};

export default QRCodeGenerator;
