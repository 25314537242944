const CreatePermissionApiHandler = async (values) => {
	const accessToken = localStorage.getItem('access_token');

	const res = await fetch(
		`${process.env.REACT_APP_BASE_URL + 'permissions/createPermission'}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				title: values.permissionTitle,
				permissions: values.checked
			})
		}
	);

	const data = await res.json();
	console.log(data);
	return data;
};

export default CreatePermissionApiHandler;
