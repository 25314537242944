import { Box } from '@mui/material';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Doughnut } from 'react-chartjs-2';

const DoughnutChart = ({ statCardContent }) => {
	ChartJS.register(ArcElement, Tooltip, Legend);

	const data = {
		labels: ['Remaining', 'In Progress', 'Completed'],
		datasets: [
			{
				label: 'Device',
				data: [
					statCardContent.remaining,
					statCardContent.inProgress,
					statCardContent.completed
				],
				backgroundColor: ['#DEE4ED', '#718399', '#415A77'],
				borderColor: ['#DEE4ED', '#718399', '#415A77'],
				borderWidth: 1,
				cutout: '70%'
			}
		]
	};

	const options = {
		plugins: {
			legend: {
				display: false
			},
			tooltip: {
				titleFont: { size: 10 },
				bodyFont: { size: 9 }
			}
		},
		responsive: true,
		maintainAspectRatio: false
	};

	return (
		<>
			<Box sx={{ height: '80px', width: '80px' }}>
				<Doughnut data={data} options={options} />
			</Box>
		</>
	);
};

export default DoughnutChart;
