const ShowAllActiveProjectOfFollowingGroup = async (groupId) => {
	const accessToken = localStorage.getItem('access_token');
	const res = await fetch(
		`${
			process.env.REACT_APP_BASE_URL +
			'projects/showAllActiveProjectsOfFollowingGroup'
		}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				group_id: groupId
			})
		}
	);
	const data = await res.json();
	return data;
};

export default ShowAllActiveProjectOfFollowingGroup;
