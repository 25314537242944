import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import LanguageIcon from '@mui/icons-material/Language';
import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import Select from '@mui/material/Select';

const LanguageButton = (props) => {
	const [anchorEl, setAnchorEl] = React.useState(null);
	const open = Boolean(anchorEl);
	const [age, setAge] = React.useState('');

	const handleChange = (event) => {
		setAge(event.target.value);
	};
	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	return (
		<>
			{/* <Button
				id="basic-button"
				size="large"
				aria-controls={open ? 'basic-menu' : undefined}
				aria-haspopup="true"
				aria-expanded={open ? 'true' : undefined}
				onClick={handleClick}
				sx={{
					color: '#718399'
				}}
			>
				<LanguageIcon />
			</Button>
			<Menu
				id="basic-menu"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				
				sx={{
					'& .MuiList-root': {
						padding: 0.5
					},
					'& .MuiPaper-root': {
						borderRadius: '10px',
						boxShadow: '0px 0px 10px 0px #dee4ed'
					}
				}}
			>
				<MenuItem value="en">English</MenuItem>
				<MenuItem value="jp">Japanese</MenuItem>
			</Menu> */}

			{/* <select onChange={props.onChange}>
				<option>Language change</option>
				<option value={'en'}>English</option>
				<option value={'jp'}>Japanese</option>
			</select> */}
			<Box sx={{ minWidth: 120 }}>
				<FormControl sx={{ width: 130 }}>
					<InputLabel
						variant="standard"
						htmlFor="uncontrolled-native"
						sx={{ color: '' }}
					>
						Language change
					</InputLabel>
					<NativeSelect
						defaultValue={'en'}
						inputProps={{
							name: 'age',
							id: 'uncontrolled-native'
						}}
						onChange={props.onChange}
					>
						<option value={'en'}>English</option>
						<option value={'jp'}>Japanese</option>
					</NativeSelect>
				</FormControl>
			</Box>
		</>
	);
};

export default LanguageButton;
