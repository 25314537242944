import * as React from 'react';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import LinearProgress, {
	linearProgressClasses
} from '@mui/material/LinearProgress';
import { useTranslation } from 'react-i18next';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 20,
	borderRadius: 10,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor:
			theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: theme.palette.mode === 'light' ? '#415A77' : '#308fe8'
	}
}));

const DeviceProgressBar = (props) => {
	const { t } = useTranslation();
	const { total, inProgress, completed, remaining } = props.progressData;
	console.log('thisi si total', total);

	return (
		<>
			<Box sx={{ display: 'flex', justifyContent: 'center' }}>
				<Box
					sx={{
						height: 1,
						width: 120,
						marginTop: 0,
						marginBottom: 0,
						padding: 0
					}}
				>
					<Tooltip
						title={`${t('deviceTable.trackCompleted')} ${completed} / ${total}`}
						arrow
					>
						<BorderLinearProgress
							variant="determinate"
							value={(completed / total) * 100}
						/>
					</Tooltip>
				</Box>
			</Box>
		</>
	);
};

export default DeviceProgressBar;
