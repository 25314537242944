import { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import StatCard from './StatCard';

const statCardContent = [
	{
		title: 'Projects',
		total: 0,
		completed: 0,
		inProgress: 0,
		remaining: 0
	},
	{
		title: 'Tracks',
		total: 0,
		completed: 0,
		inProgress: 0,
		remaining: 0
	},
	{
		title: 'Devices',
		total: 0,
		completed: 0,
		inProgress: 0,
		remaining: 0
	}
];

const StatCardList = () => {
	const [statData, setStatData] = useState(statCardContent);

	useEffect(() => {
		const fetchData = async () => {
			const response = await fetch(
				`${
					process.env.REACT_APP_BASE_URL +
					'organizations/showMyOrganizationCurrentStatDetails'
				}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${localStorage.getItem('access_token')}`
					}
				}
			);
			const result = await response.json();

			const newStatData = [...statData];

			newStatData[0].total = result.body[0].total;
			newStatData[0].completed = result.body[0].complete;
			newStatData[0].inProgress = result.body[0].inProgress;
			newStatData[0].remaining = result.body[0].remaining;

			newStatData[1].total = result.body[1].total;
			newStatData[1].completed = result.body[1].complete;
			newStatData[1].inProgress = result.body[1].inProgress;
			newStatData[1].remaining = result.body[1].remaining;

			newStatData[2].total = result.body[2].total;
			newStatData[2].completed = result.body[2].complete;
			newStatData[2].inProgress = result.body[2].inProgress;
			newStatData[2].remaining = result.body[2].remaining;

			setStatData(newStatData);
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statCardContent]);

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-start',
					gap: '1.2rem'
				}}
			>
				{statCardContent.map((statCardContent, index) => (
					<StatCard key={index} statCardContent={statCardContent} />
				))}
			</Box>
		</>
	);
};

export default StatCardList;
