const ShowHandoverRequestApi = async () => {
	const accessToken = localStorage.getItem('access_token');
	console.log(accessToken);
	const res = await fetch(
		`${
			process.env.REACT_APP_BASE_URL +
			'handovers/pendingRequestToMyOrganization'
		}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${accessToken}`
			}
		}
	);

	const data = await res.json();
	console.log('Handover request data is ', data);
	return data;
};

export default ShowHandoverRequestApi;
