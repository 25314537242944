const ShowDeviceSummaryOfFollowingProject = async (projectId) => {
	const accessToken = localStorage.getItem('access_token');
	console.log(accessToken);
	console.log('kono ekta', projectId);
	const res = await fetch(
		`${
			process.env.REACT_APP_BASE_URL +
			'tracks/showAllTracksDeviceSummaryOfFollowingProject'
		}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({
				project_id: projectId
			})
		}
	);

	const data = await res.json();
	console.log(data);
	return data;
};

export default ShowDeviceSummaryOfFollowingProject;
