import React from 'react';
import styles from './TrackInfo.module.css';
import Stack from '@mui/material/Stack';
import { Typography } from '@mui/material';
import Box from '@mui/material/Box';
import FiberManualRecordIcon from '@mui/icons-material/FiberManualRecord';
import ChartInformationForTrack from './ChartInformationForTrack';
import { useTranslation } from 'react-i18next';

const TrackInfo = ({ trackSummary, title }) => {
	const { t } = useTranslation();
	const { completed, inProgress, remaining, total } = trackSummary;
	return (
		<>
			<Typography sx={{ fontSize: 16 }} color="text.secondary" gutterBottom>
				{title}
			</Typography>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'space-between',
					columnGap: '50px'
				}}
			>
				<Box>
					<Typography
						sx={{
							fontSize: 14,
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center'
						}}
						color="text.secondary"
					>
						{t('trackSummary.total')}
					</Typography>
					<Typography
						sx={{
							fontSize: 14,
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center'
						}}
						color="text.secondary"
					>
						{/* <span><FiberManualRecordIcon fontSize="small" /></span>  <span>Completed</span> */}
						<Stack direction="row" alignItems="center" gap={0.1}>
							<FiberManualRecordIcon
								fontSize="small"
								sx={{ color: '#415A77' }}
							/>
							<Typography
								sx={{
									fontSize: 14,
									display: 'flex',
									flexWrap: 'wrap',
									alignItems: 'center'
								}}
								color="text.secondary"
							>
								{t('trackSummary.completed')}
							</Typography>
						</Stack>
					</Typography>
					<Typography
						sx={{
							fontSize: 14,
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center'
						}}
						color="text.secondary"
					>
						<Stack direction="row" alignItems="center" gap={0.1}>
							<FiberManualRecordIcon
								fontSize="small"
								sx={{ color: '#718399' }}
							/>
							<Typography
								sx={{
									fontSize: 14,
									display: 'flex',
									flexWrap: 'wrap',
									alignItems: 'center'
								}}
								color="text.secondary"
							>
								{t('trackSummary.inProgress')}
							</Typography>
						</Stack>
					</Typography>
					<Typography
						sx={{
							fontSize: 14,
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center'
						}}
						color="text.secondary"
					>
						<Stack direction="row" alignItems="center" gap={0.1}>
							<FiberManualRecordIcon
								fontSize="small"
								sx={{ color: '#DEE4ED' }}
							/>
							<Typography
								sx={{
									fontSize: 14,
									display: 'flex',
									flexWrap: 'wrap',
									alignItems: 'center'
								}}
								color="text.secondary"
							>
								{t('trackSummary.remaining')}
							</Typography>
						</Stack>
					</Typography>
				</Box>

				<Box>
					<Typography
						sx={{
							fontSize: 14,
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center'
						}}
					>
						{total}
					</Typography>
					<Typography
						sx={{
							fontSize: 14,
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center'
						}}
						color="text.secondary"
					>
						{completed}
					</Typography>
					<Typography
						sx={{
							fontSize: 14,
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center'
						}}
						color="text.secondary"
					>
						{inProgress}
					</Typography>
					<Typography
						sx={{
							fontSize: 14,
							display: 'flex',
							flexWrap: 'wrap',
							alignItems: 'center'
						}}
						color="text.secondary"
					>
						{remaining}
					</Typography>
				</Box>

				<Box sx={{ height: 80, width: 80 }}>
					<ChartInformationForTrack trackSummary={trackSummary} />
				</Box>
			</Box>
		</>
	);
};

export default TrackInfo;
