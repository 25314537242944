import React, { useEffect } from 'react';
import styles from './UserDashboardBasedOnGroup.module.css';
import { useNavigate } from 'react-router-dom';
import UserTab from './UserTab';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

const UserDashboardBasedOnGroup = () => {
	const { t } = useTranslation();
	const navigate = useNavigate();
	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			navigate('/');
		}
	}, [navigate]);
	return (
		<>
			<div className={styles[`adminDashboard-wrapper`]}>
				<div></div>
				<div className={styles['adminDashboardTable-wraper']}>
					<UserTab />
				</div>
			</div>
		</>
	);
};

export default UserDashboardBasedOnGroup;
