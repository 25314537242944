import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import ShowAllActiveToolOfMyOrganization from './ShowAllActiveToolOfMyOrganization';
import { useTranslation } from 'react-i18next';

const getDatePlease = (D) => {
	return new Date(D).toLocaleString();
};

const ToolTable = () => {
	const { t } = useTranslation();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [permissionData, setPermissionData] = useState([]);

	console.log(permissionData);
	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	useEffect(() => {
		const FetchData = async () => {
			const response = await ShowAllActiveToolOfMyOrganization();
			console.log('permission all data', response.body);
			setPermissionData(response.body);
		};
		FetchData();
	}, [permissionData]);

	return (
		<>
			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<TableContainer sx={{ maxHeight: 345 }}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
									align="center"
								>
									{t('tool.toolName')}
								</TableCell>
								<TableCell
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
									align="center"
								>
									{t('tool.processingType')}
								</TableCell>

								{/* <TableCell
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
									align="center"
								>
									Action
								</TableCell> */}
							</TableRow>
						</TableHead>
						<TableBody>
							{permissionData &&
								permissionData.map((row) => (
									<TableRow
										key={row._id}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row" align="center">
											{row.name_en}
										</TableCell>

										<TableCell align="center">
											{row.processing_types.join(', ')}
										</TableCell>

										{/* <TableCell align="center">:</TableCell> */}
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					labelRowsPerPage={t('projectTable.rowsPerPage')}
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={permissionData.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</>
	);
};

export default ToolTable;
