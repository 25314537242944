import React, { useState, useEffect } from 'react';
import styles from './Device.module.css';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import changeCatalog from '../../Icons/changeCatalog.svg';
import downloadSummary from '../../Icons/downloadSummary.svg';
import publishButton from '../../Icons/publishButton.svg';
import createTrack from '../../Icons/createTrack.svg';
import csvIcon from '../../Icons/csvIcon.svg';
import pdfIcon from '../../Icons/pdfIcon.svg';
import ProgressBar from './ProgressBar';
import TrackInfo from './TrackInfo';
import DeviceInfo from './DeviceInfo';
import GlobalSearch from './GlobalSearch';
import progressClock from '../../Icons/progressClock.svg';
// import TrackTable from "./TrackTable";
// import ProjectInfo from "./ProjectInfo";
import { useNavigate, useLocation } from 'react-router-dom';
// import ShowFollowingProjectsDetailsByProjectId from "./TrackApi/ShowFollowingProjectsDetailsByProjectId";
// import ShowDeviceSummaryOfFollowingProject from "./TrackApi/ShowDeviceSummaryOfFollowingProject";
// import CreateTrackModal from "./TrackCreate/CreateTrackModal";
import Breadcrumbs from '@mui/material/Breadcrumbs';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Link from '@mui/material/Link';
import TrackInformation from './TrackInformation';
import ShowTrackDetailsByTrackId from './DeviceApi/ShowTrackDetailsByTrackId';
import ShowAllDeviceRecordSummaryOfFollowingTrack from './DeviceApi/ShowAllDeviceRecordSummaryOfFollowingTrack';
import RecordInfo from './RecordInfo';
import DeviceTable from './DeviceTable';
import DeviceModal from './CreateDevice/DeviceModal';
import QRCodeGenerator from './QRCodeGenerator';
import CsvFileDonwload from './CsvFileDownload';
import { useTranslation } from 'react-i18next';
import HandoverToAdmin from './HandoverToAdmin';
import CompletedHandover from './CompletedHandover';

const getDatePlease = (D) => {
	return new Date(D).toLocaleDateString();
};

const Device = () => {
	const { t } = useTranslation();
	const [anchorEl, setAnchorEl] = useState(null);
	const [projectData, setProjectData] = useState([]);
	const [trackInformation, setTrackInformation] = useState([]);
	const [trackSummary, setTrackSummary] = useState([]);
	const [deviceSummary, setDeviceSummary] = useState([]);
	const [recordSummary, setRecordSummary] = useState([]);
	const [searchItem, setSearchItem] = useState('');
	const open = Boolean(anchorEl);
	const { state } = useLocation();

	// const { trackId } = state ? state : localStorage.getItem('trackId');
	const { trackId, handoverId } = state;
	console.log('handover Id device ', handoverId);
	// console.log('excellency', localStorage.getItem('trackId'));
	console.log('excellency track id', trackId);

	const handleClick = (event) => {
		setAnchorEl(event.currentTarget);
	};
	const handleClose = () => {
		setAnchorEl(null);
	};
	useEffect(() => {
		const FetchTrackData = async () => {
			const allData = await ShowTrackDetailsByTrackId(trackId);
			console.log('this is all project details', allData);

			if (allData.success === true) {
				setProjectData(allData.body);
				setDeviceSummary(allData.body.device_summary);
				console.log('data aise ');
			}
		};
		FetchTrackData();
	}, [trackId]);

	useEffect(() => {
		const FetchRecordSummaryData = async () => {
			const allData = await ShowAllDeviceRecordSummaryOfFollowingTrack(trackId);
			console.log('this is all device summary details', allData);

			if (allData.success === true) {
				setRecordSummary(allData.body.record_summary);
				console.log('data aise ');
			}
		};
		FetchRecordSummaryData();
	}, [trackId]);

	return (
		<>
			<div role="presentation" className={styles['layoutForBreadcrumbs']}>
				<Breadcrumbs
					aria-label="breadcrumb"
					separator={<NavigateNextIcon fontSize="small" />}
				>
					{/* <Link underline="hover" color="inherit" href="/userDashboard">
						User Dashboard
					</Link>
					<Link underline="hover" color="inherit" href="/trackBoard">
						Track Board
					</Link> */}
					<Link underline="hover" color="inherit" href="/userDashboard">
						{t('breadCums.projectBoard')}
					</Link>
					<Typography color="text.primary">
						{t('breadCums.trackBoard')}
					</Typography>
					<Typography color="text.primary">
						{t('breadCums.deviceBoard')}
					</Typography>
				</Breadcrumbs>
			</div>
			<Card sx={{ minWidth: 400 }}>
				<CardContent>
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Box>
							<Typography
								sx={{ fontSize: 18 }}
								color="text.secondary"
								gutterBottom
							>
								{t('trackInformation.name')}
							</Typography>
						</Box>
						<Box sx={{ color: '#11887f' }}>
							<img
								src={progressClock}
								alt="progressClockIcon"
								width="16"
								height="16"
							/>{' '}
							Running
						</Box>
					</Box>
					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Box>
							<Typography sx={{ fontSize: 24, fontWeight: 'bold' }}>
								{projectData && projectData.track_tag
									? projectData.track_tag.tag_number
									: null}
							</Typography>
						</Box>
						<Box>
							<ProgressBar trackId={trackId} />
						</Box>
					</Box>

					<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
						<Box sx={{ marginTop: '1rem', width: 290, height: 112 }}>
							{/* <ProjectInfo projectData={projectData} /> */}
							<TrackInformation projectData={projectData} />
						</Box>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								width: 700,
								height: 110
							}}
						>
							<Box>
								{deviceSummary ? (
									<TrackInfo
										trackSummary={deviceSummary}
										title={t('deviceSummary.title')}
									/>
								) : null}
							</Box>
							<Box>
								{recordSummary ? (
									<RecordInfo
										recordSummary={recordSummary}
										title={t('recordSummary.title')}
									/>
								) : null}
							</Box>
						</Box>
					</Box>
				</CardContent>
				<CardActions>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'space-between',
							marginTop: 0,
							padding: '10px',
							width: '100%'
						}}
					>
						<Box
							sx={{
								display: 'flex',
								justifyContent: 'space-between',
								columnGap: '10px'
							}}
						>
							<Box>
								{/* <Button
									variant="outlined"
									startIcon={
										<img
											src={downloadSummary}
											alt="downloadSummary"
											width="16"
											height="16"
										/>
									}
									sx={{ borderRadius: '5px', color: '#415A77 ' }}
								>
									CSV File
								</Button> */}
								<CsvFileDonwload trackId={trackId} />
							</Box>
							<Box>
								{/* <Button
									variant="outlined"
									startIcon={
										<img
											src={downloadSummary}
											alt="downloadSummary"
											width="16"
											height="16"
										/>
									}
									sx={{ borderRadius: '5px', color: '#415A77 ' }}
								>
									QR Code
								</Button> */}
								<QRCodeGenerator trackId={trackId} />
							</Box>
						</Box>
						<Box>
							{deviceSummary.total === deviceSummary.completed &&
							projectData.status === 'handed over' ? (
								<CompletedHandover handoverId={handoverId} />
							) : deviceSummary.total !== deviceSummary.completed &&
							  projectData.status ===
									'handed over' ? null : projectData.status ===
							  'completed' ? null : projectData.status === 'ready' ? null : (
								<HandoverToAdmin trackId={trackId} />
							)}
						</Box>
					</Box>
				</CardActions>
			</Card>

			<Paper
				component="form"
				sx={{
					marginTop: '10px',
					marginBottom: '40px',
					align: 'right',
					alignItems: 'center',
					width: '100%'
				}}
				elevation={2}
			>
				{/* <GlobalSearch
          searchItem={searchItem}
          setSearchItem={setSearchItem}
        />
         <CreateTrackModal /> */}
				<Box
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
						padding: '20px'
					}}
				>
					<Box>
						<GlobalSearch
							searchItem={searchItem}
							setSearchItem={setSearchItem}
						/>
					</Box>
					<Box>
						{/* <Button
              variant="outlined"
              sx={{ borderRadius: "5px", color: "#415A77" }}
              startIcon={
                <img
                  src={createTrack}
                  alt="CreateTrack"
                  width="16"
                  height="16"
                />
              }
            >
              Create Device
            </Button> */}
						{projectData.status === 'handed over' ? null : (
							<DeviceModal trackId={trackId} />
						)}
					</Box>
				</Box>
				<Box>
					<Paper
						component="form"
						sx={{
							marginTop: '0px',
							marginLeft: '20px',
							marginRight: '20px',
							marginBottom: '40px',
							align: 'right',
							alignItems: 'center',
							flex: 1
						}}
						elevation={2}
					>
						<DeviceTable trackId={trackId} searchItem={searchItem} />
					</Paper>
				</Box>
			</Paper>
		</>
	);
};

export default Device;
