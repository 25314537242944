import React, { useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import styles from './ProgressBar.module.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import LinearProgress, {
	linearProgressClasses
} from '@mui/material/LinearProgress';
import ShowTrackStatusByTrackId from '../DeviceApi/ShowTrackStatusByTrackId';
import { useTranslation } from 'react-i18next';

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
	height: 20,
	borderRadius: 10,
	[`&.${linearProgressClasses.colorPrimary}`]: {
		backgroundColor:
			theme.palette.grey[theme.palette.mode === 'light' ? 200 : 800]
	},
	[`& .${linearProgressClasses.bar}`]: {
		borderRadius: 5,
		backgroundColor: theme.palette.mode === 'light' ? '#415A77' : '#308fe8'
	}
}));

const ProgressBar = ({ trackId }) => {
	const { t } = useTranslation();
	const [trackData, setTrackData] = useState([]);

	useEffect(() => {
		const FetchProjectData = async () => {
			const allData = await ShowTrackStatusByTrackId(trackId);
			console.log('this is all project details', allData);

			if (allData.success === true) {
				setTrackData(allData.body.record_summary);
			}
		};
		FetchProjectData();
	}, [trackId]);

	return (
		<>
			<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
				<Box
					sx={{
						height: 1,
						width: 300,
						marginTop: 0,
						marginBottom: 0,
						padding: 0
					}}
				>
					<BorderLinearProgress
						variant="determinate"
						value={(trackData.completed / trackData.total) * 100}
					/>
					<p className={styles['progress-bar-text']}>
						{t('recordStatistics.recordCompleted')}{' '}
						<span style={{ fontWeight: 'bold' }}>
							{trackData.completed} / {trackData.total}
						</span>
					</p>
				</Box>
				<Box sx={{ minWidth: 35 }}>
					<Typography
						variant="body2"
						color="text.secondary"
						sx={{ textAlign: 'right' }}
					>
						{trackData.total !== 0
							? Math.floor((trackData.completed / trackData.total) * 100) + '%'
							: null}
					</Typography>
				</Box>
			</Box>
		</>
	);
};

export default ProgressBar;
