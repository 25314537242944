import React, { useState, useEffect } from 'react';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import styles from './AdminUserTable.module.css';
import FetchAllUserApiData from '../../../UserComponents/CreateUser/CreateUserApi/FetchAllUserApiData';
import { useTranslation } from 'react-i18next';

const getDatePlease = (D) => {
	return new Date(D).toLocaleString();
};

const AdminUserTable = () => {
	const { t } = useTranslation();
	const [page, setPage] = useState(0);
	const [rowsPerPage, setRowsPerPage] = useState(10);
	const [userData, setUserData] = useState([]);

	const handleChangePage = (event, newPage) => {
		setPage(newPage);
	};

	const handleChangeRowsPerPage = (event) => {
		setRowsPerPage(+event.target.value);
		setPage(0);
	};

	useEffect(() => {
		const FetchData = async () => {
			const response = await FetchAllUserApiData();
			console.log('organizatoin all data', response.body);
			setUserData(response.body);
		};
		FetchData();
	}, [userData]);

	return (
		<>
			<Paper sx={{ width: '100%', overflow: 'hidden' }}>
				<TableContainer sx={{ maxHeight: 345 }}>
					<Table stickyHeader aria-label="sticky table">
						<TableHead>
							<TableRow>
								<TableCell
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
									align="center"
								>
									{t('user.firstName')}
								</TableCell>
								<TableCell
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
									align="center"
								>
									{t('user.lastName')}
								</TableCell>
								<TableCell
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
									align="center"
								>
									{t('user.email')}
								</TableCell>
								<TableCell
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
									align="center"
								>
									{t('user.organizationName')}
								</TableCell>
								<TableCell
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
									align="center"
								>
									{t('user.createdAt')}
								</TableCell>
								<TableCell
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
									align="center"
								>
									{t('user.updatedAt')}
								</TableCell>
								<TableCell
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
									align="center"
								>
									{t('user.status')}
								</TableCell>
								{/* <TableCell
									style={{
										backgroundColor: ' rgb(203, 200, 200)',
										fontWeight: 'bold'
									}}
									align="center"
								>
									Action
								</TableCell> */}
							</TableRow>
						</TableHead>
						<TableBody>
							{userData &&
								userData.map((row) => (
									<TableRow
										key={row._id}
										sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
									>
										<TableCell component="th" scope="row" align="center">
											{row.first_name}
										</TableCell>
										<TableCell align="center">{row.last_name}</TableCell>
										<TableCell align="center">{row.email}</TableCell>
										<TableCell align="center">
											{row.organization.name_en}
										</TableCell>
										<TableCell align="center">
											{getDatePlease(row.createdAt)}
										</TableCell>
										<TableCell align="center">
											{getDatePlease(row.updatedAt)}
										</TableCell>
										<TableCell
											align="center"
											style={{
												color:
													(row.active === true && 'green') ||
													(row.active === false && 'red')
											}}
										>
											{row.active ? 'Active' : 'Inactive'}
										</TableCell>
										{/* <TableCell align="center">
											<Stack
												justifyContent="center"
												spacing={2}
												direction="row"
											>
												<Button variant="contained">Update</Button>
												<Button variant="outlined" color="error">
													Block
												</Button>
											</Stack>
										</TableCell> */}
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
				<TablePagination
					labelRowsPerPage={t('projectTable.rowsPerPage')}
					rowsPerPageOptions={[10, 25, 100]}
					component="div"
					count={userData.length}
					rowsPerPage={rowsPerPage}
					page={page}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			</Paper>
		</>
	);
};

export default AdminUserTable;
