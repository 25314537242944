const FetchUnoccupiedTagsApi = async () => {
	const accessToken = localStorage.getItem('access_token');
	console.log(accessToken);
	//alert(JSON.stringify(values, null, 2));
	// e.preventDefault()
	const res = await fetch(
		`${process.env.REACT_APP_BASE_URL + 'trackTags/askingSingleTrackTag'}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${accessToken}`
			},
			body: JSON.stringify({})
		}
	);

	const data = await res.json();
	console.log(data);
	return data;
};

export default FetchUnoccupiedTagsApi;
