import React, { useEffect } from 'react';
import styles from './QRCodeReader.module.css';
import { useNavigate } from 'react-router-dom';
import QRCodeScanner from './QRCodeScanner';

const QRCodeReader = () => {
	const navigate = useNavigate();
	useEffect(() => {
		if (!localStorage.getItem('access_token')) {
			navigate('/');
		}
	}, [navigate]);
	return (
		<>
			<div className={styles[`adminDashboard-wrapper`]}>
				<div></div>
				<div className={styles['adminDashboardTable-wraper']}>
					<QRCodeScanner />
				</div>
			</div>
		</>
	);
};

export default QRCodeReader;
