import { Button, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import { useState } from 'react';
import styles from './CreateOrganization.module.css';
import CreateOrganizationApi from './OrganizationApi/CreateOrganizationApiHandler';
import { useTranslation } from 'react-i18next';

const CreateOrganization = ({ setNextButton }) => {
	const { t } = useTranslation();
	const [isValid, setIsValid] = useState(false);
	const [isOrganizationCreate, setIsOrganizationCreate] = useState(false);
	const [isError, setIsError] = useState(false);
	const [status, setStatus] = useState(false);

	const initialValues = {
		organizationInitial: '',
		organizationNameEn: '',
		organizationNameJp: ''
	};

	const validate = (values) => {
		setIsValid(true);
		let errors = {};
		if (!values.organizationInitial) {
			errors.organizationInitial = `${t(
				'createOrganization.organizationInitialRequired'
			)}`;
			setIsValid(false);
		}

		if (!values.organizationNameEn) {
			errors.organizationNameEn = `${t(
				'createOrganization.organizationNameEnRequired'
			)}`;
			setIsValid(false);
		}
		if (!values.organizationNameJp) {
			errors.organizationNameJp = `${t(
				'createOrganization.organizationNameJpRequired'
			)}`;
			setIsValid(false);
		}

		return errors;
	};

	const onSubmit = async (values) => {
		console.log(values);
		setStatus(true);
		const res = await CreateOrganizationApi(values);
		console.log(res);
		if (res.success === true) {
			console.log('organization create successfull');
			setNextButton(true);
			setIsOrganizationCreate(true);
			setIsError(false);
		} else {
			console.log('fail');
			setNextButton(true);
			setIsError(true);
			setIsOrganizationCreate(false);
		}
	};

	return (
		<>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validate={validate}
			>
				{(formik) => {
					return (
						<>
							{isOrganizationCreate && (
								<div className={styles['password-reset-email-success']}>
									{t('createOrganization.success')}
								</div>
							)}
							{isError && (
								<div className={styles['password-reset-email-error']}>
									{t('createOrganization.error')}
								</div>
							)}

							<h3>{t('createOrganization.title')}</h3>
							<div className={styles['login-input-field']}>
								<FormControl className={styles['login-form']} fullWidth>
									<TextField
										id="organizationInitial"
										name="organizationInitial"
										label={t('createOrganization.organizationInitial')}
										variant="outlined"
										size="small"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.organizationInitial}
										error={
											formik.touched.organizationInitial &&
											Boolean(formik.errors.organizationInitial)
										}
										type="text"
									/>
									<span className={styles['error']}>
										{formik.touched.organizationInitial &&
										formik.errors.organizationInitial ? (
											<div>{formik.errors.organizationInitial}</div>
										) : null}
									</span>
									<br />
								</FormControl>
								<FormControl className={styles['login-form']} fullWidth>
									<TextField
										id="organizationNameEn"
										name="organizationNameEn"
										label={t('createOrganization.organizationNameEn')}
										variant="outlined"
										size="small"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.organizationNameEn}
										error={
											formik.touched.organizationNameEn &&
											Boolean(formik.errors.organizationNameEn)
										}
										type="text"
									/>
									<span className={styles['error']}>
										{formik.touched.organizationNameEn &&
										formik.errors.organizationNameEn ? (
											<div>{formik.errors.organizationNameEn}</div>
										) : null}
									</span>
									<br />
								</FormControl>
								<FormControl className={styles['login-form']} fullWidth>
									<TextField
										id="organizationNameJp"
										name="organizationNameJp"
										label={t('createOrganization.organizationNameJp')}
										variant="outlined"
										size="small"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.organizationNameJp}
										error={
											formik.touched.organizationNameJp &&
											Boolean(formik.errors.organizationNameJp)
										}
										type="text"
									/>
									<span className={styles['error']}>
										{formik.touched.organizationNameJp &&
										formik.errors.organizationNameJp ? (
											<div>{formik.errors.organizationNameJp}</div>
										) : null}
									</span>
									<br />
								</FormControl>
							</div>
							<Stack justifyContent="center">
								<Button
									type="Submit"
									variant="contained"
									onClick={formik.handleSubmit}
								>
									{t('createOrganization.create')}
								</Button>
							</Stack>
						</>
					);
				}}
			</Formik>
		</>
	);
};

export default CreateOrganization;
