import React, { useEffect, useState } from 'react';
import Button from '@mui/material/Button';
import publishButton from '../../../Icons/publishButton.svg';
import HandoverToAdminApi from './handovertoAdminApi';
import Box from '@mui/material/Box';
import Snackbar from '@mui/material/Snackbar';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Alert from '@mui/material/Alert';
import { useTranslation } from 'react-i18next';

const HandoverToAdmin = (props) => {
	const { t } = useTranslation();
	const { trackId } = props;
	const [isProjectCreate, setIsProjectCreate] = useState(false);
	const [isError, setIsError] = useState(false);
	const [handoverData, setHandoverData] = useState();
	const [open, setOpen] = useState(false);

	const handleClose = (event, reason) => {
		if (reason === 'clickaway') {
			return;
		}

		setOpen(false);
	};

	const action = (
		<>
			<Button color="secondary" size="small" onClick={handleClose}>
				UNDO
			</Button>
			<IconButton
				size="small"
				aria-label="close"
				color="inherit"
				onClick={handleClose}
			>
				<CloseIcon fontSize="small" />
			</IconButton>
		</>
	);

	const handoverFuntion = async () => {
		const allData = await HandoverToAdminApi(trackId);
		console.log('handover details', allData);

		if (allData.success === true) {
			setHandoverData(allData.body);
			setIsProjectCreate(true);
			setIsError(false);
			setOpen(true);
			console.log('data aise ');
		} else {
			setIsError(true);
			setIsProjectCreate(false);
			setOpen(true);
		}
	};
	return (
		<>
			{isProjectCreate && (
				<Box>
					<Snackbar
						open={open}
						autoHideDuration={5000}
						onClose={handleClose}
						message="Successfully send handover"
						action={action}
					/>
				</Box>
			)}
			{isError && (
				<Box>
					<Snackbar
						open={open}
						autoHideDuration={5000}
						onClose={handleClose}
						message="Somethign went wrong !!!"
						action={action}
					/>
				</Box>
			)}
			<Button
				variant="contained"
				sx={{
					color: 'white',
					backgroundColor: '#415A77',
					borderRadius: '5px'
				}}
				startIcon={
					<img src={publishButton} alt="publishButton" width="16" height="16" />
				}
				onClick={handoverFuntion}
			>
				{t('acceptedHandover.handover')}
			</Button>
		</>
	);
};

export default HandoverToAdmin;
