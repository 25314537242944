import { Box } from '@mui/material';
import TopCard from './TopCard';
import {
	Assignment,
	Devices,
	FormatListBulleted,
	PeopleOutline
} from '@mui/icons-material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

const topCardContents = [
	{
		count: 0,
		title: 'Groups',
		icon: <PeopleOutline sx={{ fontSize: '36px' }} />
	},
	{
		count: 0,
		title: 'Projects',
		icon: <FormatListBulleted sx={{ fontSize: '36px' }} />
	},
	{
		count: 0,
		title: 'Tracks',
		icon: <Assignment sx={{ fontSize: '36px' }} />
	},
	{
		count: 0,
		title: 'Devices',
		icon: <Devices sx={{ fontSize: '36px' }} />
	}
];

const TopCardList = () => {
	const { t } = useTranslation();
	const [statData, setStatData] = useState(topCardContents);

	useEffect(() => {
		const fetchData = async () => {
			const response = await fetch(
				`${
					process.env.REACT_APP_BASE_URL +
					'organizations/showMyOrganizationCurrentStat'
				}`,
				{
					method: 'POST',
					headers: {
						'Content-Type': 'application/json',
						Authorization: `Bearer ${localStorage.getItem('access_token')}`
					}
				}
			);
			const result = await response.json();

			const newStatData = [...statData];

			newStatData[0].count = result.body[0].count;
			newStatData[1].count = result.body[1].count;
			newStatData[2].count = result.body[2].count;
			newStatData[3].count = result.body[3].count;

			setStatData(newStatData);
		};
		fetchData();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [topCardContents]);

	return (
		<>
			<Box
				sx={{
					display: 'flex',
					justifyContent: 'flex-start',
					flexWrap: 'wrap',
					gap: '1.2rem'
				}}
			>
				{topCardContents.map((topCardContent, index) => (
					<TopCard key={index} topCardContent={topCardContent} />
				))}
			</Box>
		</>
	);
};

export default TopCardList;
