const SendOrganizationHandoverByAmin = async (values, props) => {
	const accessToken = localStorage.getItem('access_token');

	const res = await fetch(
		`${process.env.REACT_APP_BASE_URL + 'handovers/approveHandoverByAdmin'}`,
		{
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Accept: 'application/json',
				Authorization: `Bearer ${accessToken}`
			},

			body: JSON.stringify({
				Previous_handover_id: props.Previous_handover_id,
				request_to: values.groupName,
				handover_id: props.handover_id
			})
		}
	);

	const data = await res.json();
	return data;
};

export default SendOrganizationHandoverByAmin;
