import { Button, Stack } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import TextField from '@mui/material/TextField';
import { Formik } from 'formik';
import { useState, useEffect } from 'react';
import styles from './CreateProject.module.css';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import TextareaAutosize from '@mui/material/TextareaAutosize';
import FetchAllGroupApiDataHandler from './CreateProjectApi/FetchAllGroupApiDataHandler';
import CreateProjectApiHandler from './CreateProjectApi/CreateProjectApiHandler';
import { Navigate, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { add } from '../../../Store/cartSlice';
import { fetchProjects } from '../../../Store/ProjectSlice';
import ShowAllActiveProjectOfFollowingGroup from '../ProjectTable/ShowAllActiveProjectOfFollowingGroup';
import { useTranslation } from 'react-i18next';

const CreateProject = (props) => {
	const { t } = useTranslation();
	const { clickGroupName, clickGroupId } = props;
	const dispatch = useDispatch();
	const navigate = useNavigate();
	const [isValid, setIsValid] = useState(false);
	const [isProjectCreate, setIsProjectCreate] = useState(false);
	const [isError, setIsError] = useState(false);
	const [status, setStatus] = useState(false);
	const [allGroupData, setAllGroupData] = useState([]);
	const [dateValue, setDateValue] = useState(null);
	const [initialDate, setInitialDate] = useState('1970-01-01T00:00:00.000Z');

	// console.log('date value is ', dateValue)
	const initialValues = {
		projectNameEn: '',
		projectNameJp: '',
		date: null
	};

	const validate = (values) => {
		setIsValid(true);
		let errors = {};

		const dateRegex =
			/^(0[1-9]|[12][0-9]|3[01])[- /.](0[1-9]|1[012])[- /.](19|20)\d\d$/; // MM/DD/YYYY format
		// if (!values.groupName) {
		//   errors.groupName = "Group name is required";
		//   setIsValid(false);
		// }

		if (!values.projectNameEn) {
			errors.projectNameEn = `${t('project.nameEnglishRequired')}`;
			setIsValid(false);
		}
		if (!values.projectNameJp) {
			errors.projectNameJp = `${t('project.nameJapaneseRequired')}`;
			setIsValid(false);
		}
		if (!values.date) {
			errors.date = `${t('project.deadlineRequired')}`;
			setIsValid(false);
			// if (values.date < initialDate) {
			//   errors.date = "Date format is not valid";
			//   setIsError(false)

			// }
		}

		// else {
		//   errors.projectDeadline = " ";
		//   setIsValid(false);
		// }

		return errors;
	};

	useEffect(() => {
		const FetchOrganizationData = async () => {
			const allData = await FetchAllGroupApiDataHandler();
			console.log(allData.body);
			if (allData.success === true) {
				setAllGroupData(await allData.body);
				console.log('data aise ');
			}
		};
		FetchOrganizationData();
	}, []);
	const onSubmit = async (values, { resetForm }) => {
		console.log('project value', values);
		setStatus(true);
		const res = await CreateProjectApiHandler(values, clickGroupId);
		console.log('create project', res);
		if (res.success === true) {
			const allProject = await ShowAllActiveProjectOfFollowingGroup(
				clickGroupId
			);
			dispatch(add(res.body));
			dispatch(fetchProjects(res.body));
			setIsProjectCreate(true);
			setIsError(false);
			resetForm();
		} else {
			setIsError(true);
			setIsProjectCreate(false);
		}
	};

	return (
		<>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validate={validate}
			>
				{(formik) => {
					return (
						<>
							{isProjectCreate && (
								<div className={styles['password-reset-email-success']}>
									{t('project.success')}
								</div>
							)}
							{isError && (
								<div className={styles['password-reset-email-error']}>
									{t('project.error')}
								</div>
							)}

							<h3 className={styles['group-header-center']}>
								{t('project.title')}
							</h3>
							{/* <h6>{props.clickGroupId}</h6>
              <h6>{props.clickGroupName}</h6> */}
							<Typography color="text.secondary">
								{t('project.groupName')}: <b>{clickGroupName}</b>
							</Typography>
							<br />
							<div className={styles['login-input-field']}>
								<FormControl className={styles['login-form']} fullWidth>
									<TextField
										id="projectNameEn"
										name="projectNameEn"
										label={t('project.nameEnglish')}
										variant="outlined"
										size="small"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.projectNameEn}
										error={
											formik.touched.projectNameEn &&
											Boolean(formik.errors.projectNameEn)
										}
										type="text"
									/>
									<span className={styles['error']}>
										{formik.touched.projectNameEn &&
										formik.errors.projectNameEn ? (
											<div>{formik.errors.projectNameEn}</div>
										) : null}
									</span>
									<br />
								</FormControl>
								<FormControl className={styles['login-form']} fullWidth>
									<TextField
										id="projectNameJp"
										name="projectNameJp"
										label={t('project.nameJapanese')}
										variant="outlined"
										size="small"
										onChange={formik.handleChange}
										onBlur={formik.handleBlur}
										value={formik.values.projectNameJp}
										error={
											formik.touched.projectNameJp &&
											Boolean(formik.errors.projectNameJp)
										}
										type="text"
									/>
									<span className={styles['error']}>
										{formik.touched.projectNameJp &&
										formik.errors.projectNameJp ? (
											<div>{formik.errors.projectNameJp}</div>
										) : null}
									</span>
									<br />
								</FormControl>
								<FormControl className={styles['login-form']} fullWidth>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker
											id="date"
											name="date"
											label={t('project.deadline')}
											inputFormat="MM/dd/yyyy"
											value={formik.values.date}
											onChange={(value) => formik.setFieldValue('date', value)}
											onBlur={formik.handleBlur('date')}
											renderInput={(params) => <TextField {...params} />}
											error={formik.touched.date && Boolean(formik.errors.date)}
											helperText={formik.touched.date && formik.errors.date}
											disablePast
										/>
									</LocalizationProvider>

									<span className={styles['error']}>
										{formik.touched.date && formik.errors.date ? (
											<div>{formik.errors.date}</div>
										) : null}
									</span>
									<br />
								</FormControl>
							</div>
							<Stack justifyContent="center">
								<Button
									type="Submit"
									sx={{
										color: 'white',
										backgroundColor: '#415a77',
										borderRadius: '5px',
										'&:hover': {
											color: 'white',
											backgroundColor: '#415a77'
										}
									}}
									onClick={formik.handleSubmit}
								>
									{t('project.create')}
								</Button>
							</Stack>
						</>
					);
				}}
			</Formik>
		</>
	);
};

export default CreateProject;
