import { Box, Card, CardContent, Typography } from '@mui/material';

// const TopCard = ({ count, title, icon }) => {
const TopCard = ({ topCardContent }) => {
	return (
		<>
			<Card
				variant="outlined"
				sx={{
					width: '186px',
					border: '1px solid #dee4ed9b',
					borderRadius: '15px'
				}}
			>
				<CardContent>
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '0.2rem',
							color: '#718399'
						}}
					>
						{topCardContent.icon}
						<Box sx={{ ml: 1 }}>
							<Typography
								sx={{
									fontSize: '36px',
									fontWeight: 'bold'
								}}
								mb={-1}
							>
								{topCardContent.count}
							</Typography>
							<Typography
								sx={{
									fontSize: '20px'
								}}
							>
								{topCardContent.title}
							</Typography>
						</Box>
					</Box>
				</CardContent>
			</Card>
		</>
	);
};

export default TopCard;
