import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { Form, Formik } from 'formik';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import LoginApi from '../LoginApiHandler/LoginApi';
// import SyncLoader from 'react-spinners/SyncLoader';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';
import logo from '../../../../assets/ettms_logo.svg';
import Switch from '@mui/material/Switch';
import Link from '@mui/material/Link';
import { alpha, styled } from '@mui/material/styles';
import { Alert, Snackbar } from '@mui/material';

const RememberSwitch = styled(Switch)(({ theme }) => ({
	'& .MuiSwitch-switchBase.Mui-checked': {
		color: '#415A77',
		'&:hover': {
			backgroundColor: alpha('#415A77', theme.palette.action.hoverOpacity)
		}
	},
	'& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
		backgroundColor: '#415A77'
	}
}));

const label = { inputProps: { 'aria-label': 'Color switch demo' } };
const LoginForm = () => {
	// const [isValid, setIsValid] = useState(false);
	// const [isLoading, setIsLoading] = useState(false);
	// const [isLoginSuccess, setIsLoginSuccess] = useState(false);
	const [isError, setIsError] = useState(false);
	const [checked, setChecked] = useState(false);
	const navigate = useNavigate();

	const initialValues = {
		email: '',
		password: ''
	};

	const validate = (values) => {
		// setIsValid(true);
		let errors = {};
		if (!values.email) {
			errors.email = 'Email is required';
			// setIsValid(false);
		} else if (
			!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
		) {
			errors.email = 'Email address mismatch';
			// setIsValid(false);
		}

		if (!values.password) {
			errors.password = 'Password is required';
			// setIsValid(false);
		}

		return errors;
	};

	const onSubmit = async (values) => {
		localStorage.removeItem('access_token');
		const res = await LoginApi(values);
		// console.log('login res', res);
		// console.log('Say Hello!');
		// console.log(res.success);

		if (res.success === true) {
			localStorage.setItem('access_token', res.access_token);
			localStorage.setItem(
				'permissions',
				JSON.stringify(res.body.permission.permissions)
			);
			localStorage.setItem(
				'user_name',
				JSON.stringify(res.body.first_name + ' ' + res.body.last_name)
			);
			localStorage.setItem(
				'organization_name_en',
				JSON.stringify(res.body.organization.name_en)
			);
			// setIsLoginSuccess(true);
			setIsError(false);

			if (res.body.permission.permissions.includes(3)) {
				// set user role
				localStorage.setItem('user_role', 'superAdmin');
				// console.log('its Super Admin here!');
				navigate('/superAdminDashboard');
			} else if (res.body.permission.permissions.includes(10)) {
				localStorage.setItem('user_role', 'admin');
				// console.log('its Admin here!');
				navigate('/AdminDashboard');
			} else {
				localStorage.setItem('user_role', 'user');
				navigate('/UserDashboard');
			}
		} else {
			// setIsLoginSuccess(false);
			setIsError(true);
		}
	};

	return (
		<>
			<Formik
				initialValues={initialValues}
				onSubmit={onSubmit}
				validate={validate}
			>
				{(formik) => {
					return (
						<Form>
							<Card
								sx={{
									border: '1px solid #dee4ed',
									borderRadius: 3,
									width: 350,
									height: 'wrap',
									paddingX: 2,
									boxShadow: 'none'
								}}
							>
								<CardContent>
									<Typography
										sx={{
											marginY: '10%',
											textAlign: 'center'
										}}
									>
										<img src={logo} alt="ETTMS" />
									</Typography>
									<Box
										sx={{
											display: 'flex',
											justifyContent: 'space-between',
											flexDirection: 'column',
											gap: '15px'
										}}
									>
										<Box
											sx={{
												height: '45px',
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'flex-start'
											}}
										>
											<TextField
												id="email"
												label="Email"
												type="email"
												size="small"
												variant="outlined"
												autoComplete="current-email"
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.email}
												error={
													formik.touched.email && Boolean(formik.errors.email)
												}
											/>
											{formik.touched.email && formik.errors.email ? (
												<Typography
													sx={{
														textAlign: 'end',
														fontSize: '10px',
														textSizeAdjust: '10px',
														color: 'red'
													}}
												>
													{formik.errors.email}
												</Typography>
											) : null}
										</Box>
										<Box
											sx={{
												height: '45px',
												display: 'flex',
												flexDirection: 'column',
												justifyContent: 'flex-start'
											}}
										>
											<TextField
												id="password"
												label="Password"
												type="password"
												autoComplete="current-password"
												variant="outlined"
												size="small"
												onChange={formik.handleChange}
												onBlur={formik.handleBlur}
												value={formik.values.password}
												error={
													formik.touched.password &&
													Boolean(formik.errors.password)
												}
											/>
											{formik.touched.password && formik.errors.password ? (
												<Typography
													sx={{
														textAlign: 'end',
														fontSize: '10px',
														textSizeAdjust: '10px',
														color: 'red'
													}}
												>
													{formik.errors.password}
												</Typography>
											) : null}
										</Box>

										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												flexDirection: 'row',
												justifyContent: 'space-between',
												fontSize: '12px',
												lineHeight: '15px'
											}}
										>
											<Box
												sx={{
													display: 'flex',
													alignItems: 'center',
													flexDirection: 'row',
													justifyContent: 'space-between',
													minWidth: '40%'
												}}
											>
												<RememberSwitch
													{...label}
													checked={checked}
													onChange={(event) => setChecked(event.target.checked)}
												/>{' '}
												Remember me
											</Box>

											<Link href="#" color="inherit" underline="none">
												Forget password?
											</Link>
										</Box>
									</Box>
								</CardContent>
								<CardActions>
									<Box
										sx={{
											minWidth: '100%',
											display: 'flex',
											flexDirection: 'column',
											justifyContent: 'flex-end'
										}}
									>
										<Button
											id="login"
											type="submit"
											// disabled={!isValid && !isLoading}
											error={
												formik.touched.password &&
												Boolean(formik.errors.password)
											}
											sx={{
												minHeight: '100%',
												minWidth: '100%',
												backgroundColor: '#415A77',
												color: 'white',

												'&:hover': {
													backgroundColor: '#364057'
												},
												'&:disabled': {
													backgroundColor: '#E2E5E9',
													color: '#415A77'
												}
											}}
											variant="outlined"
											size="large"
										>
											{/* {isLoading ? (
												<SyncLoader />
											) : ( */}
											<Typography>Log in</Typography>
											{/* )} */}
										</Button>

										<Typography
											sx={{
												marginY: '2%',
												fontSize: '12px',
												textAlign: 'center',
												color: '#718399'
											}}
										>
											Don't have an account? Contact admin.
										</Typography>
									</Box>
								</CardActions>
							</Card>
						</Form>
					);
				}}
			</Formik>
			<Snackbar
				anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
				open={isError}
				autoHideDuration={3000}
				onClose={() => setIsError(false)}
				// message={'Invalid Email or Password'}
			>
				<Alert severity="error" sx={{ width: '100%' }}>
					Invalid Email or Password
				</Alert>
			</Snackbar>
		</>
	);
};

export default LoginForm;
