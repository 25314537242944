import * as React from 'react';
import { useState, useEffect } from 'react';
import Box from '@mui/material/Box';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import styles from './DeviceStepper.module.css';
import CreateDeviceID from '../CreateDeviceID';
import CreateDeviceModel from '../CreateDeviceModel';
import { useTranslation } from 'react-i18next';

export default function DeviceStepper({ trackId }) {
	const { t } = useTranslation();
	const [activeStep, setActiveStep] = useState(0);
	const [skipped, setSkipped] = useState(new Set());
	const [nextButton, setNextButton] = useState(false);
	const [nextFinishButton, setNextFinishButton] = useState(false);
	const [deviceTypeValue, setDeviceTypeValue] = useState([]);

	const steps = [
		`${t('createDevice.title')}`,
		`${t('createDevice.createDeviceModel')}`
	];

	const isStepOptional = (step) => {
		return step === 1;
	};

	const isStepSkipped = (step) => {
		return skipped.has(step);
	};

	const handleNext = () => {
		let newSkipped = skipped;
		if (isStepSkipped(activeStep)) {
			newSkipped = new Set(newSkipped.values());
			newSkipped.delete(activeStep);
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped(newSkipped);
	};

	const handleBack = () => {
		setActiveStep((prevActiveStep) => prevActiveStep - 1);
	};

	const handleSkip = () => {
		if (!isStepOptional(activeStep)) {
			// You probably want to guard against something like this,
			// it should never occur unless someone's actively trying to break something.
			throw new Error("You can't skip a step that isn't optional.");
		}

		setActiveStep((prevActiveStep) => prevActiveStep + 1);
		setSkipped((prevSkipped) => {
			const newSkipped = new Set(prevSkipped.values());
			newSkipped.add(activeStep);
			return newSkipped;
		});
	};

	const handleReset = () => {
		setActiveStep(0);
	};

	return (
		<Box sx={{ width: '100%' }}>
			<Stepper activeStep={activeStep}>
				{steps.map((label, index) => {
					const stepProps = {};
					const labelProps = {};
					//   if (isStepOptional(index)) {
					//     labelProps.optional = (
					//       <Typography variant="caption">Optional</Typography>
					//     );
					//   }
					if (isStepSkipped(index)) {
						stepProps.completed = false;
					}
					return (
						<Step key={label} {...stepProps}>
							<StepLabel {...labelProps}>{label}</StepLabel>
						</Step>
					);
				})}
			</Stepper>
			{activeStep === steps.length ? (
				<React.Fragment>
					<Typography sx={{ mt: 2, mb: 1 }}>
						<div className={styles['password-reset-email-success']}>
							{t('createDevice.allStepCompleted')}
						</div>
					</Typography>
					<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
						<Box sx={{ flex: '1 1 auto' }} />
						<Button onClick={handleReset}>Reset</Button>
					</Box>
				</React.Fragment>
			) : (
				<React.Fragment>
					{/* <Typography sx={{ mt: 2, mb: 1 }}>Step {activeStep + 1}</Typography> */}
					{/* {activeStep === 0 && <CreateDeviceType setNextButton={setNextButton} />} */}
					{activeStep === 0 && <CreateDeviceID trackId={trackId} />}
					{activeStep === 1 && <CreateDeviceModel />}
					{/* {activeStep === 1 && <DeviceInformationRegistration setNextFinishButton={setNextFinishButton} id={props.id}/>} */}
					<Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
						<Button
							color="inherit"
							disabled={activeStep === 0}
							onClick={handleBack}
							sx={{ mr: 1 }}
						>
							{t('createDevice.back')}
						</Button>
						<Box sx={{ flex: '1 1 auto' }} />
						{/* {isStepOptional(activeStep) && (
              <Button color="inherit" onClick={handleSkip} sx={{ mr: 1 }}>
                Skip
              </Button>
            )} */}

						{/* <Button  disabled={nextButton === false} onClick={handleNext}> */}
						{/* {activeStep === steps.length - 1 ? 'Send Mail' : 'Next'} */}
						{/* {activeStep === steps.length - 1 ? "Finish" : 'Next'} */}
						{/* </Button> */}
						{activeStep === steps.length - 1 ? (
							<Button
								disabled={nextFinishButton === false}
								onClick={handleNext}
							></Button>
						) : (
							<Button onClick={handleNext} variant="outlined">
								{t('createDevice.createModel')}
							</Button>
						)}
					</Box>
				</React.Fragment>
			)}
		</Box>
	);
}
